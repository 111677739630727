import { Component, Input, Output, EventEmitter, ViewChild, ContentChild, ElementRef } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap";
import { Modal } from "../modal.interface";

@Component({
  selector: "modal-wrapper",
  templateUrl: "./modal-wrapper.component.html",
  styleUrls: ["./modal-wrapper.component.scss"]
})
export class ModalWrapperComponent implements Modal {
  @Input() title: string;
  @Input() modalReference: BsModalRef;
  @Input() isSaving: boolean;
  @Output() onSaved = new EventEmitter();

  onClose() {
    this.modalReference.hide();
  }

  onSave() {
    this.onSaved.emit();
  }
}
