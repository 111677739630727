import { Directive, Renderer2, ElementRef, Input } from "@angular/core";
import { OnChanges } from "@angular/core/src/metadata/lifecycle_hooks";

@Directive({
    selector: "[hasError]"
})
export class HasErrorDirective implements OnChanges {
    @Input() hasError: boolean;
    private renderer: Renderer2;
    private hostElement: ElementRef;

    constructor(renderer: Renderer2, hostElement: ElementRef) {
        this.renderer = renderer;
        this.hostElement = hostElement;
    }

    ngOnChanges(): void {
        if (this.hasError) {
            this.renderer.addClass(this.hostElement.nativeElement, "has-error");
        } else {
            this.renderer.removeClass(this.hostElement.nativeElement, "has-error");
        }
    }
}
