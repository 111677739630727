import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";

import { GeneralResponse } from "../../../../core/models/base-responses.model";
import { EndpointsService } from "../../../../core/services/endpoint.service";
import { HttpService } from "../../../../core/services/http.service";
import {
  ImageUpdateResponse, AppImageGetListResponse, AppImageGetListParams, ImageAddParams, ImageUpdateParams, ImageDeleteParams,
  ImageAddResponse
} from "./app-images.model";

@Injectable()
export class AppImagesService extends EndpointsService {
  private readonly POST_APP_IMAGE_LIST_GET: string = `${this.API_URL}/ImageGetList`;
  private readonly POST_APP_IMAGE_ADD: string = `${this.API_URL}/ImageAdd`;
  private readonly POST_APP_IMAGE_UPDATE: string = `${this.API_URL}/ImageUpdate`;
  private readonly POST_APP_IMAGE_DELETE: string = `${this.API_URL}/ImageDelete`;

  constructor(private httpService: HttpService) {
    super();
  }

  getAppImageList(params: AppImageGetListParams): Observable<AppImageGetListResponse> {
    return this.httpService.post_authorized(this.POST_APP_IMAGE_LIST_GET, params);
  }

  addImage(params: ImageAddParams): Observable<ImageAddResponse> {
    return this.httpService.post_authorized(this.POST_APP_IMAGE_ADD, params);
  }

  updateImage(params: ImageUpdateParams): Observable<ImageUpdateResponse> {
    return this.httpService.post_authorized(this.POST_APP_IMAGE_UPDATE, params);
  }

  removeImage(params: ImageDeleteParams): Observable<GeneralResponse> {
    return this.httpService.post_authorized(this.POST_APP_IMAGE_DELETE, params);
  }

}
