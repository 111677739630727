import { Observable } from "rxjs";

import { Injectable } from "@angular/core";

import { BaseDeleteParams, BaseParams } from "../../../core/models/base-requests.model";
import { GeneralResponse, GenericGeneralResponse } from "../../../core/models/base-responses.model";
import { CacheService } from "../../../core/services/cache.service";
import { EndpointsService } from "../../../core/services/endpoint.service";
import { HttpService } from "../../../core/services/http.service";
import {
  AddAppModulesListParams, AppAddParams, AppConfigResponse, AppGetListResponse, AppPublishResponse, AppEnforceUniqueResponse, AppEnforceUniqueParams, AppUpdateParams, CurrencyGetListResponse, GetAppModuleListParams,
  MyApp, TimeZoneGetListResponse as TimeZoneGetListResponse, AppGetListParams, AppCloneParams, GetAppModuleDefaultParams
} from "../model/my-apps.model";
import { MyAppModules, MyAppModuleUpdate, AppModuleData } from "../model/page-builder.model";
import { AppModule } from "src/app/app.module";

@Injectable({
  providedIn: "root"
})
export class AppService extends EndpointsService {
  // ---- App ----
  private readonly GET_APP: string = `${this.API_URL}/AppGet`;
  private readonly GET_APP_LIST: string = `${this.API_URL}/AppGetList`;
  private readonly ADD_APP: string = `${this.API_URL}/AppAdd`;
  private readonly UPDATE_APP: string = `${this.API_URL}/AppUpdate`;
  private readonly DELETE_APP: string = `${this.API_URL}/AppDelete`;
  private readonly PUBLISH_APP: string = `${this.API_URL}/AppPublish`;
  private readonly ENFORCE_UNIQUE_APP: string = `${this.API_URL}/AppEnforceUnique`;

  // ---- Abstract Module - Feature ----
  private readonly GET_ABSTRACT_MODULE_LIST: string = `${this.API_URL}/FeatureGetList`;

  // ---- Module Instance by Base Module - App item
  private readonly GET_APP_MODULES_LIST: string = `${this.API_URL}/AppItemGetList`;
  private readonly ADD_APP_MODULES: string = `${this.API_URL}/AppItemAddList`;
  private readonly GET_APP_MODULES_DEFAULT: string = `${this.API_URL}/AppItemGetDefault`;
  private readonly UPDATE_APP_MODULE: string = `${this.API_URL}/AppItemUpdate`;
  private readonly DELETE_APP_MODULE: string = `${this.API_URL}/AppItemDelete`;

  // Time zone & currency
  private readonly GET_TIME_ZONE_LIST: string = `${this.API_URL}/TimezoneGetList`;
  private readonly GET_CURRENCY_LIST: string = `${this.API_URL}/CurrencyGetList`;

  // App config
  private readonly GET_APP_CONFIG_GET: string = `${this.API_URL}/AppConfigGet`;

  constructor(
    private cacheService: CacheService,
    private httpService: HttpService
  ) {
    super();
  }

  // ==================================================================================================================
  // ---------------------------- APP ---------------------------------------------------------------------------------

  getApp(id: number): Observable<MyApp> {
    return this.httpService.post_authorized(this.GET_APP, { ID: id });
  }

  getAppList(params: AppGetListParams): Observable<AppGetListResponse> {
    return this.httpService.post_authorized(this.GET_APP_LIST, params);
  }

  getAppConfig(params: any): Observable<AppConfigResponse> {
    return this.httpService.post_authorized(this.GET_APP_CONFIG_GET, params);
  }

  addApp(newApp: AppAddParams): Observable<MyApp> {
    return this.httpService.post_authorized(this.ADD_APP, newApp);
  }

  cloneApp(params: AppAddParams): Observable<MyApp> {
    return this.httpService.post_authorized(this.ADD_APP, params);
  }

  cloneTemplateApp(params: AppCloneParams): Observable<MyApp> {
    return this.httpService.post_authorized(this.ADD_APP, params);
  }

  updateApp(updatedApp: AppUpdateParams): Observable<MyApp> {
    return this.httpService.post_authorized(this.UPDATE_APP, updatedApp);
  }

  deleteApp(params: BaseDeleteParams): Observable<GeneralResponse> {
    return this.httpService.post_authorized(this.DELETE_APP, params);
  }

  publishApp(params: BaseParams): Observable<AppPublishResponse> {
    return this.httpService.post_authorized(this.PUBLISH_APP, params);
  }

  enforceUniqueApp(params: AppEnforceUniqueParams): Observable<AppEnforceUniqueResponse> {
    return this.httpService.post_authorized(this.ENFORCE_UNIQUE_APP, params);
  }

  // ==================================================================================================================
  // ---------------------------- ABSTRACT MODULE - FEATURE -----------------------------------------------------------

  getAllAbstractModules(params: any): Observable<any> {
    return this.cacheService.get(this.GET_ABSTRACT_MODULE_LIST, this.httpService.post_authorized(this.GET_ABSTRACT_MODULE_LIST, params));
  }

  // ==================================================================================================================
  // ---------------------------- MODULE INSTANCE BY ABSTRACT MODULE - APP ITEM ---------------------------------------

  getAppModulesList(params: GetAppModuleListParams): Observable<MyAppModules> {
    return this.httpService.post_authorized(this.GET_APP_MODULES_LIST, params);
  }

  getAppModuleDefault(params: GetAppModuleDefaultParams): Observable<GenericGeneralResponse<AppModuleData>> {
    return this.httpService.post_authorized(this.GET_APP_MODULES_DEFAULT, params);
  }

  addAppModules(params: AddAppModulesListParams): Observable<MyAppModules> {
    return this.httpService.post_authorized(this.ADD_APP_MODULES, params);
  }

  updateAppModule(app: MyAppModuleUpdate): Observable<GeneralResponse> {
    return this.httpService.post_authorized(this.UPDATE_APP_MODULE, app);
  }

  deleteAppModule(params: BaseDeleteParams): Observable<GeneralResponse> {
    return this.httpService.post_authorized(this.DELETE_APP_MODULE, params);
  }

  // ==================================================================================================================
  // ---------------------------- TIME ZONE & CURRENCY ----------------------------------------------------------------

  getTimeZonesList(): Observable<TimeZoneGetListResponse> {
    return this.cacheService.get(this.GET_TIME_ZONE_LIST, this.httpService.post_authorized(this.GET_TIME_ZONE_LIST));
  }

  getCurrencyList(): Observable<CurrencyGetListResponse> {
    return this.cacheService.get(this.GET_CURRENCY_LIST, this.httpService.post_authorized(this.GET_CURRENCY_LIST));
  }

}
