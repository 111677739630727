import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "confirm-publish-modal",
  templateUrl: "./confirm-publish-modal.component.html"
})
export class ConfirmPublishModalComponent {
  @Output() yesCallback: EventEmitter<void> = new EventEmitter();
  @Output() noCallback: EventEmitter<void> = new EventEmitter();
}
