import { AppInfo } from "src/app/modules/my-apps/model/my-apps.model";
import { UserInfo } from "src/app/core/models/user-info.model";

export class SessionAppInfoChange {
  appInfo: AppInfo;
  isRestored: boolean;

  constructor(appInfo: AppInfo, isRestored: boolean) {
    this.appInfo = appInfo;
    this.isRestored = isRestored;
  }
}

export class SessionUserInfoChange {
  userInfo: UserInfo;
  isRestored: boolean;

  constructor(userInfo: UserInfo, isRestored: boolean) {
    this.userInfo = userInfo;
    this.isRestored = isRestored;
  }
}

export class SessionUserCreditChange {
  creditCount: number;
  isRestored: boolean;

  constructor(creditCount: number, isRestored: boolean) {
    this.creditCount = creditCount;
    this.isRestored = isRestored;
  }
}
