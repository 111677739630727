import { Component, EventEmitter, Output, Input } from "@angular/core";

@Component({
  selector: "purchase-modal",
  templateUrl: "./purchase-modal.component.html"
})
export class PurchaseModalComponent {
  @Input() enableFlagBusiness: boolean;
  @Output() onPurchaseConfirmed: EventEmitter<void> = new EventEmitter();
  @Output() onCancelClick: EventEmitter<void> = new EventEmitter();

  constructor() { }

  purchaseConfirmed() {
    this.onPurchaseConfirmed.emit();
  }

  cancelClick() {
    this.onCancelClick.emit();
  }
}
