export class StringUtil {

  public static removeWhiteSpace(numberString: string): string {
    return numberString.replace(/\s/g, "");
  }

  public static upperFirst(text: string): string {
    let result: string;

    if (text) {
      result = text.charAt(0).toUpperCase() + text.slice(1);
    }

    return result;
  }

  public static lowerFirst(text: string): string {
    let result: string;

    if (text) {
      result = text.charAt(0).toLowerCase() + text.slice(1);
    }
    return result;
  }

  /**
   * Check if the string contains one of the array element
   * @param text text which will be checked
   * @param array text array
   * @return is the text contains one of the array element
   */
  public static containsOneOfTheArrayElement(text: string, array: string[]): boolean {
    return array.some(arrayTextItem => text.includes(arrayTextItem));
  }

  public static contains(text: string, textToSearch: string): boolean {
    return text && text.toLowerCase().indexOf(textToSearch.toLowerCase()) > -1;
  }

  public static convertObjectPropertyToReadable(objectPropertyName: string) {
    // Split string by capital letter
    let result: string = objectPropertyName.replace(/([A-Z])/g, " $1").trim();

    // Set first char to capital letter
    result = result.charAt(0).toUpperCase() + result.slice(1);
    return result;
  }

  public static replaceAll(str, find, replace) {
    return str.replace(new RegExp(StringUtil.escapeRegExp(find), "g"), replace);
  }

  public static escapeRegExp(str) {
    return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
  }
}
