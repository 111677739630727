import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { UserAuthCodeCheckParams } from "src/app/core/models/user-auth-code-check";
import { FormValidationModel } from "src/app/shared/validation/form-validation.model";
import { ResponseStatus } from "src/app/core/enums/response-status.enum";
import { AuthService } from "src/app/core/services/auth.service";
import { SessionStorageService } from "src/app/core/services/session-storage.service";
import { PARAM_ACTIVATION_CODE, RouterPath } from "src/app/core/consts/router-paths";
import { passwordMinLength, Validation, passwordMaxLength } from "src/app/core/consts/validation";
import { ValidationModel } from "src/app/shared/validation/validation.model";
import { InternalCustomValidators } from "src/app/shared/validation/internal-custom-validators.model";
import { SessionRoutingParams } from "src/app/core/models/session-params.model";
import { UserPresenter } from "src/app/modules/user-profile/presenters/user.presenter";
import { ToastrHelper } from "src/app/shared/helpers/toaster.helper";
import { SessionChangeService } from "src/app/shared/services/session-change.service";

@Component({
  templateUrl: "./change-password.component.html"
})
export class ChangePasswordComponent implements OnInit {
  formValidation: FormValidationModel;
  newPasswordFormGroup: FormGroup;
  validationModels: any = {};
  errorMessage: string = "";
  invalidCodeErrorMessage: string = "";
  isPasswordVisible: boolean = false;
  activationCode: string;
  isSaving: boolean;
  isLoading: boolean;
  isCodeValid: boolean;
  userMessage: string;
  toastrHelper: ToastrHelper;

  readonly PASSWORD = "password";

  constructor(
    private userPresenter: UserPresenter,
    private authService: AuthService,
    private translateService: TranslateService,
    private sessionStorageService: SessionStorageService,
    private sessionChangeService: SessionChangeService,
    private toastrService: ToastrService,
    private router: Router,
    activatedRoute: ActivatedRoute
  ) {
    this.toastrHelper = new ToastrHelper(toastrService, translateService);
    this.activationCode = activatedRoute.snapshot.paramMap.get(PARAM_ACTIVATION_CODE);
  }

  ngOnInit() {
    this.translateService.use(this.sessionStorageService.getLanguageCode());
    this.listenLanguageChange();

    if (this.activationCode) {
      this.isLoading = true;
      this.authService.checkAuthCode(new UserAuthCodeCheckParams(this.activationCode)).subscribe(response => {
        if (response.Status == ResponseStatus.Success) {
          this.isCodeValid = true;
          const routingParams: SessionRoutingParams = new SessionRoutingParams();
          routingParams.changePasswordEmail = response.UserEmail;
          this.sessionStorageService.setRoutingParams(routingParams);
        } else {
          this.isCodeValid = false;
          this.invalidCodeErrorMessage = this.translateService.instant("ChangePassword.ActivationCodeNotValidReTryForgotPass");
        }
        this.initFormValidation();
        this.isLoading = false;
      }, () => this.isLoading = false);
    } else {
      this.initFormValidation();
    }
  }
  listenLanguageChange() {
    this.sessionChangeService.getLanguageSubject().subscribe(response => {
      this.translateService.use(response);
    });
  }

  saveNewPassword() {
    if (this.newPasswordFormGroup.valid) {
      this.isSaving = true;
      this.userPresenter.resetUserPassword(
        this.activationCode,
        this.newPasswordFormGroup.controls[this.PASSWORD].value
      ).subscribe(response => {
        this.isSaving = false;
        this.router.navigate([RouterPath.Login]);
      }, error => {
        this.isSaving = false;
        this.errorMessage = error.userMessage;
      });
    } else {
      this.newPasswordFormGroup.controls[this.PASSWORD].markAsTouched();
    }
  }

  changePasswordVisibility(isVisible: boolean) {
    this.isPasswordVisible = isVisible;
  }

  private initFormValidation() {
    // Form Group
    const formControls: LooseObject = {};
    formControls[this.PASSWORD] = new FormControl("", [Validators.required, Validators.minLength(passwordMinLength), Validators.maxLength(passwordMaxLength),
    InternalCustomValidators.noWhitespace]);

    this.newPasswordFormGroup = new FormGroup(formControls);

    // Validation models
    this.validationModels.passwordMinLength =
      new ValidationModel(this.newPasswordFormGroup, this.PASSWORD, Validation.MinLength, "Validation.MinLength", passwordMinLength);
    this.validationModels.passwordMaxLength =
      new ValidationModel(this.newPasswordFormGroup, this.PASSWORD, Validation.MaxLength, "Validation.MaxLength", passwordMaxLength);

    this.formValidation = new FormValidationModel(this.newPasswordFormGroup);
  }

}
