import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, zip } from "rxjs";
import { map } from "rxjs/operators";
import { AppPresenter } from "src/app/modules/my-apps/presenters/app.presenter";
import { AppGetLanguageListParams } from "src/app/shared/components/menu/translation-menu-block/language-model/language-model.model";
import { LanguagePresenter } from "src/app/shared/components/menu/translation-menu-block/language-presenter/language-presenter.presenter";
import { LookupPresenter } from "src/app/shared/presenters/lookup.presenter";

import { AppLoadService } from "../../app-load/app-load.service";
import { SessionChangeService } from "../../shared/services/session-change.service";
import { CreditUtils } from "../../shared/utils/credit.utils";
import { ResponseStatus, UserLoginActionType } from "../enums/response-status.enum";
import { LoginParams, LoginResponse } from "../models/login.model";
import { UserAuthCodeCheckParams, UserAuthCodeCheckResponse } from "../models/user-auth-code-check";
import { UserForgotPasswordParams, UserForgotPasswordResponse } from "../models/user-forgot-password.model";
import { UserInfo } from "../models/user-info.model";
import { UserRegisterEmailParams, UserRegisterEmailResponse } from "../models/user-register-email.model";
import { EndpointsService } from "./endpoint.service";
import { HttpService } from "./http.service";
import { SessionStorageService } from "./session-storage.service";

@Injectable()
export class AuthService extends EndpointsService {
  loggedIn = new BehaviorSubject({ isAuth: false, isFromInitialization: true });

  private readonly LOGIN_URL: string = `${this.API_URL}/UserLogin`;
  private readonly LOGOUT_URL: string = `${this.API_URL}/UserLogout`;
  private readonly REGISTER_EMAIL_SEND: string = `${this.API_URL}/UserRegisterEmailSend`;
  private readonly FORGOT_PASSWORD: string = `${this.API_URL}/UserForgotPassword`;
  private readonly ACTION_SUBMIT_URL: string = `${this.API_URL}/UserLoginActionSubmit`;
  private readonly AUTH_CODE_CHECK_URL: string = `${this.API_URL}/UserAuthCodeCheck`;

  constructor(
    private httpService: HttpService,
    private sessionStorageService: SessionStorageService,
    private sessionChangeService: SessionChangeService,
    private appLoad: AppLoadService,
    private appPresenter: AppPresenter,
    private lookupPresenter: LookupPresenter,
    private languagePresenter: LanguagePresenter
  ) {
    super();
  }

  login(email: string, password: string): Observable<LoginResponse> {
    return this.httpService.post(this.LOGIN_URL, new LoginParams(email, password)).pipe(map(response => {
      const result: LoginResponse = <LoginResponse>response;
      if (result.Status == ResponseStatus.Success) {
        const userInfo = <UserInfo>result.UserInfo;
        this.sessionStorageService.setUserInfo(userInfo);
        this.sessionStorageService.setOrganizationInfo(result.OrganizationInfo);
        this.sessionChangeService.notifyUserCreditChange(CreditUtils.getClientCreditCountByUser(userInfo));

        this.loadData();

        if (result.UserLoginActions && result.UserLoginActions.length > 0) {
          this.sessionStorageService.setUserActions(result.UserLoginActions);

          const updateBillingNotification = result.UserLoginActions.find(item => item.UserLoginActionType == UserLoginActionType.UpdateBillingRequired);
          if (updateBillingNotification) {
            this.sessionChangeService.notifyUpdateBillingRequiredChange(updateBillingNotification);
          }

          const announcementNotification = result.UserLoginActions.find(item => item.UserLoginActionType == UserLoginActionType.Announcement);
          if (announcementNotification) {
            this.sessionChangeService.notifyAnnouncementChange(announcementNotification);
          }
        }
      }
      return result;
    }));
  }

  logout() {
    this.httpService.post(this.LOGOUT_URL).subscribe(result => {
      this.sessionStorageService.removeAll();
      this.appLoad.initializeApp();
    });
  }

  actionSubmit(actionType: UserLoginActionType) {
    const params = {
      UserLoginActionType: actionType
    };
    return this.httpService.post(this.ACTION_SUBMIT_URL, params);
  }

  sendRegisterEmail(email: string): Observable<UserRegisterEmailResponse> {
    return this.httpService.post(this.REGISTER_EMAIL_SEND, new UserRegisterEmailParams(email));
  }

  forgotPassword(email: string): Observable<UserForgotPasswordResponse> {
    return this.httpService.post(this.FORGOT_PASSWORD, new UserForgotPasswordParams(email));
  }

  checkAuthCode(userAuthCodeCheckRequest: UserAuthCodeCheckParams): Observable<UserAuthCodeCheckResponse> {
    return this.httpService.post(this.AUTH_CODE_CHECK_URL, userAuthCodeCheckRequest);
  }

  private loadData() {
    const appGetLanguageListParams: AppGetLanguageListParams = new AppGetLanguageListParams();
    appGetLanguageListParams.ForceAll = 1;
    zip(this.appPresenter.getAllAbstractModules(),
      this.lookupPresenter.getSystemLookups([
      ]),
      this.languagePresenter.appLanguageList(appGetLanguageListParams)
    ).subscribe(([responseModules, lookupResponse, languageResponse]) => {
      this.sessionStorageService.setFeaturesList(responseModules.dataList);
      this.sessionStorageService.setLookupsList(lookupResponse.dataList);
      this.sessionStorageService.setAppLanguageList(languageResponse.dataList);
    });
  }

}
