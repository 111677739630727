import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ModalModule } from "ngx-bootstrap";

import { SharedModule } from "../../../../shared/shared.module";
import { AddEditUserBillingModalComponent } from "./add-edit-user-billing-modal/add-edit-user-billing-modal.component";
import { BankCardMetaTileComponent } from "./bank-card-meta-tile/bank-card-meta-tile.component";
import { BankCardPaymentNetworkIconComponent } from "./bank-card-payment-network-icon/bank-card-payment-network-icon.component";
import { BankCardTileContainerComponent } from "./bank-card-tile-container/bank-card-tile-container.component";
import { BankCardTileComponent } from "./bank-card-tile/bank-card-tile.component";

@NgModule({
  imports: [
    CommonModule,
    ModalModule.forRoot(),
    SharedModule,
  ],
  declarations: [
    BankCardTileComponent,
    BankCardMetaTileComponent,
    AddEditUserBillingModalComponent,
    BankCardTileContainerComponent,
    BankCardPaymentNetworkIconComponent
  ],
  exports: [
    BankCardTileComponent,
    BankCardMetaTileComponent,
    AddEditUserBillingModalComponent,
    BankCardTileContainerComponent,
    BankCardPaymentNetworkIconComponent
  ],
  providers: []
})
export class UserBillingModule { }
