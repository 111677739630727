import { Component, Input, Output, EventEmitter } from "@angular/core";
import { UserBillingTile } from "../../../models/user-billing.model";

@Component({
  selector: "bank-card-tile",
  templateUrl: "./bank-card-tile.component.html"
})
export class BankCardTileComponent {
  @Input() userBillingTile: UserBillingTile;
  @Input() isDeletable = false;
  @Output() onTileClicked: EventEmitter<UserBillingTile> = new EventEmitter();
  @Output() onDeleted: EventEmitter<UserBillingTile> = new EventEmitter();

  tileClicked(event: Event) {
    if (!event.defaultPrevented) {
      this.onTileClicked.emit(this.userBillingTile);
    }
  }

  deleteClick(event: Event) {
    event.stopPropagation();
    this.onDeleted.emit(this.userBillingTile);
  }

}
