import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { SessionStorageService } from "src/app/core/services/session-storage.service";
import { SessionChangeService } from "src/app/shared/services/session-change.service";
import { UserLoginActionType } from "src/app/core/enums/response-status.enum";
import { routerPaths } from "src/app/core/consts/router-paths";
import { SessionRoutingParams } from "src/app/core/models/session-params.model";
import { UserLoginAction } from "src/app/core/models/login.model";
import { Subscription } from "rxjs";
import { SubscriptionUtil } from "src/app/shared/utils/subscription.utils";
import { NotificationBarListenerService } from "./services/notification-bar.service";

@Component({
  selector: "notification-bar",
  templateUrl: "./notification-bar.component.html",
  styleUrls: ["./notification-bar.component.scss"]
})
export class NotificationBarComponent implements OnInit, OnDestroy {
  announcement: UserLoginAction = new UserLoginAction();
  updateBilling: UserLoginAction = new UserLoginAction();

  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private notificationBarListener: NotificationBarListenerService,
    public sessionChangeService: SessionChangeService,
  ) { }

  ngOnInit() {
    this.initSubscriptions();

    if (this.sessionStorageService.getUserActions()) {
      const billingUpdate = this.sessionStorageService.getUserActions().find(item => item.UserLoginActionType == UserLoginActionType.UpdateBillingRequired);
      if (billingUpdate) {
        this.sessionChangeService.notifyUpdateBillingRequiredChange(billingUpdate);
        this.notificationBarListener.notifyNotificationBarVisibilityChange(true);
      }

      const announcement = this.sessionStorageService.getUserActions().find(item => item.UserLoginActionType == UserLoginActionType.Announcement);
      if (announcement) {
        this.sessionChangeService.notifyAnnouncementChange(announcement);
        this.notificationBarListener.notifyNotificationBarVisibilityChange(true);
      }
    }
  }

  ngOnDestroy(): void {
    SubscriptionUtil.unsubscribe(this.subscriptions);
  }

  onUpdateBillingClick(event) {
    event.preventDefault();
    const routeParams: SessionRoutingParams = this.sessionStorageService.getRoutingParams() ?
      this.sessionStorageService.getRoutingParams() : new SessionRoutingParams();
    routeParams.isFromUpdateBillingButton = true;
    this.sessionStorageService.setRoutingParams(routeParams);

    // If we are already on the profile page, fake navigate to another route and then go back to profile route so we can switch to billing tab
    if (this.router.url == routerPaths.Profile) {
      this.router.navigateByUrl("/", { skipLocationChange: true }).then(() =>
        this.router.navigate([routerPaths.Profile]));
    } else {
      this.router.navigate([routerPaths.Profile]);
    }
  }

  onAnnouncementClick(event) {
    event.preventDefault();

    const announcement = this.sessionStorageService.getUserActions().find(item => item.UserLoginActionType == UserLoginActionType.Announcement);
    if (announcement) {
      this.sessionChangeService.notifyAnnouncementChange(null);
      this.sessionStorageService.setUserActions(
        this.sessionStorageService.getUserActions().filter(item => item.UserLoginActionType != announcement.UserLoginActionType)
      );
    }
    this.notificationBarListener.notifyNotificationBarVisibilityChange(false);
  }

  private initSubscriptions() {
    this.subscriptions.push(
      this.sessionChangeService.getUpdateBillingRequiredSubject().subscribe(response => {
        this.updateBilling = response;
      }),
      this.sessionChangeService.getAnnouncementSubject().subscribe(response => {
        this.announcement = response;
      })
    );
  }

}
