import { GenericBaseParams, BaseParams } from "../../../core/models/base-requests.model";
import { GenericGeneralResponse, DateData, GeneralResponse } from "../../../core/models/base-responses.model";

// ---------------------- GET ---------------------------

export class UserBillingGetParams extends BaseParams { }

export class UserBillingGetResponse extends GenericGeneralResponse<UserBillingData> { }

// ---------------------- Update ---------------------------

export class UserBillingUpdateParams extends GenericBaseParams<UserBillingData> { }

export class UserBillingUpdateResponse extends GenericGeneralResponse<UserBillingData> { }

// ---------------------- Delete ---------------------------

export class UserBillingDeleteParams extends BaseParams {
  ID: number;
}

export class UserBillingDeleteResponse extends GeneralResponse { }

// ---------------------- Related ---------------------------

export class UserBillingData {
  CreatedByName: string;
  CreatedDate: DateData;
  ID: number;
  ModifiedByName: string;
  ModifiedDate: DateData;
  Address: string;
  Suite: string;
  CCV: string;
  City: string;
  Country: string;
  CreditCardNumber: string;
  CreditCardProvider: string;
  CreditCardProviderIdentifier: string;
  CreditCardType: string;
  ExpirationMonth: number;
  ExpirationYear: number;
  BusinessName: string;
  FirstName: string;
  LastName: string;
  State: string;
  UserID: number;
  Zip: string;
  Email: string;
  Phone: string;
  RegistrationNumber: string;
  TaxIdentification: string;
  VATIdentification: string;
  PONumber: string;
}

export class UserBillingTile {
  id: number;
  data: UserBillingData;
  isSelected: boolean;

  constructor(userBillingData?: UserBillingData) {
    if (userBillingData) {
      this.id = userBillingData.ID;
      this.data = userBillingData;
    }
  }
}
