import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";

import { routerPaths } from "../consts/router-paths";
import { SessionStorageService } from "../services/session-storage.service";
import { UserInfo } from "../models/user-info.model";

@Injectable()
export class AdvancedUserGuard implements CanActivate {
  constructor(
    private router: Router,
    private sessionStorageService: SessionStorageService
  ) { }

  canActivate() {
    const userInfo: UserInfo = this.sessionStorageService.getUserInfo();

    if (userInfo.FlagIsAdvanced) {
      return true;
    }

    this.router.navigate([routerPaths.Unauthorized]);
    return false;
  }
}
