import { FormGroup, AbstractControl } from "@angular/forms";

export class FormValidatorUtil {

  /** Highlighting the not valid fields on form group
   *
   * @param formGroup
   */
  public static validateFormElements(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(key => {
      formGroup.get(key).markAsTouched();
    });
  }

   /** Highlighting the not valid field on form control
   *
   * @param formControl
   */
  public static validateFormElement(formControl: AbstractControl): void {
    if (formControl) {
      formControl.markAsTouched();
    }
  }

  public static hasRequiredField(abstractControl: AbstractControl): boolean {
    if (abstractControl.validator) {
      const validator = abstractControl.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
    // tslint:disable:no-string-literal
    if (abstractControl["controls"]) {
      for (const controlName in abstractControl["controls"]) {
        if (abstractControl["controls"][controlName]) {
          if (this.hasRequiredField(abstractControl["controls"][controlName])) {
            return true;
          }
        }
      }
    }
    return false;
  }
}

