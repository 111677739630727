export class ArrayUtils {

  /**
   * Add the defined item to the end of the array and returns a new instance
   *
   * @param array
   * @param itemToAdd the item which will be added
  */
  public static addItemToEnd<T>(array: T[], itemToAdd: T): T[] {
    let resultArray: T[];

    if (array) {
      array.push(itemToAdd);
      resultArray = [...array];
    } else {
      resultArray = [];
      resultArray.push(itemToAdd);
    }

    return resultArray;
  }

  /**
   * Add the defined item to the beginning to the array and returns a new instance
   *
   * @param array
   * @param itemToAdd the item which will be added
  */
  public static addItemToBeginning<T>(array: T[], itemToAdd: T): T[] {
    let resultArray: T[];

    if (array) {
      array.unshift(itemToAdd);
      resultArray = [...array];
    } else {
      resultArray = [];
      resultArray.push(itemToAdd);
    }

    return resultArray;
  }

  /**
   * Replace all the items in an array which match the condition
   *
   * @param array
   * @param itemToReplace the item which will be added
   */
  public static replaceItems<T>(array: T[], itemToReplace: T, conditionEvaluator: ((item: T) => boolean)): T[] {
    array.forEach((item, index) => {
      if (conditionEvaluator(item)) {
        array[index] = itemToReplace;
      }
    });

    return array;
  }

  /**
   * Replace the first item in an array which match the condition and returns a new instance
   *
   * @param array
   * @param itemToReplace the item which will be added
   */
  public static replaceFirst<T>(array: T[], itemToReplace: T, conditionEvaluator: ((item: T) => boolean)): T[] {
    const itemIndex = array.findIndex(item => conditionEvaluator(item));

    if (itemIndex != -1) {
      array[itemIndex] = itemToReplace;
    }

    return [...array];
  }

  /**
   * Remove item from array by index
   *
   * @param array
   * @param index of the item
   */
  public static removeItemByIndex<T>(array: T[], startIndex: number) {
    for (let i = startIndex; i < array.length - 1; i++) {
      array[i] = array[i + 1];
    }

    array.pop();
  }

  /**
   * Remove the defined item from the array and returns a new instance
   *
   * @param array
   * @param itemToDelete the item which will be deleted
   */
  public static removeItem<T>(array: T[], itemToDelete: T): T[] {
    return array.filter(obj => obj !== itemToDelete);
  }

}
