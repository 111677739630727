import { Component, Input } from "@angular/core";

@Component({
    selector: "custom-loader",
    templateUrl: "loader.component.html"
})

export class LoaderComponent {
    @Input() loading: boolean;

    constructor() { }
}
