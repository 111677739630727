import { DEFAULT_PAGE_SIZE } from "src/app/core/consts/request-defaults";
import { Observable } from "rxjs";
import { LoggerUtil } from "../utils/logger.util";

export class LazyLoad {
  isLazyLoading: boolean = false;
  noMoreItemToLoad: boolean = false;
  isComponentLoadFailed: boolean = false;
  addedItemsCount: number = 0;
  deletedItemsCount: number = 0;
  pageSize: number = DEFAULT_PAGE_SIZE;

  requestFunction: ((pageNumber: number) => Observable<number>);

  pageNumber: number = 1;

  protected setupLazyLoadingFunction(requestFunction: ((pageNumber: number) => Observable<number>)) {
    this.requestFunction = requestFunction;
  }

  protected resetPageNumber(arrayLength: number, dividend: number, addedItemsCount: number = 0): void {
    if (arrayLength >= this.pageSize) {
      this.pageNumber = Math.ceil((dividend - addedItemsCount) / this.pageSize);
    } else {
      this.pageNumber = 1;
    }
  }

  protected lazyLoadItems(fromTheBeginning: boolean = false) {
    if (fromTheBeginning) {
      this.pageNumber = 0;
      this.noMoreItemToLoad = false;
    }

    if (!this.isLazyLoading && !this.noMoreItemToLoad) {
      this.isLazyLoading = true;
      const observable: Observable<number> = this.requestFunction(this.pageNumber++);
      if (observable && observable instanceof Observable) {
        observable.subscribe(resultCount => {
          this.isLazyLoading = false;
          this.isComponentLoadFailed = false;
          this.noMoreItemToLoad = resultCount < this.pageSize;
        }, error => {
          LoggerUtil.log(this.constructor.name, error);
          this.handleError();
        });
      } else {
        this.handleError();
      }
    }
  }

  private handleError() {
    this.isLazyLoading = false;
    this.isComponentLoadFailed = true;
  }
}
