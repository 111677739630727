import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { SortingType } from "src/app/core/models/base-requests.model";
import { AppImagesService } from "../components/app-images/app-images.service";
import {
  AppImageGetListParams, AppImageGetListResponse, ImageAddParams, ImageUpdateParams, ImageDeleteParams
} from "../components/app-images/app-images.model";
import { PhotoSortingType } from "../../app-modules/photos/components/photo-gallery/photo-sorting-type.model";
import { AlphaTechSubscribeUtils } from "src/app/shared/utils/alpha-tech-subscribe.utils";
import { ImageData } from "src/app/core/models/base-responses.model";
import { DEFAULT_PAGE_SIZE } from "src/app/core/consts/request-defaults";
import { AlphaTechResponseDataList, AlphaTechResponseData } from "src/app/shared/model/alphatech-response-data.model";
import { AlphaTechServerMessage } from "src/app/shared/model/alphatech-server-message.model";

@Injectable()
export class ImagePresenter {
  constructor(
    private appImageService: AppImagesService,
  ) { }

  getAppImageList(appId: number, tags: string, type: number, selectedAppId?: number): Observable<AlphaTechResponseDataList<ImageData>> {
    const params: AppImageGetListParams = this.buildAppImageGetListParam(1, 0, "", PhotoSortingType.Newest, appId, tags, type, selectedAppId);

    return AlphaTechSubscribeUtils.subscribeDataListResponseHandling<ImageData>(this.appImageService.getAppImageList(params));
  }

  addImage(imageData: ImageData): Observable<AlphaTechResponseData<ImageData>> {
    const params: ImageAddParams = new ImageAddParams();
    params.Data = imageData;

    return AlphaTechSubscribeUtils.subscribeDataResponseHandling<ImageData>(this.appImageService.addImage(params));
  }

  updateImage(imageData: ImageData): Observable<AlphaTechResponseData<ImageData>> {
    const params: ImageUpdateParams = new ImageUpdateParams();
    params.Data = imageData;

    return AlphaTechSubscribeUtils.subscribeDataResponseHandling<ImageData>(this.appImageService.updateImage(params));
  }

  removeImage(imageId: number): Observable<AlphaTechServerMessage> {
    const params: ImageDeleteParams = new ImageDeleteParams();
    params.ID = imageId;

    return AlphaTechSubscribeUtils.subscribeResponseHandling(
      this.appImageService.removeImage(params)
    );
  }

  private buildAppImageGetListParam(
    pageNumber: number,
    appItemId: number,
    searchText: string,
    sortingType: PhotoSortingType,
    appId: number,
    tags: string,
    type: number,
    selectedAppId?: number
  ) {
    const params: AppImageGetListParams = new AppImageGetListParams();
    params.Take = pageNumber * DEFAULT_PAGE_SIZE;
    params.ForceAll = 1; // TODO: remove this when 'load-more' is implemented
    params.Position = 0;
    params.Filter.AppID = appId;
    params.Filter.SelectedAppID = selectedAppId || -1;
    params.Filter.ImageTag = tags;
    params.Filter.FlagShowImageType = type;

    params.Sorting.Key = "CreatedDate";
    switch (+sortingType) {
      case PhotoSortingType.Newest: {
        params.Sorting.Type = SortingType.DESC;
        break;
      }
      case PhotoSortingType.Oldest: {
        params.Sorting.Type = SortingType.ASC;
        break;
      }
    }

    return params;
  }

}
