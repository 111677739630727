import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, TemplateRef } from "@angular/core";

import { UserBillingData } from "../../../../../modules/billing/models/user-billing.model";
import { BaseComponent } from "../../../../../shared/components/base-components/base-component";
import { ArrayUtils } from "../../../../../shared/utils/array.utils";
import { UserBillingTile as UserBillingTile } from "../../../models/user-billing.model";
import { BillingPresenter } from "../../../presenters/billing.presenter";
import { RequestOperationType } from "src/app/shared/components/base-components/request-operation-type.model";
import { ItemToDelete } from "src/app/shared/components/confirm-delete-modal/item-to-delete";
import { ModalUtils } from "src/app/shared/utils/modal.utils";
import { ServerMessageUtil } from "src/app/shared/utils/server-message.utils";
import { finalize } from "rxjs/operators";

@Component({
  selector: "bank-card-tile-container",
  templateUrl: "./bank-card-tile-container.component.html"
})
export class BankCardTileContainerComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() userBillingDataList: UserBillingData[];
  @Input() isSelectionHighLightEnabled = true;
  @Output() onSelected: EventEmitter<UserBillingData> = new EventEmitter();
  @Output() onDeleted: EventEmitter<UserBillingData> = new EventEmitter();

  userBillingTileList: UserBillingTile[];
  itemToDelete: ItemToDelete<UserBillingTile>;

  constructor(
    private billingPresenter: BillingPresenter,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.buildUserBillingItemList();
  }

  ngOnChanges(): void {
    this.buildUserBillingItemList();
  }

  tileClicked(userBillingTile: UserBillingTile) {
    if (this.isSelectionHighLightEnabled) {
      this.userBillingTileList.forEach(currentUserBillingTile => {
        currentUserBillingTile.isSelected = (userBillingTile.data.ID == currentUserBillingTile.data.ID);
      });
    }

    this.onSelected.emit(userBillingTile.data);
  }

  showDeleteConfirmationModal(userBillingTile: UserBillingTile, template: TemplateRef<any>) {
    this.itemToDelete = new ItemToDelete(userBillingTile.id, userBillingTile.data.CreditCardNumber, userBillingTile);
    this.bsModalRef = ModalUtils.showModalIgnoringBackdropClick(this.modalService, template);
  }

  deleteBankCard(itemToDelete: ItemToDelete<UserBillingTile>) {
    this.itemToDelete.isDeleting = true;
    this.presenterRequestHandler(
      this.billingPresenter.deleteUserBilling(itemToDelete.instance.data.ID)
    ).pipe(finalize(() => {
      this.itemToDelete.isDeleting = false;
      this.bsModalRef.hide();
    })).subscribe(
      response => {
        this.userBillingTileList = ArrayUtils.removeItem(this.userBillingTileList, itemToDelete.instance);
        this.toastrHelper.showServerSuccess(response.userMessage);
        this.onDeleted.emit(itemToDelete.instance.data);
      }
    );
  }

  private buildUserBillingItemList() {
    if (this.userBillingDataList) {
      this.userBillingTileList = [];
      this.userBillingDataList.forEach(item => {
        const userBillingItem: UserBillingTile = new UserBillingTile();
        userBillingItem.id = item.ID;
        userBillingItem.data = item;
        userBillingItem.isSelected = false;
        this.userBillingTileList.push(userBillingItem);
      });
    }
  }

}
