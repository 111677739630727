export class ImageWrapper {
  image: string;
  width: number;
  height: number;
  filename: string;

  constructor(image: string, width: number, height: number, filename: string = "") {
    this.image = image;
    this.width = width;
    this.height = height;
    this.filename = filename;
  }
}
