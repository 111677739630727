import { Component, Input } from "@angular/core";

@Component({
  selector: "created-updated-status-info",
  templateUrl: "./created-updated-status-info.component.html",
  styleUrls: ["./created-updated-status-info.component.scss"]
})
export class CreatedUpdatedStatusInfoComponent {
  @Input() createdDate: string;
  @Input() updatedDate: string;
  @Input() createdBy: string;
  @Input() updatedBy: string;
  // @Input() description: string;
  @Input() showInSmallSize: boolean;
  @Input() status: boolean;
  @Input() template: boolean;
}
