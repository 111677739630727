import { Component, OnInit, Output, EventEmitter, OnDestroy } from "@angular/core";

@Component({
    selector: "validation-helper",
    template: ""
})
export class ValidationHelperComponent implements OnInit, OnDestroy {
    @Output() onInit = new EventEmitter<boolean>();

    constructor() { }

    ngOnInit() {
        this.onInit.next(true);
    }

    ngOnDestroy(): void {
        this.onInit.next(false);
    }
}
