import { PHInfo, VRInfo } from "./info.model";
import { DateData, BaseRecord } from "./base-responses.model";

export class UserInfo extends BaseRecord {
  DateOfBirth: DateData;
  DeltaFields: string;
  Email: string;
  FirstName: string;
  FullName: string;
  LastName: string;
  Status: number;
  Title: string;
  ParentID: number;
  Validated: number;
  AppCount: number;
  AppCountStandard: number;
  AppCountPremium: number;
  AppMax: number;
  AppMaxPremium: number;
  CountryCode: string;
  FlagIsAdvanced: number;
  LanguageID: number;
  OrganizationID: number;
  PHInfo: PHInfo;
  VRInfo: VRInfo;
  UserLevelID: number;
  UserLevelText: string;
}
