import { BaseParams, GenericBaseListParams, GenericBaseParams } from "../../../core/models/base-requests.model";
import { DateData, GenericGeneralListResponse, GenericGeneralResponse, BaseRecord } from "../../../core/models/base-responses.model";
import { ItemData } from "../../app-modules/models/common.model";
import { EditorData } from "src/app/shared/components/editors-list/model/editors-list.model";

// ---------------------- Get List ---------------------------

export class PackageGetListParams extends GenericBaseListParams<PackageItemFilter> { }

export class PackageGetListResponse extends GenericGeneralListResponse<PackageData> { }

export class PackageSubscriptionGetListParams extends GenericBaseListParams<PackageSubscriptionItemFilter> { }

export class PackageSubscriptionGetListResponse extends GenericGeneralListResponse<PackageSubscriptionData> {
  PackageSubscriptionsSummary: PackageSubscriptionsSummaryData;
}

export class PackageDetailsGetListParams extends GenericBaseListParams<PackageDetailsItemFilter> { }

export class PackageDetailsGetListResponse extends GenericGeneralListResponse<PackageDetailsData> { }

export class PackageUserInvoiceGetListParams extends GenericBaseListParams<PackageUserInvoiceDataFilter> { }

export class PackageUserInvoiceGetListResponse extends GenericGeneralListResponse<PackageUserInvoiceData> { }

export class PackageUserInvoicePaymentGetListParams extends GenericBaseListParams<PackageUserInvoicePaymentDataFilter> { }

export class PackageUserInvoicePaymentGetListResponse extends GenericGeneralListResponse<PackageUserInvoicePaymentData> { }

export class PackageUserInvoiceLineGetListParams extends GenericBaseListParams<PackageUserInvoiceLineDataFilter> { }

export class PackageUserInvoiceLineGetListResponse extends GenericGeneralListResponse<PackageUserInvoiceLineData> { }

// ---------------------- Add ---------------------------

export class PackageAddParams extends GenericBaseParams<PackageData> {
  CloneID: number;
}

export class PackageSubscriptionAddParams extends GenericBaseParams<PackageSubscriptionData> { }

export class PackageSubscriptionAddResponse extends GenericGeneralResponse<PackageSubscriptionData> { }

export class PackageAppAssignmentAddParams extends GenericBaseParams<PackageAppAssignmentData> { }

export class PackageAppAssignmentAddResponse extends GenericGeneralResponse<PackageAppAssignmentData> { }

// ---------------------- Delete ---------------------------

export class PackageSubscriptionCancelParams extends BaseParams {
  ID: number;
}

export class PackageSubscriptionCancelResponse extends GenericGeneralResponse<PackageSubscriptionData> { }


// ---------------------- Related ---------------------------
// ------------------ Package Data ------------------

export class PackageData extends ItemData {
  AppCount: number;
  EditorContributors: EditorDataWithPackageID[] = [];
  EditorOwners: EditorDataWithPackageID[] = [];
  EditorSubscribers: EditorDataWithPackageID[] = [];
  EndDate: DateData = new DateData();
  ExpirationDays: number;
  FlagIsPremium: number;
  FlagIsAvatar: number;
  OrganizationID: number;
  PackageDetails: PackageDetails[] = [];
  PackageID: number;
  ID: number;
  PackageTypeID: number;
  PackageUserLevelID: number;
  PackageVisibilityTypeID: number;
  PriceInitial: number;
  PriceInitialMSRP: number;
  PriceMonthly: number;
  PriceMonthlyMSRP: number;
  PricePartial: number;
  StartDate: DateData = new DateData();
}

export class EditorDataWithPackageID extends EditorData {
  PackageID: number;
}

// ------------------ Package Details Data ------------------

export class PackageDetails extends BaseRecord {
  FeatureCount: number;
  FeatureName: string;
  FeatureID: number;
  ListCount: number;
  PackageID: number;
  Position: number;

  constructor(featureId: number, featureCount: number, listCount: number) {
    super();
    this.FeatureID = featureId;
    this.FeatureCount = featureCount;
    this.ListCount = listCount;
  }
}

export class PackageItemFilter {
  Status: number;
  FlagBusiness: number;
  FlagOnlyFree: number;
  FlagOnlyPremium: number;
  FlagOnlyParentOrganization: number;
}

export class PackageSubscriptionData extends BaseRecord {
  AppsAppliedTo: string;
  CardInUse: string;
  AppCount: number;
  AppCountUsed: number;
  Expiration: DateData;
  PackageID: number;
  PackageName: string;
  PriceInitial: number;
  PriceMonth: number;
  Status: number;
  UserID: number;
  LastSuccessfulPaymentIdentifier: string;
  NextPaymentDate: DateData;
}

export class PackageSubscriptionsSummaryData {
  AmountTotal: number;
  AppCount: number;
  AppCountUsed: number;
  NextPaymentDate: DateData;
  PremiumBenefits: string[] = [];
}

export class PackageSubscriptionListSummary {
  list: PackageSubscriptionData[];
  summary: PackageSubscriptionsSummaryData;

  constructor(response?: PackageSubscriptionGetListResponse) {
    this.list = response ? response.Data : [];
    this.summary = response ? response.PackageSubscriptionsSummary : null;
  }
}

export class PackageSubscriptionItemFilter extends BaseParams {
  Status: number;
  FlagOnlyFree: number;
  FlagOnlyPremium: number;
  FlagOnlyPremiumAvailable: number;
  UserID: number;
}

export class PackageAppAssignmentData extends BaseRecord {
  AppID: number;
  PackageSubscriptionID: number;
  Status: number;
}

export class PackageDetailsItemFilter extends BaseParams {
  Status: number;
  PackageID: number;
}

export class PackageDetailsData extends BaseRecord {
  FeatureCount: number;
  FeatureID: number;
  ListCount: number;
  PackageID: number;
}

export class PackageUserInvoiceData extends BaseRecord {
  InvoiceAmount: number;
  InvoiceDate: string;
  InvoiceDueDate: string;
  UserID: number;
}

export class PackageUserInvoicePaymentData extends BaseRecord {
  AuthorizationCode: string;
  BillingAmount: string;
  BillingDetails: string;
  BillingDates: string;
  PackageUserInvoiceID: number;
  PaymentCard: string;
  PaymentDate: DateData;
  PaymentStatus: number;
  TransactionCode: string;
}

export class PackageUserInvoiceLineData extends BaseRecord {
  InvoiceLineAmount: number;
  PackageSubscriptionID: number;
  PackageUserInvoiceID: number;
}

export class PackageUserInvoiceDataFilter {
  Status: number;
  DateFrom: DateData;
  DateTo: DateData;
  UserID: number;
}

export class PackageUserInvoicePaymentDataFilter {
  Status: number;
  PackageUserInvoiceID: number;
  FromDate: DateData;
  ToDate: DateData;
  UserID: number;
}

export class PackageUserInvoiceLineDataFilter {
  Status: number;
  PackageUserInvoiceID: number;
}

export class PackageUserInvoiceInfo {
  packageUserInvoiceData: PackageUserInvoiceData;
  packageUserInvoicePaymentDataList: PackageUserInvoicePaymentData[] = [];
}
