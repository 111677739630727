import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { TranslateService } from "@ngx-translate/core";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { SessionChangeService } from "src/app/shared/services/session-change.service";

import { routerPaths } from "../../../../core/consts/router-paths";
import { ImageContent, ImageData } from "../../../../core/models/base-responses.model";
import { SessionStorageService } from "../../../../core/services/session-storage.service";
import { AppInfo } from "../../../../modules/my-apps/model/my-apps.model";
import { ImagePresenter } from "../../../../modules/my-apps/presenters/app-images.presenter";
import { CloneUtil } from "../../../../shared/utils/clone.utils";
import { FormValidatorUtil } from "../../../../shared/utils/form-validator.util";
import { ImageUtils } from "../../../../shared/utils/image.util";
import { FormValidationModel } from "../../../../shared/validation/form-validation.model";
import { BaseFormComponent } from "../../base-components/base-form-component";
import { AlphaTechImageToUpload } from "../alpha-tech-image-to-upload.model";

@Component({
  selector: "alpha-tech-image-uploader-modal",
  templateUrl: "./alpha-tech-image-uploader-modal.component.html",
  styleUrls: ["alpha-tech-image-uploader-modal.component.scss"]
})
export class AlphaTechImageUploaderModalComponent extends BaseFormComponent implements OnInit, OnDestroy {
  // there are only two use-cases for this:
  // 1. import from other apps only (single usage: when adding to the current app gallery)
  // 2. use an image from the current app gallery (this property would not be provided)
  @Input() onlyOtherApps: boolean;

  @Output() onImageSelectedForUpload: EventEmitter<AlphaTechImageToUpload> = new EventEmitter();
  @Output() onClose: EventEmitter<void> = new EventEmitter();
  @Output() onCancel: EventEmitter<void> = new EventEmitter();

  readonly RADIO: string = "radio";
  readonly IS_DRAWABLE: string = "new-image-is-drawable";
  readonly DRAWABLE_CONTENT: string = "new-drawable-content";
  readonly IMAGE_NAME: string = "new-image-name";
  readonly IMAGE_TAGS: string = "new-image-tags";
  readonly IMAGES_TAGS: string = "images-tags-list";

  addImageForm: FormGroup;
  addFrom: FormGroup;
  imageFilterForm: FormGroup;

  imageData: ImageData;
  alphaTechImageToUpload: AlphaTechImageToUpload;
  allOtherImages: ImageData[] = [];
  selectedOtherAppImageData: ImageData;
  isAppImageValid: boolean = true;
  isListLoading: boolean;
  isFromOtherApp: boolean = false;
  isImageSelected: boolean = false;

  editorConfig: AngularEditorConfig = {
    editable: true,
    height: "10rem"
  };

  private appId: number;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private sessionChangeService: SessionChangeService,
    private imagePresenter: ImagePresenter,
    injector: Injector
  ) {
    super(injector);
    const appInfo: AppInfo = this.sessionStorageService.getSelectedAppInfo();
    this.appId = appInfo ? appInfo.id : undefined;
  }

  ngOnInit() {
    this.translate.use(this.sessionStorageService.getLanguageCode());
    this.listenLanguageChange();
    this.initNewImageData();
    this.initEditorConfig();
    this.initFilterAllImagesByTag();
    this.initAddImageForm();
    this.initAddFromRadio();
  }

  onUploadAppImage(imageSource) {
    this.alphaTechImageToUpload.newImageSource = imageSource;

    const { height, width, image, filename } = imageSource;
    const fileExt = image.split(";")[0].split("/")[1];

    this.imageData.Contents[0].Content = image;
    this.imageData.Contents[0].ImageExt = fileExt;
    this.imageData.Contents[0].Filename = filename;
    this.imageData.Width = width;
    this.imageData.Height = height;
	this.imageData.ID = 0;

    if (this.imageData.FlagIsDrawable) {
      this.imageData.ImageName = this.addImageForm.get(this.IMAGE_NAME).value;
    } else {
      this.imageData.ImageName = filename;
    }

    this.isAppImageValid = true;
  }

  onAppImageAdd($event) {
    const newImageData: ImageData = CloneUtil.getClonedInstance(this.imageData);

    if (this.isFormValid()) {
      if (this.selectedOtherAppImageData) {
        newImageData.ImportFromImageID = this.selectedOtherAppImageData.ID;
        newImageData.PublicURL = this.selectedOtherAppImageData.PublicURL;
      } else {
        const isDrawable: boolean = this.addImageForm.get(this.IS_DRAWABLE).value;
        newImageData.FlagIsDrawable = isDrawable ? 1 : 0;
        newImageData.Tags = this.addImageForm.get(this.IMAGE_TAGS).value.trim().replace(/ +/g, " "); // remove unnecessary whitespaces

        if (isDrawable) {
          newImageData.Contents[0].Filename = this.addImageForm.get(this.IMAGE_NAME).value.trim().replace(/ +/g, " "); // remove unnecessary whitespaces
          newImageData.Contents[0].Content = this.addImageForm.get(this.DRAWABLE_CONTENT).value;
          newImageData.Drawable = this.addImageForm.get(this.DRAWABLE_CONTENT).value;
        } else {
          newImageData.Contents[0].Content = ImageUtils.removeBase64Header(newImageData.Contents[0].Content);
        }
      }
	  newImageData.ID = 0;
      this.alphaTechImageToUpload.imageData = newImageData;

      this.isSaving = true;
      this.onImageSelectedForUpload.emit(this.alphaTechImageToUpload);
    } else {
      FormValidatorUtil.validateFormElements(this.addImageForm);
      if (!this.isImageSelected) {
        this.toastrHelper.toastTheresIsNoImageSelected();
      } else {
        this.toastrHelper.toastInvalidFields();
      }
    }
  }

  onAppImageRemove() {
    this.imageData.Contents = [new ImageContent(null)];
    this.imageData.PublicURL = "";
  }

  onListTagClick(tag: string) {
    this.imageFilterForm.patchValue({ [this.IMAGES_TAGS]: tag });
    this.getOtherAppsImages(tag);
  }

  onSelectImage(imageData: ImageData) {
    this.selectedOtherAppImageData = imageData;
  }

  listenLanguageChange() {
    this.sessionChangeService.getLanguageSubject().subscribe(response => {
      this.translateService.use(response);
    });
  }


  isFormValid(): boolean {
    const isAddImageFormValid = this.isAddImageFormValid();

    const isDrawable: boolean = this.addImageForm.get(this.IS_DRAWABLE).value;
    this.isImageSelected = false;
    if (this.selectedOtherAppImageData && this.selectedOtherAppImageData.PublicURL) {
      this.isImageSelected = true;
    } else if (isDrawable && this.addImageForm.get(this.DRAWABLE_CONTENT).value) {
      this.isImageSelected = true;
    } else if (!isDrawable && this.imageData.Contents[0].Content) {
      this.isImageSelected = true;
    }

    return isAddImageFormValid && this.isImageSelected;
  }

  private isAddImageFormValid() {
    let isValid: boolean = true;

    // if drawable is selected
    if (this.addImageForm.get(this.IS_DRAWABLE).value) {
      isValid = this.addImageForm.get(this.IMAGE_NAME).valid && this.addImageForm.get(this.DRAWABLE_CONTENT).valid;
    }

    return isValid;
  }

  private initEditorConfig() {
    this.translateService.get("AppImages.DrawableEditorPlaceholder").subscribe(res => {
      this.editorConfig.placeholder = res;
    });
  }

  private initFilterAllImagesByTag() {
    const formControls: LooseObject = {};
    formControls[this.IMAGES_TAGS] = new FormControl("");

    this.imageFilterForm = new FormGroup(formControls);

    this.listenImageFilterValueChange();
  }

  private listenImageFilterValueChange() {
    this.subscriptions.push(this.imageFilterForm.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(result => {
      this.getOtherAppsImages(result[this.IMAGES_TAGS]);
    })
    );
  }

  private initAddImageForm() {
    const formControls: LooseObject = {};
    formControls[this.IMAGE_TAGS] = new FormControl("");
    formControls[this.IS_DRAWABLE] = new FormControl(false);
    formControls[this.DRAWABLE_CONTENT] = new FormControl("", [Validators.required]);
    formControls[this.IMAGE_NAME] = new FormControl("", [Validators.required]);
    this.addImageForm = new FormGroup(formControls);
    this.formValidation = new FormValidationModel(this.addImageForm);

    this.listenAddImageFormValueChange();
    this.listenIsDrawableChange([Validators.required]);
  }

  private listenIsDrawableChange(validationArray: any[]) {
    this.subscriptions.push(
      this.addImageForm.get(this.IS_DRAWABLE).valueChanges.subscribe(isDrawable => {
        // Dynamic validation handling
        if (isDrawable) {
          this.addImageForm.get(this.DRAWABLE_CONTENT).setValidators(validationArray);
        } else {
          this.addImageForm.get(this.DRAWABLE_CONTENT).clearValidators();
        }

        this.addImageForm.get(this.DRAWABLE_CONTENT).updateValueAndValidity();
      })
    );
  }

  private listenAddImageFormValueChange() {
    this.subscriptions.push(
      this.addImageForm.valueChanges.subscribe(result => {
        this.imageData.FlagIsDrawable = result[this.IS_DRAWABLE] ? 1 : 0;
        this.imageData.Tags = result[this.IMAGE_TAGS];
        if (this.imageData.FlagIsDrawable) {
          this.imageData.ImageName = result[this.IMAGE_NAME];
          this.imageData.Contents[0].Content = result[this.DRAWABLE_CONTENT];
          this.imageData.Contents[0].FlagIsDrawable = result[this.IS_DRAWABLE] ? 1 : 0;
        }
      }
      ));
  }

  private initAddFromRadio() {
    this.addFrom = new FormGroup({
      [this.RADIO]: new FormControl(0)
    });

    this.subscriptions.push(this.addFrom.valueChanges.subscribe(({ radio }) => {
      if (radio === 1) {
        this.getOtherAppsImages();
      } else {
        this.selectedOtherAppImageData = undefined;
      }
      this.isFromOtherApp = radio === 1;
    }));
  }

  private getOtherAppsImages(tags?: string) {
    this.isListLoading = true;
    const appId = this.onlyOtherApps ? -1 : this.appId;
    const excludedApp = this.onlyOtherApps ? this.appId : undefined;
    this.imagePresenter.getAppImageList(appId, tags || "", -1, excludedApp).subscribe(response => {
      this.allOtherImages = response.dataList;
      this.isListLoading = false;
    }, error => {
      this.isListLoading = false;
      this.router.navigate([routerPaths.Error]);
    });
  }

  private initNewImageData() {
    this.alphaTechImageToUpload = new AlphaTechImageToUpload();
    this.imageData = new ImageData();
    this.imageData.Contents.push(new ImageContent(""));
  }

}
