export enum RouterPath {
  ActivateUser = "activate-user",
  ActivationLinkSent = "activation-link-sent",
  Dashboard = "dashboard",
  DevStudio = "dev-studio",
  InternalServerError = "internal-server-error",
  Error = "error",
  ForgotPassword = "forgot-password",
  ChangePassword = "change-password",
  Login = "login",
  NotFound = "not-found",
  Unauthorized = "unauthorized",
  Profile = "profile",
  BusinessProfile = "business-profile",
  Redirect = "redirect",
  Register = "register",
  Bundles = "bundles",
  ViewNotification = "view-notification",
  Notifications = "notifications",
  PushNotifications = "push-notifications",
  MyApps = "my-apps",
  AddApp = "add-app",
  AppDetails = "app-details",
  PageBuilder = "page-builder",
  UserProfile = "profile",
  AppThemes = "app-themes",
  AppImages = "app-images",
  AppUsers = "app-users",
  AssignedDeltas = "assign-deltas",
  AssignedOutboundMessages = "assign-outbound-messages",
  AppThemesEditor = "app-themes-editor",
  AppGlobalOptions = "app-global-options",
  ActionConsentRequired = "action-consent-required",
  ActionChangePassword = "action-change-password",
  Deltas = "deltas",
  OutboundMessages = "outbound-messages",
  UserAudit = "user-audit",
  AppAudit = "app-audit",
  Analytics = "app-analytics",
  PageDesigner = "page-designer",
  AppInApp = "app-in-app-nav",
  Shopping = "shopping",
  AlphaProducts = "alpha-products",
  Subscriptions = "app-subscription",
  AppInterest = "app-interest"
}

export const routerPaths = {
  ActivateUser: "/" + RouterPath.ActivateUser,
  ActivationLinkSent: "/" + RouterPath.ActivationLinkSent,
  Dashboard: "/" + RouterPath.Dashboard,
  DevStudio: "/" + RouterPath.DevStudio,
  InternalServerError: "/" + RouterPath.InternalServerError,
  Error: "/" + RouterPath.Error,
  ForgotPassword: "/" + RouterPath.ForgotPassword,
  ChangePassword: "/" + RouterPath.ChangePassword,
  Login: "/" + RouterPath.Login,
  NotFound: "/" + RouterPath.NotFound,
  Unauthorized: "/" + RouterPath.Unauthorized,
  Profile: "/" + RouterPath.Profile,
  BusinessProfile: "/" + RouterPath.BusinessProfile,
  Redirect: "/" + RouterPath.Redirect,
  Register: "/" + RouterPath.Register,
  Bundles: "/" + RouterPath.Bundles,
  ViewNotification: "/" + RouterPath.Bundles + "/" + RouterPath.ViewNotification,
  MyApps: "/" + RouterPath.MyApps,
  AddApp: "/" + RouterPath.MyApps + "/" + RouterPath.AddApp,
  AppDetails: "/" + RouterPath.MyApps + "/" + RouterPath.AppDetails,
  PageBuilder: "/" + RouterPath.MyApps + "/" + RouterPath.PageBuilder,
  AppThemes: "/" + RouterPath.MyApps + "/" + RouterPath.AppThemes,
  AppImages: "/" + RouterPath.MyApps + "/" + RouterPath.AppImages,
  AppUsers: "/" + RouterPath.MyApps + "/" + RouterPath.AppUsers,
  AssignedDeltas: "/" + RouterPath.MyApps + "/" + RouterPath.AssignedDeltas,
  AssignedOutboundMessages: "/" + RouterPath.MyApps + "/" + RouterPath.AssignedOutboundMessages,
  Notifications: "/" + RouterPath.MyApps + "/" + RouterPath.Notifications,
  PushNotifications: "/" + RouterPath.MyApps + "/" + RouterPath.PushNotifications,
  AppThemeEditor: "/" + RouterPath.MyApps + "/" + RouterPath.AppThemesEditor,
  AppGlobalOptions: "/" + RouterPath.MyApps + "/" + RouterPath.AppGlobalOptions,
  ActionConsentRequired: "/" + RouterPath.ActionConsentRequired,
  ActionChangePassword: "/" + RouterPath.ActionChangePassword,
  Deltas: "/" + RouterPath.Deltas,
  OutboundMessages: "/" + RouterPath.OutboundMessages,
  UserAudit: "/" + RouterPath.UserAudit,
  AppAudit: "/" + RouterPath.AppAudit,
  Analytics: "/" + RouterPath.MyApps + "/" + RouterPath.Analytics,
  PageDesigner: "/" + RouterPath.MyApps + "/" + RouterPath.PageDesigner,
  AppInApp: "/" + RouterPath.MyApps + "/" + RouterPath.AppInApp,
  Shopping: "/" + RouterPath.MyApps + "/" + RouterPath.Shopping,
  AlphaProducts: "/" + RouterPath.MyApps + "/" + RouterPath.AlphaProducts,
  Subscriptions: "/" + RouterPath.MyApps + "/" + RouterPath.Subscriptions,
  AppInterest: "/" + RouterPath.MyApps + "/" + RouterPath.AppInterest,
};

export const PARAM_ACTIVATION_CODE = "ActivationCode";
export const PARAM_PASS = "Pass";
