import { BaseParams } from "src/app/core/models/base-requests.model";
import { GenericGeneralListResponse } from "src/app/core/models/base-responses.model";

export class CountryParams extends BaseParams { }

export class CountryResponse extends GenericGeneralListResponse<CountryData> { }

export class CountryData {
  Code: string;
  Name: string;
}
