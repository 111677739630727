import { Injectable } from "@angular/core";
import { AppInfo } from "src/app/modules/my-apps/model/my-apps.model";
import { BehaviorSubject } from "rxjs";
import { SessionAppInfoChange, SessionUserCreditChange, SessionUserInfoChange } from "../model/session-change";
import { UserLoginAction } from "src/app/core/models/login.model";
import { UserInfo } from "src/app/core/models/user-info.model";

@Injectable({
  providedIn: "root"
})
export class SessionChangeService {

  private readonly sessionAppInfoChangeSubject = new BehaviorSubject<SessionAppInfoChange>(new SessionAppInfoChange(new AppInfo, false));
  private readonly sessionUserCreditChangeSubject = new BehaviorSubject<SessionUserCreditChange>(new SessionUserCreditChange(0, false));
  private readonly sessionUserInfoChangeSubject = new BehaviorSubject<SessionUserInfoChange>(new SessionUserInfoChange(new UserInfo, false));
  private readonly sessionUpdateBillingRequired = new BehaviorSubject<UserLoginAction>(null);
  private readonly sessionAnnouncementSubject = new BehaviorSubject<UserLoginAction>(null);
  private readonly sessionLanguageSubject = new BehaviorSubject<string>(null);

  constructor() { }

  // App info change

  notifyAppInfoChange(appInfo: AppInfo) {
    this.sessionAppInfoChangeSubject.next(new SessionAppInfoChange(appInfo, true));
  }

  getSessionAppInfoSubject(): BehaviorSubject<SessionAppInfoChange> {
    return this.sessionAppInfoChangeSubject;
  }

  // User info change

  notifyUserInfoChange(userInfo: UserInfo) {
    this.sessionUserInfoChangeSubject.next(new SessionUserInfoChange(userInfo, true));
  }

  getSessionUserInfoSubject(): BehaviorSubject<SessionUserInfoChange> {
    return this.sessionUserInfoChangeSubject;
  }

  // User credit change

  notifyUserCreditChange(creditCount: number) {
    this.sessionUserCreditChangeSubject.next(new SessionUserCreditChange(creditCount, true));
  }

  getSessionUserCreditSubject(): BehaviorSubject<SessionUserCreditChange> {
    return this.sessionUserCreditChangeSubject;
  }

  // Announcement

  notifyAnnouncementChange(data: UserLoginAction) {
    this.sessionAnnouncementSubject.next(data);
  }

  getAnnouncementSubject(): BehaviorSubject<UserLoginAction> {
    return this.sessionAnnouncementSubject;
  }

  // Billing update required

  notifyUpdateBillingRequiredChange(data: UserLoginAction) {
    this.sessionUpdateBillingRequired.next(data);
  }

  getUpdateBillingRequiredSubject(): BehaviorSubject<UserLoginAction> {
    return this.sessionUpdateBillingRequired;
  }

  notifyLanguageChange(data: string) {
    this.sessionLanguageSubject.next(data);
  }

  getLanguageSubject(): BehaviorSubject<string> {
    return this.sessionLanguageSubject;
  }
}
