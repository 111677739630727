import { Injectable } from "@angular/core";
import { LZStringService } from "ng-lz-string";

@Injectable()
export class CompressService<T> {
  constructor(
    private lzStringService: LZStringService
  ) { }

  compress(value: T): any {
    return this.lzStringService.compress(JSON.stringify(value));
  }

  decompress(value: any): T {
    return JSON.parse(this.lzStringService.decompress(value));
  }
}
