export class DomUtils {

  /**
   * Copy function which can copy any input value in clipboard, no matter if the input element is disabled
   * @param inputElement
   */
  static copyInputText(inputElement) {
    if (inputElement) {
      const tempInputElement = document.createElement("textarea");
      tempInputElement.style.position = "fixed";
      tempInputElement.style.left = "0";
      tempInputElement.style.top = "0";
      tempInputElement.style.opacity = "0";
      tempInputElement.value = inputElement.value;
      document.body.appendChild(tempInputElement);
      tempInputElement.focus();
      tempInputElement.select();
      document.execCommand("copy");
      document.body.removeChild(tempInputElement);
    }
  }

  static focus(fieldTypeOrClassName: InputFieldType, fieldName: string): void;
  static focus(fieldTypeOrClassName: string): void;
  static focus(fieldTypeOrClassName: InputFieldType | string, fieldName?: string) {
    if (typeof fieldTypeOrClassName != "string") {
      switch (fieldTypeOrClassName) {
        case InputFieldType.Input:
          (<HTMLElement>document.querySelector(`input[id=${fieldName}]`)).focus();
          break;
        case InputFieldType.TextArea:
          (<HTMLElement>document.querySelector(`textarea[id=${fieldName}]`)).focus();
          break;
        case InputFieldType.Select:
          (<HTMLElement>document.querySelector(`select[id=${fieldName}]`)).focus();
          break;
      }
    } else {
      (<HTMLElement>document.getElementsByClassName(fieldTypeOrClassName)[0]).focus();
    }
  }

  static getHtmlElementHeightByClass(className: string, elementIndex: number = 0): number {
    let height: number = 0;
    if (document.getElementsByClassName(className).length > 0) {
      height = document.getElementsByClassName(className)[elementIndex].clientHeight;
    }
    return height;
  }

  static setElementHeightByClass(className: string, height: number, elementIndex: number = 0): void {
    if (document.getElementsByClassName(className).length > 0) {
      (<HTMLElement>document.getElementsByClassName(className)[elementIndex]).style.height = height + "px";
    }
  }
}

export enum InputFieldType {
  Input,
  TextArea,
  Select
}
