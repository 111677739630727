import { environment } from "../../../environments/environment";
// tslint:disable:no-console
// tslint:disable:no-any - to be able to call log for any type of object
export class LoggerUtil {

  public static log(className: string, message: string, obj?: any) {
    if (environment.isLogging) {
      if (obj) {
        console.log(className + " - " + message + ": ", obj);
      } else {
        console.log(className + " - " + message);
      }
    }
  }

}
