import { ToastrService } from "ngx-toastr";
import { BsModalService } from "ngx-bootstrap";
import { LazyLoad } from "src/app/shared/components/lazy-load";
import { ToastrHelper } from "src/app/shared/helpers/toaster.helper";
import { TranslateService } from "@ngx-translate/core";
import { Injector, TemplateRef, OnDestroy } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap";
import { Subscription, Observable, Subject } from "rxjs";
import { ResponseStatus } from "src/app/core/enums/response-status.enum";
import { LoggerUtil } from "src/app/shared/utils/logger.util";
import { GeneralResponse } from "src/app/core/models/base-responses.model";
import { RequestOperationType } from "./request-operation-type.model";
import { AlphaTechServerErrorService } from "src/app/components/error/alphatech-server-error.service";
import { AlphaTechServerMessage } from "src/app/shared/model/alphatech-server-message.model";
import { ModalUtils } from "../../utils/modal.utils";
import { GenericItemsPositionsUpdateParams } from "../../model/generic-item-positions-params";
import { GenericItemPositionService } from "../../services/generic/generic-item-position.service";
import { CommonModulesService, ModuleDataListener } from "src/app/modules/app-modules/service/common-module.service";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { SubscriptionUtil } from "../../utils/subscription.utils";
import { AlphaTechResponseData, AlphaTechResponseDataList } from "../../model/alphatech-response-data.model";
import { ServerMessageUtil } from "../../utils/server-message.utils";
import { PlatformLocation } from "@angular/common";

export abstract class BaseComponent extends LazyLoad implements OnDestroy {
  isComponentLoadFailed: boolean;
  isSaving: boolean = false;
  isLoading: boolean = false;
  isDeleting: boolean = false;
  isCloning: boolean = false;
  deletingId: number = -1;

  // Common Modules Service
  commonModulesService: CommonModulesService;

  // Module Data Service
  moduleDataListener: ModuleDataListener;

  // Modal
  bsModalRef: BsModalRef;
  modalService: BsModalService;

  // Subscriptions
  subscriptions: Subscription[] = [];

  // Toaster
  toastrHelper: ToastrHelper;

  // Translation
  translateService: TranslateService;

  // this is used for components that use modals to be able to close on navigation
  platformLocation: PlatformLocation;

  // Making lists orderable
  itemsArray: any[];
  itemPositionService: GenericItemPositionService;
  orderableListChangeSubject: Subject<any> = new Subject<any>();

  constructor(
    public injector: Injector
  ) {
    super();

    this.commonModulesService = this.injector.get(CommonModulesService);
    this.moduleDataListener = this.injector.get(ModuleDataListener);
    this.translateService = this.injector.get(TranslateService);
    this.modalService = this.injector.get(BsModalService);
    this.itemPositionService = this.injector.get(GenericItemPositionService);
    this.platformLocation = this.injector.get(PlatformLocation);

    this.toastrHelper = new ToastrHelper(
      this.injector.get(ToastrService),
      this.translateService
    );

    // close the currently open modal when navigating
    this.platformLocation.onPopState(() => {
      if (this.bsModalRef) {
        this.bsModalRef.hide();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length) {
      SubscriptionUtil.unsubscribe(this.subscriptions);
      this.subscriptions = [];
    }
  }

  presenterRequestHandler<T>(
    observableRequest: Observable<T>,
    operationType?: RequestOperationType,
    isComponentLoadFailedEnabled?: boolean,
  ): Observable<T> {
    this.handleRequestOperationVariables(operationType, true);

    return new Observable(observer => {
      observableRequest.subscribe(result => {
        const serverMessage: AlphaTechServerMessage = ServerMessageUtil.getServerMessage(result);

        this.handleRequestOperationVariables(operationType, false);

        observer.next(result);
        observer.complete();
      }, error => {
        if (error instanceof AlphaTechServerMessage) {
          this.handleFailToast(error);
        }

        this.handleRequestOperationVariables(operationType, false);

        if (isComponentLoadFailedEnabled) {
          this.isComponentLoadFailed = true;
        }

        LoggerUtil.log(this.constructor.name, error);
        observer.error(error);
      });
    });
  }

  onListOrdered($event) {
    // tslint:disable-next-line: deprecation // TODO: solve issues
    if (event instanceof DragEvent) {
      this.orderableListChangeSubject.next($event);
    }
  }

  showModalIgnoringBackdropClick(template: TemplateRef<any>): BsModalRef {
    return ModalUtils.showModalIgnoringBackdropClick(this.modalService, template);
  }

  showModalInFullScreen(template: TemplateRef<any>) {
    return ModalUtils.showModalInFullScreen(this.modalService, template);
  }

  changeCustomItemPriorityIndex(event, isApiCall?: boolean) {
    const orderUpdateParams: GenericItemsPositionsUpdateParams = new GenericItemsPositionsUpdateParams();
    orderUpdateParams.AppItemID = isApiCall ? undefined : this.commonModulesService.getSelectedModuleTileData().id;
    orderUpdateParams.ItemsType = isApiCall ? "OutboundMessageCall" : undefined;
    orderUpdateParams.Items = [];

    event.forEach((element, index) => {
      this.itemsArray.forEach(field => {
        if (element.ID == field.ID) {
          field.Position = index;
        }
      });
      orderUpdateParams.Items.push(element.ID);
    });

    this.presenterRequestHandler(
      this.itemPositionService.updateGenericItemPosition(orderUpdateParams)
    ).subscribe(response => {
      this.toastrHelper.showServerSuccess(response.UserMessage);
    });
  }

  listenListOrderingChange(isApiCall?: boolean) {
    this.subscriptions.push(this.orderableListChangeSubject.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe(event => {
      this.changeCustomItemPriorityIndex(event, isApiCall);
    }));
  }

  private handleRequestOperationVariables(operationType: RequestOperationType, inProgress: boolean) {
    if (operationType == RequestOperationType.DeletingById) {
      // after the deletion is finished
      if (!inProgress) {
        this.deletingId = -1;
      }
    } else if (operationType != RequestOperationType.None) {
      this[operationType] = inProgress;
    }
  }

  private handleFailToast(serverMessage: AlphaTechServerMessage) {
    this.toastrHelper.showServerError(serverMessage.userMessage);
  }

  private handleSuccessToast(serverMessage: AlphaTechServerMessage) {
    this.toastrHelper.showServerSuccess(serverMessage.userMessage);
  }
}
