import { ContentBanks } from "src/app/modules/app-modules/models/common.model";
import { IconMeta } from "../../../../../../shared/components/icon/icon.model";
import { GeneralData } from "../../../../../app-modules/common-elements/components/general/general-data.model";

// TODO: Tile data should be split in to two parts. ModuleTileData and ModuleMetaData. We should not use UI specific data in MetaData
export class ModuleTileData {
  id: number;
  featureId: number;
  tagFeatureId: number;
  featureName: string;
  iconMeta: IconMeta;
  title: string;
  subtitle: string;
  visibilityName: string;
  visibilityTypeId: number;
  generalData: GeneralData = new GeneralData();
  isFilteredOut?: boolean = false;
  isSelected: boolean;
  tagView: number;
  contentBanks: ContentBanks[] = [];

  constructor() { }
}
