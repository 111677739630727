import { Component, Input } from "@angular/core";

@Component({
  selector: "svg-icon",
  templateUrl: "./svg-icon.component.html",
})
export class SvgIconComponent {
  @Input() name: String;

  get absUrl() {
    return window.location.href;
  }

}
