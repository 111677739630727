
export enum Validation {
  Required = "required",
  Pattern = "pattern",
  CharactersValidation = "customChars",
  EqualTo = "equalTo",
  GreaterThanEqual = "gte",
  LowerThanEqual = "lte",
  Email = "email",
  Min = "min",
  Max = "max",
  MinLength = "minlength",
  MaxLength = "maxlength",
  Number = "number"
}

export const MIN_LENGTH = "MinLength";
export const MAX_LENGTH = "MaxLength";

// password
export const passwordMinLength = 6;
export const passwordMaxLength = 100;

// email
export const emailTemplateNameMaxLength = 60;
export const emailMaxLength = 200;

// phone
export const phoneMaxLength = 25;

// zip code
export const zipCodeMinLength = 4;
export const zipCodeMaxLength = 10;

// app & app page
export const appNameMaxLength = 80;
export const pageNameMaxLength = 100;
export const pagePrefixMaxLength = 10;
export const pageTitleMaxLength = 200;
export const pageDescriptionMaxLength = 2000;
export const appCodeMaxLength = 50;

// delta & outbound
export const deltaNameMaxLength = 60;
export const dataSourceNameMaxLength = 60;
export const outboundMessageNameMaxLength = 60;
export const apiCallNameMaxLength = 60;

export const paymentProviderNameMaxLength = 50;

// push notification
export const pushNotificationMessageMaxLength = 2000;
export const pushNotificationSubMessageMaxLength = 600;
export const audienceNameMaxLength = 50;

// general
export enum MaxLength {
  XXXSmall = 3,
  XXSmall = 5,
  XSmall = 10,
  Small = 20,
  Medium = 40,
  Large = 60,
  XLarge = 80,
  XXLarge = 100,
  XXXLarge = 200
}

export enum MinLength {
  XSmall = 1,
  Small = 3,
  Medium = 5,
  Large = 10,
  XLarge = 15,
  XXLarge = 20
}

export const minYear = new Date().getFullYear();
export const maxYear = new Date().getFullYear() + 50;
export const minMonth = 1;
export const maxMoth = 12;
export const buttonTextMaxLength = 20;

export const HTTP_PATTERN = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
export const YOUTUBE_PATTERN = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
export const PHONE_PATTERN = "^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$";
export const NUMBER_PATTERN = "[0-9.]+";
export const COORDINATES_PATTERN = /^(\-?\d+(\.\d+)?)$/;
export const NUMBER_WITH_SPACE_PATTERN = "[0-9 X]+";
export const DATE_PATTERN = /^\d{4}-(0[1-9]|1[0-2])-[0-3]\d\s([0-1][0-9]|2[0-3]):[0-5]\d$/;
export const REMOVE_WHITE_SPACE_PATTERN = /\s/g;
export const ONLY_LETTERS = "[a-zA-Z]+";
export const IDENTIFIERS = "[a-zA-Z.]+";
