export class SessionRoutingParams {
  appBuilderId: number;
  themeBundleIds: ThemeBundleSessionParams = new ThemeBundleSessionParams();
  changePasswordEmail: string;
  isFromUpdateBillingButton: boolean;
}

export class ThemeBundleSessionParams {
  themeBundleId: number;
  forceUpdateCache: boolean = false; // WARNING: if there is stored another item with true it will override it
}
