import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GeneralResponse } from "src/app/core/models/base-responses.model";
import { AlphaTechResponseData, AlphaTechResponseDataList } from "src/app/shared/model/alphatech-response-data.model";
import { AlphaTechSubscribeUtils } from "src/app/shared/utils/alpha-tech-subscribe.utils";
import {
  AppAvaiableData, AppAvailableAddParams, AppAvailableGetLanguageListParams,
  AppAvailableGetLanguageListParamsUpdate, AppGetLanguageListParams, AppLanguageData,
  LanguageData, LanguageGetListParams, LanguageSetParams
} from "../language-model/language-model.model";
import { LanguageService } from "../language-service/language-service.service";

@Injectable()
export class LanguagePresenter {
  constructor(
    private languageService: LanguageService
  ) { }

  getLanguageList(params: LanguageGetListParams): Observable<AlphaTechResponseDataList<LanguageData>> {
    return AlphaTechSubscribeUtils.subscribeDataListResponseHandling(
      this.languageService.getLanguageList(params)
    );
  }

  appLanguageList(params: AppGetLanguageListParams): Observable<AlphaTechResponseDataList<AppLanguageData>> {
    return AlphaTechSubscribeUtils.subscribeDataListResponseHandling(
      this.languageService.getAppLanguageList(params)
    );
  }

  getAppAvailableLanguageList(id: number): Observable<AlphaTechResponseDataList<AppAvaiableData>> {
    const params: AppAvailableGetLanguageListParams = new AppAvailableGetLanguageListParams();
    params.Filter.AppID = id;
    params.ForceAll = 1;

    return AlphaTechSubscribeUtils.subscribeDataListResponseHandling(
      this.languageService.getAppAvailableLanguageList(params)
    );
  }

  addAppAvailableList(params: AppAvailableAddParams): Observable<AlphaTechResponseDataList<AppAvaiableData>> {
    return AlphaTechSubscribeUtils.subscribeDataListResponseHandling(
      this.languageService.addAppAvailableLanguageList(params)
    );
  }

  updateAppAvailableList(params: AppAvailableGetLanguageListParamsUpdate): Observable<AlphaTechResponseDataList<AppAvaiableData>> {
    return AlphaTechSubscribeUtils.subscribeDataListResponseHandling(
      this.languageService.updateAppAvailableLanguageList(params)
    );
  }
}
