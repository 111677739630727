import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { CloneUtil } from "src/app/shared/utils/clone.utils";
import { GenericSingleItemInterface } from "./generic-listener.interface";

@Injectable()
export class GenericSingleItemListenerService<T> implements GenericSingleItemInterface<T> {
  private singleItem: T = {} as T;

  private singleItemChangeSubject = new BehaviorSubject<T>({} as T);

  notifySingleItemDataChanged(data: T) {
    this.singleItem = CloneUtil.getClonedInstance(data);
    this.singleItemChangeSubject.next(data);
  }

  getSingleItemDataSubject(): BehaviorSubject<T> {
    return this.singleItemChangeSubject;
  }

  setSingeItemData(data: T) {
    this.singleItem = CloneUtil.getClonedInstance(data);
  }

  getSingleItemData(): T {
    return this.singleItem;
  }

}
