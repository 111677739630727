import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap";

import { environment } from "src/environments/environment";

@Component({
  selector: "fullscreen-modal-wrapper",
  templateUrl: "./fullscreen-modal-wrapper.component.html",
  styleUrls: ["./fullscreen-modal-wrapper.component.scss"]
})
export class FullscreenModalWrapperComponent {
  @Input() title: string;
  @Input() modalReference: BsModalRef;
  @Input() overrideTabContentHeightClass: string;
  @Input() isFooterVisible: boolean;
  @Input() isGenericMenuVisible: boolean;
  @Input() isSaving: boolean;
  @Output() onClose = new EventEmitter();

  readonly environment = environment;

  onCloseClick() {
    this.onClose.emit();
    this.modalReference.hide();
  }

  onLinkClick() {
    this.modalReference.hide();
  }
}
