import { ModuleWithProviders } from "@angular/core/src/metadata/ng_module";
import { RouterModule, Routes } from "@angular/router";

import { ChangePasswordComponent } from "./components/change-password/change-password.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { ErrorPageComponent } from "./components/error/error.component";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { InternalServerErrorComponent } from "./components/internal-server-error/internal-server-error.component";
import { ActionChangePasswordComponent } from "./components/login-actions/change-password/action-change-password.component";
import { ActionConsentTermsComponent } from "./components/login-actions/consent-terms/action-consent-terms.component";
import { LoginComponent } from "./components/login/login.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { UnauthorizedComponent } from "./components/unauthorized/unauthorized.component";
import { PARAM_ACTIVATION_CODE, RouterPath } from "./core/consts/router-paths";
import { AuthorizedGuard } from "./core/guards/auth-guard";
import { NotAuthorizedGuard } from "./core/guards/not-auth-guard";
import { DevStudioComponent } from "./components/dev-studio/dev-studio.component";

export const routes: Routes = [
  {
    path: "",
    component: DashboardComponent,
    canActivate: [AuthorizedGuard]
  }, {
    path: RouterPath.Login,
    component: LoginComponent,
    canActivate: [NotAuthorizedGuard]
  }, {
    path: RouterPath.ForgotPassword,
    component: ForgotPasswordComponent,
    canActivate: [NotAuthorizedGuard]
  }, {
    path: RouterPath.ChangePassword + "/:" + PARAM_ACTIVATION_CODE,
    component: ChangePasswordComponent,
    canActivate: [NotAuthorizedGuard]
  }, {
    path: RouterPath.Unauthorized,
    component: UnauthorizedComponent
  }, {
    path: RouterPath.InternalServerError,
    component: InternalServerErrorComponent
  }, {
    path: RouterPath.Error,
    component: ErrorPageComponent
  }, {
    path: RouterPath.NotFound,
    component: NotFoundComponent
  }, {
    path: RouterPath.Dashboard,
    component: DashboardComponent,
    canActivate: [AuthorizedGuard]
  }, {
    path: RouterPath.DevStudio,
    component: DevStudioComponent,
    canActivate: [AuthorizedGuard]
  }, {
    path: RouterPath.ActionConsentRequired,
    component: ActionConsentTermsComponent
  }, {
    path: RouterPath.ActionChangePassword,
    component: ActionChangePasswordComponent
  }, {
    path: RouterPath.Register,
    loadChildren: "../app/modules/register/register.module#RegisterModule"
  }, {
    path: RouterPath.Bundles,
    loadChildren: "../app/modules/themes/themes.module#ThemesModule"
  }, {
    path: RouterPath.Notifications,
    loadChildren: "../app/modules/notifications/notifications.module#NotificationsModule"
  }, {
    path: RouterPath.ActivateUser,
    loadChildren: "../app/modules/activate-user/activate-user.module#ActivateUserModule"
  }, {
    path: RouterPath.MyApps,
    loadChildren: "../app/modules/my-apps/my-apps.module#MyAppsModule"
  }, {
    path: RouterPath.UserProfile,
    loadChildren: "../app/modules/user-profile/user-profile.module#UserProfileModule"
  }, {
    path: RouterPath.BusinessProfile,
    loadChildren: "../app/modules/business-profile/business-profile.module#BusinessProfileModule"
  }, {
    path: RouterPath.Deltas,
    loadChildren: "../app/modules/deltas/deltas.module#DeltasModule"
  }, {
    path: RouterPath.OutboundMessages,
    loadChildren: "../app/modules/outbound-messages/outbound-messages.module#OutboundMessagesModule"
  }, {
    path: RouterPath.UserAudit,
    loadChildren: "../app/modules/audit/audit.module#AuditModule"
  }, {
    path: RouterPath.AppAudit,
    loadChildren: "../app/modules/audit/audit.module#AuditModule"
  }, {
    path: "**",
    component: NotFoundComponent
  }
];

export const appRouter: ModuleWithProviders = RouterModule.forRoot(routes, {
  scrollPositionRestoration: "enabled",
  anchorScrolling: "enabled"
});
