// tslint:disable:no-import-side-effect
import { Observable } from "rxjs";
import "rxjs/add/observable/of";
import "rxjs/add/operator/map";
import "rxjs/add/operator/share";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class CacheServices {
  public cachedData: Map<string, any> = new Map();
  public cachedObservable: Map<string, Observable<any>> = new Map();

  constructor() { }

  get(cacheKey: string, request: Observable<any>) {
    if (this.cachedData.get(cacheKey)) {
      return Observable.of(this.cachedData.get(cacheKey));
    } else if (this.cachedObservable.get(cacheKey)) {
      return this.cachedObservable.get(cacheKey);
    } else {
      const requestData = request.map(response => {
        this.cachedObservable.set(cacheKey, null);
        this.cachedData.set(cacheKey, response);
        return this.cachedData.get(cacheKey);
      }).share();

      this.cachedObservable.set(cacheKey, requestData);
      return this.cachedObservable.get(cacheKey);
    }
  }

  clear() {
    this.cachedData = null;
    this.cachedObservable = null;
  }
}
