import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { BaseParams } from "../../core/models/base-requests.model";
import { EndpointsService } from "../../core/services/endpoint.service";
import { HttpService } from "../../core/services/http.service";
import { MetadataData, MetadataGetLastUpdateResponse, MetadataGetListParams, MetadataGetListResponse } from "../model/metadata.model";
import { ForceAllType } from "../model/system-lookup.model";

@Injectable({
  providedIn: "root"
})
export class MetadataService extends EndpointsService {
  private readonly GET_METADATA_LIST: string = `${this.API_URL}/MetadataGetList`;
  private readonly GET_METADATA_LAST_UPDATED: string = `${this.API_URL}/MetadataLastUpdated`;

  constructor(
    private httpService: HttpService
  ) {
    super();
  }

  getMetadataList(idFieldsOnly: boolean): Observable<MetadataGetListResponse> {
    const params: MetadataGetListParams = new MetadataGetListParams();
    params.ForceAll = ForceAllType.Activated;
    params.Filter.FlagReturnIdOnly = idFieldsOnly ? 1 : 0;

    return this.httpService.post_authorized(this.GET_METADATA_LIST, params);
  }

  getMetadataLastUpdate(): Observable<MetadataGetLastUpdateResponse> {
    const params: BaseParams = new BaseParams();

    return this.httpService.post_authorized(this.GET_METADATA_LAST_UPDATED, params);
  }
}
