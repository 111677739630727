export class DateFormatter {
  public static YYYY_MM_DD = "YYYY-MM-DD";
  public static YYYY_MM_DD_HH_mm = "YYYY-MM-DD HH:mm";
  public static M_d_yy_h_mm_ss_a = "M/d/yy h:mm:ss a";
  public static M = "M";
  public static MM = "MM";
  public static MMMM = "MMMM";
  public static HH_mm = "HH:mm";
  public static M_dd_yy = "M dd, yy";
  public static HH_mm_ss = "HH:mm:ss";
}
