import { NgModule, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateModule, TranslateLoader, TranslateService } from "@ngx-translate/core";

import { AppTranslateService } from "./app-translate.service";
import { SessionStorageService } from "../../core/services/session-storage.service";
import { languageDefaults } from "../../core/consts/language-defaults";
import { SessionChangeService } from "../services/session-change.service";


export const createTranslateLoader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
};

@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      isolate: false
    })],
  exports: [TranslateModule],
  declarations: [],
  providers: [],
})
export class TranslateRootModule implements OnInit {

  constructor(
    private translate: TranslateService,
    private sessionChangeService: SessionChangeService,
    private appTranslateService: AppTranslateService, private sessionStorageService: SessionStorageService) {
    const organizationInfo = this.sessionStorageService.getOrganizationInfo();
    this.translate.langs = [];
    if (organizationInfo && organizationInfo.AvailableLanguages) {
      const languages: string[] = [];
      organizationInfo.AvailableLanguages.forEach(item => {
        languages.push(item.Code);
      });
      this.translate.addLangs(languages);
    } else {
      this.translate.addLangs(languageDefaults.supportedLanguageCodes);
    }

    if (organizationInfo && organizationInfo.DefaultLanguageID && organizationInfo.AvailableLanguages
      && organizationInfo.AvailableLanguages.find(item => item.ID == organizationInfo.DefaultLanguageID)) {
      this.translate.setDefaultLang(organizationInfo.AvailableLanguages.find(item => item.ID == organizationInfo.DefaultLanguageID).Code);
    } else {
      this.translate.setDefaultLang(languageDefaults.defaultLanguageCode);
    }

    const user = this.sessionStorageService.getUserInfo();
    if (user && user.LanguageID && organizationInfo && organizationInfo.AvailableLanguages
      && organizationInfo.AvailableLanguages.find(item => item.ID == user.LanguageID)) {
      this.translate.use(organizationInfo.AvailableLanguages.find(item => item.ID == user.LanguageID).Code);
    }
    this.appTranslateService.add(translate);
  }

  ngOnInit() {
    this.translate.use(this.sessionStorageService.getLanguageCode());
    this.listenLanguageChange();

  }

  listenLanguageChange() {
    this.sessionChangeService.getLanguageSubject().subscribe(response => {
      this.translate.use(response);
    });
  }

}
