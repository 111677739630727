import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { CustomValidators } from "ng2-validation";

import { routerPaths } from "src/app/core/consts/router-paths";
import { emailMaxLength, Validation } from "src/app/core/consts/validation";
import { ResponseStatus } from "src/app/core/enums/response-status.enum";
import { AuthService } from "src/app/core/services/auth.service";
import { SessionStorageService } from "src/app/core/services/session-storage.service";
import { FormValidatorUtil } from "src/app/shared/utils/form-validator.util";
import { FormValidationModel } from "src/app/shared/validation/form-validation.model";
import { InternalCustomValidators } from "src/app/shared/validation/internal-custom-validators.model";
import { ValidationModel } from "src/app/shared/validation/validation.model";
import { LoggerUtil } from "src/app/shared/utils/logger.util";

@Component({
  selector: "forgot-password",
  templateUrl: "./forgot-password.component.html"
})
export class ForgotPasswordComponent implements OnInit {
  readonly EMAIL: string = "email";

  routerPaths = routerPaths;

  forgotPasswordForm: FormGroup;
  validationModels: any = {};
  formValidation: FormValidationModel;
  isSaving: boolean = false;
  isRegisterEnabled: boolean = false;
  isResetPasswordSent: boolean = false;
  validEmail: string;
  errorMessage: string;

  constructor(
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    const organizationInfo = this.sessionStorageService.getOrganizationInfo();
    if (organizationInfo) {
      this.isRegisterEnabled = organizationInfo.FlagRegisterEnabled !== 0;
    }

    this.initFormValidation();
  }

  resetPassword(event: any) {
    event.preventDefault();

    if (this.forgotPasswordForm.valid) {
      this.isSaving = true;
      this.errorMessage = "";
      this.validEmail = this.forgotPasswordForm.controls[this.EMAIL].value;
      this.authService.forgotPassword(this.validEmail).subscribe(response => {
        this.isSaving = false;

        if (response.Status == ResponseStatus.Success) {
          this.isResetPasswordSent = true;
        } else {
          this.errorMessage = response.UserMessage;
        }
      }, (error: any) => {
        this.isSaving = false;
        this.errorMessage = error.statusText;
        LoggerUtil.log(this.constructor.name, "resetPassword", error);
      });
    } else {
      FormValidatorUtil.validateFormElements(this.forgotPasswordForm);
    }
  }

  navigateToLogin() {
    this.router.navigate([routerPaths.Login]);
  }

  private initFormValidation() {
    const baseValidations = [Validators.required, InternalCustomValidators.noWhitespace];

    const formControls: LooseObject = {};
    formControls[this.EMAIL] = new FormControl("", [...baseValidations, CustomValidators.email, Validators.maxLength(emailMaxLength)]);
    this.forgotPasswordForm = new FormGroup(formControls);

    // email
    this.validationModels.emailInvalid = new ValidationModel(this.forgotPasswordForm, this.EMAIL, Validation.Email, "Validation.InvalidEmail");
    this.validationModels.emailMaxLength = new ValidationModel(this.forgotPasswordForm, this.EMAIL, Validation.MaxLength, "Validation.MaxLength", emailMaxLength);

    this.formValidation = new FormValidationModel(this.forgotPasswordForm);
  }

}
