import { PHInfo, VRInfo } from "../../../core/models/info.model";
import { UserInfo } from "../../../core/models/user-info.model";
import { BaseParams, GenericBaseListParams, BaseListParams } from "src/app/core/models/base-requests.model";
import { DateData, GenericGeneralResponse, GenericGeneralListResponse } from "src/app/core/models/base-responses.model";

export class UserData {
  CreatedByName: string;
  CreatedDate: DateData;
  ID: number;
  ModifiedByName: string;
  ModifiedDate: DateData;
  DateOfBirth: DateData;
  Email: string;
  FirstName: string;
  FlagIsAdvanced: number;
  LastName: string;
  FullName: string;
  Status: number;
  Title: string;
  UserAppLevelID: number;
  UserAppLevelText: string;
  UserLevelID: number;
  UserLevelText: string;
  ParentID: number;
  Validated: number;
  AppCount: number;
  AppCountPremium: number;
  AppCountStandard: number;
  AppMax: number;
  AppMaxPremium: number;
  CountryCode: string;
  LanguageID: number;
  OrganizationID: number;
  PHInfo: PHInfo;
  VRInfo: VRInfo;
}

export class UserGetParams extends BaseParams {
  ID: number;
}

export class UserGetListParams extends GenericBaseListParams<UserItemFilter> { }

export class UserGetResponse extends GenericGeneralResponse<UserData> { }

export class UserGetListResponse extends GenericGeneralListResponse<UserData> { }

export class UserUpdateParams extends BaseParams {
  Data: UserInfo;
}

export class UserLanguageUpdateParams {
  AuthToken: string;
  LanguageID: number;

  constructor(id?: number) {
    if (id) {
      this.LanguageID = id;
    }
  }
}

export class UserEmailUpdateParams {
  AuthToken: string;
  LanguageID: number;
  Email: string;
}

export class UserItemFilter extends BaseListParams {
  Status: number;
  AppID: number;
  Keyword: string;
  LevelID: number;
  OrganizationID: number;
  FlagIgnoreDetails: number;
}

export enum UserProfileTabs {
  MyProfile = 1,
  BillingDetails = 2,
  Subscriptions = 3,
  BillingHistory = 4
}
