import { Component, OnInit, Input, Output, EventEmitter, TemplateRef, SimpleChanges, OnChanges } from "@angular/core";
import { ItemToDelete } from "../../confirm-delete-modal/item-to-delete";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { AlertType } from "src/app/shared/model/bootstrap-alerts.enum";
import { ModalUtils } from "src/app/shared/utils/modal.utils";
import { ImageUploadType } from "./image-uploader-type.model";

@Component({
  selector: "alpha-tech-image-uploader",
  templateUrl: "./alpha-tech-image-uploader.component.html",
  styleUrls: ["alpha-tech-image-uploader.component.scss"]
})
export class AlphaTechImageUploaderComponent implements OnInit, OnChanges {
  @Input() onlyOtherApps: boolean;
  @Input() type: ImageUploadType = ImageUploadType.Simple;
  @Input() multiSizeImageSizes: number[] = [120, 88, 76, 58, 40, 29];
  @Input() imageSource: string;
  @Input() imageSources: string[] = [];
  @Input() isImageHeaderOn: boolean = true;
  @Input() isImageValid: boolean;
  @Input() isHeaderImage: boolean;
  @Input() isSplashImage: boolean;
  @Input() subtitle: string;
  @Input() pageHeaderImageDescription: string;
  @Input() uploadButtonTitle: string;
  @Input() imageValidationText: string;

  @Output() onUploadImage: EventEmitter<ImageData> = new EventEmitter();
  @Output() onDelete: EventEmitter<number> = new EventEmitter();

  readonly imageUploadTypeEnum = ImageUploadType;
  readonly NO_IMAGE_PATH = "assets/images/no-image.png";

  itemToDelete: ItemToDelete<any>;
  modalRef: BsModalRef;
  visibleImageDeleteField: number;
  alertIcon = {
    type: AlertType.Danger
  };
  classImageSource: string;

  constructor(private modalService: BsModalService) { }

  ngOnInit(): void {
    this.classImageSource = this.imageSource;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.classImageSource = changes.imageSource ? changes.imageSource.currentValue : this.classImageSource;
  }

  showAlphaTechUploadImageModal(template: TemplateRef<any>) {
    this.modalRef = ModalUtils.showModalIgnoringBackdropClick(this.modalService, template);
  }

  openConfirmationModal(template: TemplateRef<any>) {
    this.itemToDelete = new ItemToDelete(0, "");
    this.modalRef = ModalUtils.showModalIgnoringBackdropClick(this.modalService, template);
  }

  deleteImage() {
    this.classImageSource = undefined;
    this.modalRef.hide();
    this.onDelete.emit();
  }

  deleteFromImages(index: number) {
    this.imageSources.splice(index, 1);
    this.onDelete.emit(index);
  }

  onImageSelectedForUpload(addedImageData: ImageData) {
    this.classImageSource = this.imageSource;
    this.onUploadImage.emit(addedImageData);
    this.modalRef.hide();
  }

  onMultipleImageMouseLeave() {
    this.visibleImageDeleteField = null;
  }

  onMultipleImageMouseEnter(index: number) {
    this.visibleImageDeleteField = index;
  }

}
