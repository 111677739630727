import { Injectable } from "@angular/core";
import { BehaviorSubject, Subscription, Observable } from "rxjs";
import { DomUtils } from "src/app/shared/utils/dom.utils";

@Injectable({
  providedIn: "root"
})
export class NotificationBarListenerService {
  private isNotificationBarVisible: boolean;
  private notificationBarVisibleChangeSubject = new BehaviorSubject<boolean>(false);

  notifyNotificationBarVisibilityChange(isVisible: boolean) {
    this.isNotificationBarVisible = isVisible;
    this.notificationBarVisibleChangeSubject.next(isVisible);
  }

  getNotificationBarVisibleSubject(): BehaviorSubject<boolean> {
    return this.notificationBarVisibleChangeSubject;
  }

  changeElementHeight(className: string): Observable<number> {
    return new Observable(observer => {
      if (this.isNotificationBarVisible) {
        setTimeout(() => {
          const initialContentHeight: number = DomUtils.getHtmlElementHeightByClass(className);
          const notificationHeight: number = DomUtils.getHtmlElementHeightByClass("notification-bar");
          DomUtils.setElementHeightByClass(className, initialContentHeight - notificationHeight);
          observer.next(initialContentHeight);
          observer.complete();
        });
      } else {
        observer.complete();
      }
    });
  }

  notificationBarDismissListener(className: string, initialHeight: number): Subscription {
    return this.getNotificationBarVisibleSubject().subscribe(response => {
      if (!response) {
        if (!this.isNotificationBarVisible) {
          DomUtils.setElementHeightByClass(className, initialHeight);
        }
      }
    });
  }
}
