import { AlphaTechServerMessage } from "./alphatech-server-message.model";
import { UserInfo } from "src/app/core/models/user-info.model";
import { OrganizationInfo } from "src/app/core/models/organization-info.model";

export class AlphaTechResponseData<ReturnType> {
  data: ReturnType;
  userInfo: UserInfo;
  serverMessage: AlphaTechServerMessage;
  organizationInfo: OrganizationInfo;
}

export class AlphaTechResponseDataList<ReturnType> {
  dataList: ReturnType[];
  totalCount: number;
  serverMessage: AlphaTechServerMessage;
  userInfo: UserInfo;
}
