import { HttpClient } from "@angular/common/http";
import { NgModule, OnInit } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { BsDropdownModule, ModalModule } from "ngx-bootstrap";

import { AppLoadModule } from "./app-load/app-load.module";
import { appRouter } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ChangePasswordComponent } from "./components/change-password/change-password.component";
import { ChangePasswordService } from "./components/change-password/service/change-password.service";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { DevStudioComponent } from "./components/dev-studio/dev-studio.component";
import { ErrorPageComponent } from "./components/error/error.component";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { InternalServerErrorComponent } from "./components/internal-server-error/internal-server-error.component";
import { ActionChangePasswordComponent } from "./components/login-actions/change-password/action-change-password.component";
import { ActionConsentTermsComponent } from "./components/login-actions/consent-terms/action-consent-terms.component";
import { LoginComponent } from "./components/login/login.component";
import { NavBarComponent } from "./components/nav-bar/nav-bar.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { UnauthorizedComponent } from "./components/unauthorized/unauthorized.component";
import { AppCoreModule } from "./core/core.module";
import { AuthorizedGuard } from "./core/guards/auth-guard";
import { NotAuthorizedGuard } from "./core/guards/not-auth-guard";
import { RegisterEnabledGuard } from "./core/guards/register-enabled-guard";
import { AuthService } from "./core/services/auth.service";
import { HttpService } from "./core/services/http.service";
import { StorageService } from "./core/services/storage.service";
import { PurchaseModule } from "./modules/billing/modules/purchase/purchase.module";
import { GenericComponentListenerService } from "./shared/services/generic/generic-component-listener.service";
import { SharedModule } from "./shared/shared.module";
import { AppTranslateService } from "./shared/translate/app-translate.service";
import { GenericSingleItemListenerService } from "./shared/services/generic/generic-single-item-listener.service";
import { NotificationBarComponent } from "./components/notification-bar/notification-bar.component";
import { AdvancedUserGuard } from "./core/guards/advanced-guard";
import { UserPresenter } from "./modules/user-profile/presenters/user.presenter";
import { SessionStorageService } from "./core/services/session-storage.service";
import { SessionChangeService } from "./shared/services/session-change.service";

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    InternalServerErrorComponent,
    ErrorPageComponent,
    LoginComponent,
    NavBarComponent,
    NotFoundComponent,
    UnauthorizedComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    ActionConsentTermsComponent,
    ActionChangePasswordComponent,
    DevStudioComponent,
    NotificationBarComponent
  ],
  imports: [
    appRouter,
    BrowserAnimationsModule,
    AppLoadModule,
    AppCoreModule,
    SharedModule,
    PurchaseModule,
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      isolate: false
    })
  ],
  providers: [
    HttpService,
    AuthService,
    UserPresenter,
    StorageService,
    AdvancedUserGuard,
    AuthorizedGuard,
    NotAuthorizedGuard,
    RegisterEnabledGuard,
    AppTranslateService,
    ChangePasswordService,
    GenericComponentListenerService,
    GenericSingleItemListenerService
  ],
  bootstrap: [AppComponent]
})
export class AppModule implements OnInit {

  constructor(
    private translate: TranslateService,
    private sessionStorageService: SessionStorageService,
    private sessionChangeService: SessionChangeService,
  ) { }



  ngOnInit() {
    this.translate.use(this.sessionStorageService.getLanguageCode());
    this.listenLanguageChange();
  }

  listenLanguageChange() {
    this.sessionChangeService.getLanguageSubject().subscribe(response => {
      this.translate.use(response);
    });

  }

}


// tslint:disable-next-line:only-arrow-functions
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}

