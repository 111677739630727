import { AlphaTechServerMessage } from "src/app/shared/model/alphatech-server-message.model";
import { AlphaTechResponseData, AlphaTechResponseDataList } from "../model/alphatech-response-data.model";
import { ToastrHelper } from "../helpers/toaster.helper";

export class ServerMessageUtil {

  static getServerMessage(input: any): AlphaTechServerMessage {
    let serverMessage: AlphaTechServerMessage;

    if (AlphaTechServerMessage.isAlphaTechResponse(input)) {
      serverMessage = new AlphaTechServerMessage(input);
    } else if (input instanceof AlphaTechResponseData || input instanceof AlphaTechResponseDataList) {
      serverMessage = input.serverMessage;
    }

    return serverMessage;
  }

  static toastServerMessage(toastrHelper: ToastrHelper, input: any) {
    if (input instanceof AlphaTechServerMessage) {
      toastrHelper.showServerError(input.userMessage);
    }
  }

}
