import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";

import { EndpointsService } from "../../../core/services/endpoint.service";
import { HttpService } from "../../../core/services/http.service";
import {
  PackageAppAssignmentAddParams, PackageAppAssignmentAddResponse, PackageDetailsGetListParams, PackageDetailsGetListResponse, PackageGetListParams,
  PackageGetListResponse, PackageSubscriptionAddParams, PackageSubscriptionAddResponse, PackageSubscriptionCancelParams, PackageSubscriptionCancelResponse,
  PackageSubscriptionGetListParams, PackageSubscriptionGetListResponse, PackageUserInvoiceGetListParams, PackageUserInvoiceGetListResponse,
  PackageUserInvoiceLineGetListParams, PackageUserInvoiceLineGetListResponse, PackageUserInvoicePaymentGetListParams, PackageUserInvoicePaymentGetListResponse,
  PackageData, PackageAddParams, PackageDetailsData
} from "../models/package.model";
import {
  UserBillingDeleteParams, UserBillingDeleteResponse, UserBillingGetParams, UserBillingGetResponse, UserBillingUpdateParams, UserBillingUpdateResponse
} from "../models/user-billing.model";
import { GenericGeneralResponse, GeneralResponse } from "src/app/core/models/base-responses.model";
import { BaseParamsWithID } from "src/app/core/models/base-requests.model";

@Injectable({
  providedIn: "root"
})
export class BillingService extends EndpointsService {

  // Package
  private readonly GET_PACKAGE_LIST: string = `${this.API_URL}/PackageGetList`;
  private readonly GET_PACKAGE: string = `${this.API_URL}/PackageGet`;
  private readonly PACKAGE_ADD: string = `${this.API_URL}/PackageAdd`;
  private readonly PACKAGE_UPDATE: string = `${this.API_URL}/PackageUpdate`;
  private readonly PACKAGE_DELETE: string = `${this.API_URL}/PackageDelete`;

  // Package <=> App
  private readonly ADD_PACKAGE_APP_ASSIGNMENT: string = `${this.API_URL}/PackageAppAssignmentAdd`;

  // Package subscription
  private readonly GET_PACKAGE_SUBSCRIPTION_LIST: string = `${this.API_URL}/PackageSubscriptionGetList`;
  private readonly ADD_PACKAGE_SUBSCRIPTION: string = `${this.API_URL}/PackageSubscriptionAdd`;
  private readonly CANCEL_PACKAGE_SUBSCRIPTION: string = `${this.API_URL}/PackageSubscriptionCancel`;

  // Package details
  private readonly GET_PACKAGE_DETAILS_LIST: string = `${this.API_URL}/PackageDetailGetList`;
  private readonly GET_PACKAGE_DETAIL: string = `${this.API_URL}/PackageDetailGet`;

  // User billing
  private readonly GET_USER_BILLING: string = `${this.API_URL}/UserBillingGet`;
  private readonly UPDATE_USER_BILLING: string = `${this.API_URL}/UserBillingUpdate`;
  private readonly DELETE_USER_BILLING: string = `${this.API_URL}/UserBillingDelete`;

  // Billing history
  private readonly GET_PACKAGE_USER_INVOICE_LIST: string = `${this.API_URL}/PackageUserInvoiceGetList`;
  private readonly GET_PACKAGE_USER_INVOICE_PAYMENT_LIST: string = `${this.API_URL}/PackageUserInvoicePaymentGetList`;
  private readonly GET_PACKAGE_USER_INVOICE_LINE_LIST: string = `${this.API_URL}/PackageUserInvoiceLineGetList`;

  constructor(
    private httpService: HttpService
  ) {
    super();
  }

  // ==================================================================================================================
  // ---------------------------- PACKAGE ---------------------------------------------------------------------------------

  getPackageList(params: PackageGetListParams): Observable<PackageGetListResponse> {
    return this.httpService.post_authorized(this.GET_PACKAGE_LIST, params);
  }

  getPackage(params: BaseParamsWithID): Observable<GenericGeneralResponse<PackageData>> {
    return this.httpService.post_authorized(this.GET_PACKAGE, params);
  }

  addPackage(params: PackageAddParams): Observable<GenericGeneralResponse<PackageData>> {
    return this.httpService.post_authorized(this.PACKAGE_ADD, params);
  }

  updatePackage(params: PackageAddParams): Observable<GenericGeneralResponse<PackageData>> {
    return this.httpService.post_authorized(this.PACKAGE_UPDATE, params);
  }

  deletePackage(params: BaseParamsWithID): Observable<GeneralResponse> {
    return this.httpService.post_authorized(this.PACKAGE_DELETE, params);
  }

  // ==================================================================================================================
  // ---------------------------- PACKAGE SUBSCRIPTION -------------------------------------------------------------

  getPackageSubscriptionList(params: PackageSubscriptionGetListParams): Observable<PackageSubscriptionGetListResponse> {
    return this.httpService.post_authorized(this.GET_PACKAGE_SUBSCRIPTION_LIST, params);
  }

  addPackageSubscription(params: PackageSubscriptionAddParams): Observable<PackageSubscriptionAddResponse> {
    return this.httpService.post_authorized(this.ADD_PACKAGE_SUBSCRIPTION, params);
  }

  cancelPackageSubscription(params: PackageSubscriptionCancelParams): Observable<PackageSubscriptionCancelResponse> {
    return this.httpService.post_authorized(this.CANCEL_PACKAGE_SUBSCRIPTION, params);
  }

  // ==================================================================================================================
  // ---------------------------- PACKAGE <=> APP -------------------------------------------------------------

  addPackageAppAssignment(params: PackageAppAssignmentAddParams): Observable<PackageAppAssignmentAddResponse> {
    return this.httpService.post_authorized(this.ADD_PACKAGE_APP_ASSIGNMENT, params);
  }

  // ==================================================================================================================
  // ---------------------------- PACKAGE DETAILS ---------------------------------------------------------------------

  getPackageDetailsList(params: PackageDetailsGetListParams): Observable<PackageDetailsGetListResponse> {
    return this.httpService.post_authorized(this.GET_PACKAGE_DETAILS_LIST, params);
  }

  getPackageDetails(params: BaseParamsWithID): Observable<GenericGeneralResponse<PackageDetailsData>> {
    return this.httpService.post_authorized(this.GET_PACKAGE_DETAIL, params);
  }

  // ==================================================================================================================
  // ---------------------------- USER BILLING ---------------------------------------------------------------------

  getUserBilling(params: UserBillingGetParams): Observable<UserBillingGetResponse> {
    return this.httpService.post_authorized(this.GET_USER_BILLING, params);
  }

  updateUserBilling(params: UserBillingUpdateParams): Observable<UserBillingUpdateResponse> {
    return this.httpService.post_authorized(this.UPDATE_USER_BILLING, params);
  }

  deleteUserBilling(params: UserBillingDeleteParams): Observable<UserBillingDeleteResponse> {
    return this.httpService.post_authorized(this.DELETE_USER_BILLING, params);
  }

  // ==================================================================================================================
  // ---------------------------- BILLING HISTORY ---------------------------------------------------------------------

  getPackageUserInvoiceList(params: PackageUserInvoiceGetListParams): Observable<PackageUserInvoiceGetListResponse> {
    return this.httpService.post_authorized(this.GET_PACKAGE_USER_INVOICE_LIST, params);
  }

  getPackageUserInvoicePaymentList(params: PackageUserInvoicePaymentGetListParams): Observable<PackageUserInvoicePaymentGetListResponse> {
    return this.httpService.post_authorized(this.GET_PACKAGE_USER_INVOICE_PAYMENT_LIST, params);
  }

  getPackageUserInvoiceLineList(params: PackageUserInvoiceLineGetListParams): Observable<PackageUserInvoiceLineGetListResponse> {
    return this.httpService.post_authorized(this.GET_PACKAGE_USER_INVOICE_LINE_LIST, params);
  }

}
