import { Component, EventEmitter, Injector, OnInit, Output, Input } from "@angular/core";
import { Router } from "@angular/router";
import { zip } from "rxjs";
import { finalize } from "rxjs/operators";

import { routerPaths } from "../../../../../core/consts/router-paths";
import { UserInfo } from "../../../../../core/models/user-info.model";
import { SessionStorageService } from "../../../../../core/services/session-storage.service";
import { CountryData } from "../../../../../modules/user-profile/model/country.model";
import { CountryPresenter } from "../../../../../modules/user-profile/presenters/country.presenter";
import { BaseComponent } from "../../../../../shared/components/base-components/base-component";
import { RequestOperationType } from "../../../../../shared/components/base-components/request-operation-type.model";
import { ModalType } from "../../../../../shared/components/modals/modal-type.model";
import { SessionChangeService } from "../../../../../shared/services/session-change.service";
import { CreditUtils } from "../../../../../shared/utils/credit.utils";
import { UserBillingData, UserBillingTile } from "../../../models/user-billing.model";
import { BillingPresenter } from "../../../presenters/billing.presenter";
import { PurchasePackageTile } from "../purchase-package-tile/purchase-package-tile.modal";
import { ModalUtils } from "src/app/shared/utils/modal.utils";
import { FIRST_PAGE_NUMBER } from "./../../../../../core/consts/request-defaults";
import { ServerMessageUtil } from "src/app/shared/utils/server-message.utils";

@Component({
  selector: "purchase-content",
  templateUrl: "./purchase-content.component.html",
})
export class PurchaseContentComponent extends BaseComponent implements OnInit {
  @Input() enableFlagBusiness: boolean;
  @Output() onPurchaseConfirmed: EventEmitter<void> = new EventEmitter();

  readonly purchaseStepEnum = PurchaseStep;
  readonly modalTypeEnum = ModalType;

  step: PurchaseStep = PurchaseStep.Description;
  purchasePackageTiles: PurchasePackageTile[] = [];
  selectedPurchasePackageTile: PurchasePackageTile;
  userBillingTileList: UserBillingTile[] = [];
  selectedUserBillingData: UserBillingData;
  countryDataList: CountryData[];
  organizationName: string;
  orderNumber: number;
  isPaymentSuccessful: boolean;
  paymentSource: string;
  orderDate: string;
  nextPaymentDate: string;
  transactionId: string;

  constructor(
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private sessionChangeService: SessionChangeService,
    private billingPresenter: BillingPresenter,
    private countryPresenter: CountryPresenter,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.organizationName = this.sessionStorageService.getOrganizationName();
    this.getData();
  }

  onPackageTileClicked(tile: PurchasePackageTile) {
    this.selectedPurchasePackageTile = tile;
    this.purchasePackageTiles.forEach(currentTile => {
      currentTile.isSelected = (tile.id == currentTile.id);
    });
  }

  onCardClicked(tile: UserBillingTile) {
    this.selectedUserBillingData = tile.data;
    this.userBillingTileList.forEach(currentTile => {
      currentTile.isSelected = (tile.id == currentTile.id);
    });
  }

  confirmSubscription() {
    if (this.isFormValid()) {
      this.presenterRequestHandler(
        this.billingPresenter.addPackageSubscription(this.selectedPurchasePackageTile.id),
        RequestOperationType.IsSaving,
      ).subscribe(
        response => {
          if (response) {
            this.step = PurchaseStep.SuccessfullyProcessed;
            this.paymentSource = response.data.CardInUse;
            this.orderDate = response.data.CreatedDate.Date;
            this.orderNumber = response.data.ID;
            this.isPaymentSuccessful = true;
            this.transactionId = response.data.LastSuccessfulPaymentIdentifier;
            this.nextPaymentDate = response.data.NextPaymentDate.Date;

            this.toastrHelper.showServerSuccess(response.serverMessage.userMessage);
            this.updateCreditCount(this.selectedPurchasePackageTile.credit);
          }
        }
      );
    } else {
      this.toastrHelper.toastError("Validation.SelectCardAndPackage");
    }
  }

  purchaseConfirmed() {
    this.onPurchaseConfirmed.emit();
  }

  // -------------------- Add user billing modal --------------------

  showAddUserBillingModal(template) {
    this.presenterRequestHandler(
      this.countryPresenter.getCountries(),
      RequestOperationType.IsLoading
    ).subscribe(
      response => {
        this.countryDataList = response.dataList;
        this.bsModalRef = ModalUtils.showModalInFullScreen(this.modalService, template);
      },
      error => {
        this.router.navigate([routerPaths.Error]);
      }
    );
  }

  onAddedUserBilling(userBillingData: UserBillingData) {
    this.userBillingTileList.push(new UserBillingTile(userBillingData));
  }

  onAddUserBillingModalClose() {
    this.bsModalRef.hide();
  }

  onAddUserBillingModalCancel() {
    this.bsModalRef.hide();
  }

  // -------------------- Other --------------------

  isFormValid(): boolean {
    return this.selectedPurchasePackageTile && this.selectedUserBillingData ? true : false;
  }

  private getData() {
    this.isLoading = true;
    zip(
      this.billingPresenter.getPackageList(FIRST_PAGE_NUMBER, true, this.enableFlagBusiness ? 1 : 0),
      this.billingPresenter.getUserBilling(),
    ).pipe(finalize(() => this.isLoading = false)).subscribe(
      ([packageDataListResponse, userBillingDataResponse]) => {
        if (packageDataListResponse) {
          packageDataListResponse.dataList.forEach(packageData => {
            if (packageData.Language !== null) {
              if (packageData.Language.Description !== null) {
                const purchasePackageTile = new PurchasePackageTile();
                purchasePackageTile.id = packageData.ID;
                purchasePackageTile.credit = packageData.AppCount;
                purchasePackageTile.title = packageData.Language.Description;
                purchasePackageTile.monthlyCost = packageData.PriceMonthly;
                purchasePackageTile.chargedNowPrice = packageData.PricePartial;
                if (packageData.StartDate && packageData.EndDate) {
                  purchasePackageTile.billingCyclePeriod = " (" + packageData.StartDate.Date + " - " + packageData.EndDate.Date + ") ";
                }
                this.purchasePackageTiles.push(purchasePackageTile);
              }
            }
          });
          this.selectFirstPackageIfExits(this.purchasePackageTiles);
        }
        if (userBillingDataResponse.data) {
          this.userBillingTileList.push(new UserBillingTile(userBillingDataResponse.data));
          this.selectFirstCardIfExits(this.userBillingTileList);
        }
      }, error => {
        ServerMessageUtil.toastServerMessage(this.toastrHelper, error);
        this.router.navigate([routerPaths.Error]);
      }
    );
  }

  private selectFirstPackageIfExits(purchasePackageTiles: PurchasePackageTile[]) {
    const firstItem: PurchasePackageTile = purchasePackageTiles.length > 0 ? purchasePackageTiles[0] : undefined;
    if (firstItem) {
      firstItem.isSelected = true;
      this.selectedPurchasePackageTile = firstItem;
    }
  }

  private selectFirstCardIfExits(userBillingTileList: UserBillingTile[]) {
    const firstItem: UserBillingTile = userBillingTileList.length > 0 ? userBillingTileList[0] : undefined;
    if (firstItem) {
      firstItem.isSelected = true;
      this.selectedUserBillingData = firstItem.data;
    }
  }

  private updateCreditCount(purchasedCreditCount: number) {
    const clientCreditCount = CreditUtils.getClientCreditCount(this.sessionStorageService);
    this.sessionChangeService.notifyUserCreditChange(clientCreditCount + purchasedCreditCount);

    this.updateUserCreditCountInSessionStorage(purchasedCreditCount);
  }

  private updateUserCreditCountInSessionStorage(purchasedCreditCount: number) {
    const userInfo: UserInfo = this.sessionStorageService.getUserInfo();
    userInfo.AppMaxPremium += purchasedCreditCount;
    this.sessionStorageService.setUserInfo(userInfo);
  }
}

export enum PurchaseStep {
  Description,
  SuccessfullyProcessed
}
