import { BaseParams } from "./base-requests.model";
import { GeneralResponse } from "./base-responses.model";

export class UserResetPasswordParams extends BaseParams {
  ActivationCode: string;
  Password: string;

  constructor(activationCode: string, password: string, authToken?: string, languageId?) {
    super(authToken, languageId);
    this.ActivationCode = activationCode;
    this.Password = password;
  }
}

export class UserResetPasswordResponse extends GeneralResponse { }
