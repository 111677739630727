import { Icon } from "src/app/shared/model/icons.model";
import { ThemeBundleData } from "src/app/modules/themes/model/themes-bundle.model";
import { TagViews } from "./tag-view/tag-view.model";
import { IconMeta } from "src/app/shared/components/icon/icon.model";

export class GeneralData {
  isActive: boolean;
  pageTitle: string;
  pageName: string;
  pageDescription: string;
  selectedIconMeta: IconMeta;
  themeBundle: ThemeBundleData;
  featureIcons: Icon[];
  selectedTagView: TagViews;
}
