import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { EndpointsService } from "../../core/services/endpoint.service";
import { HttpService } from "../../core/services/http.service";
import { LookupType, SystemLookupParams, SystemLookupResponse, ForceAllType } from "../model/system-lookup.model";
import { CacheServices } from "./cache-manager.service";

@Injectable({
  providedIn: "root"
})
export class LookupService extends EndpointsService {
  private readonly GET_SYSTEM_LOOKUP: string = `${this.API_URL}/SystemLookupGetList`;

  constructor(
    private httpService: HttpService,
    private cacheService: CacheServices
  ) {
    super();
  }

  getSystemLookup(lookupTypes: LookupType[]): Observable<SystemLookupResponse> {
    const systemLookupParams: SystemLookupParams = new SystemLookupParams();
    systemLookupParams.ForceAll = ForceAllType.Activated;
    systemLookupParams.Filter.SystemLookupTypes = lookupTypes;

    return this.cacheService.get(
      this.GET_SYSTEM_LOOKUP + "_" + lookupTypes.join("_"),
      this.httpService.post_authorized(this.GET_SYSTEM_LOOKUP, systemLookupParams)
    );
  }
}
