import { VisibilityLookup } from "src/app/shared/model/lookup/visibility-lookup.model";

export class VisibilityLookupChange {
  visibilityLookups: VisibilityLookup[];
  isRestored: boolean;

  constructor(visibilityLookups: VisibilityLookup[], isRestored: boolean) {
    this.visibilityLookups = visibilityLookups;
    this.isRestored = isRestored;
  }
}
