export class BaseParams {
  AuthToken: string;
  LanguageID: number;

  constructor(authToken?: string, languageId?: number) {
    this.AuthToken = authToken;
    this.LanguageID = languageId;
  }
}

export class BaseParamsWithID extends BaseParams {
  ID: number;

  constructor(id?: number, authToken?: string, languageId?: number) {
    super(authToken, languageId);
    this.ID = id;
  }
}

export class GenericBaseParams<T> {
  AuthToken: string;
  LanguageID: number;
  Data: T = {} as T;

  constructor(authToken?: string, languageId?: number) {
    this.AuthToken = authToken;
    this.LanguageID = languageId;
  }
}

export class BaseListParams extends BaseParams {
  Position: number;
  Take: number;
  ForceAll: number;
  Sorting: Sorting = new Sorting();
}

export class GenericBaseListParams<T> extends BaseListParams {
  Filter: T = {} as T;
}

export class BaseDeleteParams extends BaseParams {
  ID: number;
}

export class RequestFilter {
  AppID?: number;
  Status: number;
  AppItemID: number;
  FlagIsSystem?: number;
  Keyword: string;

  constructor(appId?: number, status?: number, appItemId?: number, flagIsSystem?: number, keyword?: string) {
    this.AppID = appId;
    this.Status = status;
    this.AppItemID = appItemId;
    this.Keyword = keyword;
    this.FlagIsSystem = flagIsSystem;
  }
}

export class Sorting {
  Key: string;
  Type: string;
}

export enum SortingType {
  ASC = "ASC",
  DESC = "DESC"
}

export const SORTING_ID_DESC: Sorting = {
  Key: "ID",
  Type: SortingType.DESC
};

export const SORTING_MODIFIED_DESC: Sorting = {
  Key: "ModifiedDate",
  Type: SortingType.DESC
};

export const SORTING_POSITION_ASC: Sorting = {
  Key: "Position",
  Type: SortingType.ASC
};
