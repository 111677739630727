import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UserLoginActionType } from "src/app/core/enums/response-status.enum";
import { AuthService } from "src/app/core/services/auth.service";
import { SessionStorageService } from "src/app/core/services/session-storage.service";
import { LoginActions } from "../login-actions";

@Component({
  templateUrl: "./action-consent-terms.component.html"
})
export class ActionConsentTermsComponent extends LoginActions implements OnInit {

  title: string = "";
  description: string = "";
  content: string = "";

  constructor(
    authService: AuthService,
    sessionStorage: SessionStorageService,
    router: Router
  ) {
    super(router, sessionStorage, authService, UserLoginActionType.ConsentRequired);
  }

  ngOnInit() {
    this.title = this.actionInProgress.ActionTitle;
    this.description = this.actionInProgress.ActionDescription;
    this.content = this.actionInProgress.ActionContent;
  }

  accepted() {
    this.removeActionFromStorage();
    this.goToDashboard();
  }
}
