import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
import * as HttpStatus from "http-status-codes";
import { Observable } from "rxjs";

import { routerPaths } from "../consts/router-paths";
import { ResponseStatus } from "../enums/response-status.enum";
import { LoginResponse } from "../models/login.model";
import { SessionStorageService } from "./session-storage.service";
import { map } from "rxjs/internal/operators/map";
import { GeneralResponse } from "../models/base-responses.model";

@Injectable()
export class HttpService {

  constructor(
    private http: HttpClient,
    private sessionStorageService: SessionStorageService,
    private injector: Injector
  ) { }

  get(url: string): Observable<any> {
    return this.get_internal(url);
  }

  getWithCORS(url: string): Observable<any> {
    return this.get_cors(url);
  }

  post(url: string, body?: any): Observable<any> {
    return this.post_internal(url, body);
  }

  post_authorized(url: string, body?: any): Observable<any> {
    return this.post_internal(url, body).pipe(map(result => {
      if ((<LoginResponse>result).Status == ResponseStatus.NoUser) {
        this.sessionStorageService.removeAll();
        window.location.reload();
        return null;
      }
      return result;
    }));
  }

  // this method would be called from dev-studio
  // TODO: this doesn't work, need another solution
  handleInternalErrorByStatus = (status: ResponseStatus) => {
    switch (status) {
      case ResponseStatus.NoUser:
        const router = this.injector.get(Router);
        router.navigate([routerPaths.Login]);
    }
  }

  private get_internal(url: string): Observable<Object> {
    return new Observable(observer => {
      this.http.get(url, {
        withCredentials: true
      }).subscribe(
        response => {
          observer.next(response);
          observer.complete();
        }, error => {
          this.httpResponseStatusErrorHandling(error, observer);
        });
    });
  }

  private get_cors(url: string): Observable<Object> {
    return new Observable(observer => {
      this.http.get(url).subscribe(
        response => {
          observer.next(response);
          observer.complete();
        }, error => {
          this.httpResponseStatusErrorHandling(error, observer);
        });
    });
  }

  private post_internal(url: string, body: any): Observable<any> {
    const headers = new HttpHeaders();
    body = !body ? {} : body;

    return new Observable(observer => {
      this.http.post(url, body, {
        headers: headers,
        withCredentials: true
      }).subscribe(
        response => {
          observer.next(response);
          observer.complete();
        }, error => {
          this.httpResponseStatusErrorHandling(error, observer);
        });
    });
  }

  private httpResponseStatusErrorHandling(error: any, observer: any) {
    if (error instanceof HttpErrorResponse) {
      const httpErrorResponse: HttpErrorResponse = <HttpErrorResponse>error;
      if (httpErrorResponse.status == HttpStatus.INTERNAL_SERVER_ERROR) {
        const router = this.injector.get(Router);
        router.navigate([routerPaths.InternalServerError]);
      }
    }
    observer.error(error);
  }
}
