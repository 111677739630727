import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { AlphaTechServerErrorService } from "../../../components/error/alphatech-server-error.service";
import { DEFAULT_PAGE_SIZE, FIRST_PAGE_NUMBER } from "../../../core/consts/request-defaults";
import { ResponseStatus } from "../../../core/enums/response-status.enum";
import { BaseParamsWithID, SORTING_ID_DESC } from "../../../core/models/base-requests.model";
import { SessionStorageService } from "../../../core/services/session-storage.service";
import { AppPresenter } from "../../../modules/my-apps/presenters/app.presenter";
import { AlphaTechResponseData, AlphaTechResponseDataList } from "../../../shared/model/alphatech-response-data.model";
import { AlphaTechServerMessage } from "../../../shared/model/alphatech-server-message.model";
import { ForceAllType } from "../../../shared/model/system-lookup.model";
import { AlphaTechSubscribeUtils } from "../../../shared/utils/alpha-tech-subscribe.utils";
import { ErrorUtil } from "../../../shared/utils/error.util";
import { AppInfo, AppData } from "../../my-apps/model/my-apps.model";
import {
  PackageAddParams, PackageAppAssignmentAddParams, PackageAppAssignmentData, PackageData, PackageDetailsData, PackageDetailsGetListParams, PackageGetListParams,
  PackageSubscriptionAddParams, PackageSubscriptionCancelParams, PackageSubscriptionData, PackageSubscriptionGetListParams, PackageSubscriptionListSummary,
  PackageUserInvoiceData, PackageUserInvoiceGetListParams, PackageUserInvoiceLineData, PackageUserInvoiceLineGetListParams, PackageUserInvoicePaymentData,
  PackageUserInvoicePaymentGetListParams
} from "../models/package.model";
import { UserBillingData, UserBillingDeleteParams, UserBillingGetParams, UserBillingUpdateParams } from "../models/user-billing.model";
import { BillingService } from "../services/billing-service";

@Injectable()
export class BillingPresenter {

  constructor(
    private billingService: BillingService,
    private appPresenter: AppPresenter,
    private sessionStorageService: SessionStorageService,
    private alphaTechServerErrorService: AlphaTechServerErrorService
  ) { }

  // -------------------- APP --------------------

  downgradeApp(appInfo: AppInfo): Observable<AlphaTechResponseData<AppData>> {
    appInfo.isPremium = false;

    return this.appPresenter.updateApp(appInfo);
  }

  // -------------------- PACKAGE --------------------

  getPackageList(
    pageNumber: number,
    isPurchase?: boolean,
    flagBusiness?: number,
    takeOne?: boolean,
    deletedItemsCount: number = 0
  ): Observable<AlphaTechResponseDataList<PackageData>> {
    const params: PackageGetListParams = new PackageGetListParams();
    params.Filter.FlagBusiness = flagBusiness ? flagBusiness : 0;
    params.Filter.FlagOnlyPremium = 1;
    params.Filter.FlagOnlyParentOrganization = isPurchase ? 1 : 0;
	params.Filter.Status = 1;
    params.Sorting = SORTING_ID_DESC;
    params.Take = takeOne ? 1 : DEFAULT_PAGE_SIZE;
    params.Position = pageNumber * DEFAULT_PAGE_SIZE - deletedItemsCount + (takeOne ? 15 : 0);

    return AlphaTechSubscribeUtils.subscribeDataListResponseHandling<PackageData>(
      this.billingService.getPackageList(params)
    );
  }

  getPackage(packageId: number): Observable<AlphaTechResponseData<PackageData>> {
    const params: BaseParamsWithID = new BaseParamsWithID();
    params.ID = packageId;

    return AlphaTechSubscribeUtils.subscribeDataResponseHandling<PackageData>(
      this.billingService.getPackage(params)
    );
  }

  // --------------- Table ---------------

  getPackagesTableData(): Observable<AlphaTechResponseDataList<PackageData>> {
    const params: PackageGetListParams = new PackageGetListParams();
    params.Sorting = SORTING_ID_DESC;
    params.Take = DEFAULT_PAGE_SIZE;
    params.Position = FIRST_PAGE_NUMBER * DEFAULT_PAGE_SIZE;

    return AlphaTechSubscribeUtils.subscribeDataListResponseHandling(
      this.billingService.getPackageList(params)
    );
  }

  // --------------- Add/Update Package ---------------

  savePackage(packageData: PackageData, isEdit: boolean): Observable<AlphaTechResponseData<PackageData>> {
    const params: PackageAddParams = new PackageAddParams();
    params.Data = packageData;
    params.Data.OrganizationID = this.sessionStorageService.getOrganizationId();

    return AlphaTechSubscribeUtils.subscribeDataResponseHandling<PackageData>(
      isEdit
        ? this.billingService.updatePackage(params)
        : this.billingService.addPackage(params)
    );
  }

  clonePackage(packageId: number): Observable<AlphaTechResponseData<PackageData>> {
    const params: PackageAddParams = new PackageAddParams();
    params.CloneID = packageId;

    return AlphaTechSubscribeUtils.subscribeDataResponseHandling<PackageData>(
      this.billingService.addPackage(params)
    );
  }

  deletePackage(packageId: number): Observable<AlphaTechServerMessage> {
    const params: BaseParamsWithID = new BaseParamsWithID();
    params.ID = packageId;

    return AlphaTechSubscribeUtils.subscribeResponseHandling(this.billingService.deletePackage(params));
  }

  // -------------------- PACKAGE SUBSCRIPTION --------------------

  getPackageSubscriptionList(onlyActive?: boolean): Observable<AlphaTechResponseDataList<PackageSubscriptionData>> {
    const params: PackageSubscriptionGetListParams = new PackageSubscriptionGetListParams();
    params.ForceAll = ForceAllType.Activated;
    params.Filter.FlagOnlyPremium = 1;

    if (onlyActive) {
      params.Filter.Status = 1;
      params.Filter.FlagOnlyPremiumAvailable = 1;
    }

    return AlphaTechSubscribeUtils.subscribeDataListResponseHandling<PackageSubscriptionData>(
      this.billingService.getPackageSubscriptionList(params)
    );
  }

  // TODO: find a way to include the summary along-side the data in an abstraction
  getPackageSubscriptionListSummary(onlyActive?: boolean): Observable<PackageSubscriptionListSummary> {
    const params: PackageSubscriptionGetListParams = new PackageSubscriptionGetListParams();
    params.ForceAll = ForceAllType.Activated;
    params.Filter.FlagOnlyPremium = 1;

    if (onlyActive) {
      params.Filter.Status = 1;
      params.Filter.FlagOnlyPremiumAvailable = 1;
    }

    return new Observable(observer => {
      this.billingService.getPackageSubscriptionList(params).subscribe(response => {
        if (response.Status == ResponseStatus.Success) {
          observer.next(new PackageSubscriptionListSummary(response));
          observer.complete();
        } else {
          this.alphaTechServerErrorService.setUserMessageAndStatus(new AlphaTechServerMessage(response));
          observer.error(ErrorUtil.buildError(response.Status));
        }
      });
    });
  }

  addPackageSubscription(packageId: number): Observable<AlphaTechResponseData<PackageSubscriptionData>> {
    const params: PackageSubscriptionAddParams = new PackageSubscriptionAddParams();
    params.Data.PackageID = packageId;

    return AlphaTechSubscribeUtils.subscribeDataResponseHandling<PackageSubscriptionData>(
      this.billingService.addPackageSubscription(params)
    );
  }

  cancelPackageSubscription(packageSubscriptionId: number): Observable<AlphaTechResponseData<PackageSubscriptionData>> {
    const params: PackageSubscriptionCancelParams = new PackageSubscriptionCancelParams();
    params.ID = packageSubscriptionId;

    return AlphaTechSubscribeUtils.subscribeDataResponseHandling<PackageSubscriptionData>(
      this.billingService.cancelPackageSubscription(params)
    );
  }

  // -------------------- PACKAGE <=> APP --------------------

  addPackageAppAssignment(appId: number, packageSubscriptionID: number): Observable<AlphaTechResponseData<PackageAppAssignmentData>> {
    const params: PackageAppAssignmentAddParams = new PackageAppAssignmentAddParams();
    params.Data.AppID = appId;
    params.Data.PackageSubscriptionID = packageSubscriptionID;

    return AlphaTechSubscribeUtils.subscribeDataResponseHandling<PackageAppAssignmentData>(
      this.billingService.addPackageAppAssignment(params)
    );
  }

  // -------------------- PACKAGE DETAILS --------------------

  getPackageDetailsList(packageId: number): Observable<AlphaTechResponseDataList<PackageDetailsData>> {
    const params: PackageDetailsGetListParams = new PackageDetailsGetListParams();
    params.Filter.PackageID = packageId;

    return AlphaTechSubscribeUtils.subscribeDataListResponseHandling<PackageDetailsData>(
      this.billingService.getPackageDetailsList(params)
    );
  }

  getPackageDetails(packageId: number): Observable<AlphaTechResponseData<PackageDetailsData>> {
    const params: BaseParamsWithID = new BaseParamsWithID();
    params.ID = packageId;

    return AlphaTechSubscribeUtils.subscribeDataResponseHandling<PackageDetailsData>(
      this.billingService.getPackageDetails(params)
    );
  }

  // -------------------- USER BILLING --------------------

  getUserBilling(): Observable<AlphaTechResponseData<UserBillingData>> {
    const params: UserBillingGetParams = new UserBillingGetParams();

    return AlphaTechSubscribeUtils.subscribeDataResponseHandling<UserBillingData>(
      this.billingService.getUserBilling(params)
    );
  }

  updateUserBilling(userBillingData: UserBillingData): Observable<AlphaTechResponseData<UserBillingData>> {
    const params: UserBillingUpdateParams = new UserBillingUpdateParams();
    params.Data = userBillingData;

    return AlphaTechSubscribeUtils.subscribeDataResponseHandling<UserBillingData>(
      this.billingService.updateUserBilling(params)
    );
  }

  deleteUserBilling(id: number): Observable<AlphaTechServerMessage> {
    const params: UserBillingDeleteParams = new UserBillingDeleteParams();
    params.ID = id;

    return AlphaTechSubscribeUtils.subscribeResponseHandling(
      this.billingService.deleteUserBilling(params)
    );
  }

  // -------------------- BILLING HISTORY --------------------

  getPackageUserInvoiceList(userId: number): Observable<AlphaTechResponseDataList<PackageUserInvoiceData>> {
    const params: PackageUserInvoiceGetListParams = new PackageUserInvoiceGetListParams();
    params.Filter.UserID = userId;

    return AlphaTechSubscribeUtils.subscribeDataListResponseHandling<PackageUserInvoiceData>(
      this.billingService.getPackageUserInvoiceList(params)
    );
  }

  getPackageUserInvoicePaymentList(userId: number): Observable<AlphaTechResponseDataList<PackageUserInvoicePaymentData>> {
    const params: PackageUserInvoicePaymentGetListParams = new PackageUserInvoicePaymentGetListParams();
    params.Filter.UserID = userId;

    return AlphaTechSubscribeUtils.subscribeDataListResponseHandling<PackageUserInvoicePaymentData>(
      this.billingService.getPackageUserInvoicePaymentList(params)
    );
  }

  getPackageUserInvoiceLineList(packageUserInvoiceID: number): Observable<AlphaTechResponseDataList<PackageUserInvoiceLineData>> {
    const params: PackageUserInvoiceLineGetListParams = new PackageUserInvoiceLineGetListParams();
    params.Filter.PackageUserInvoiceID = packageUserInvoiceID;

    return AlphaTechSubscribeUtils.subscribeDataListResponseHandling<PackageUserInvoiceLineData>(
      this.billingService.getPackageUserInvoiceLineList(params)
    );
  }

}
