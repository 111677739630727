import { Component, Input } from "@angular/core";

@Component({
  selector: "custom-validation-message",
  templateUrl: "./custom-validation-message.component.html"
})
export class CustomValidationMessageComponent {
  @Input() isEnabled: boolean;
  @Input() messageKey: string;

  constructor() { }
}
