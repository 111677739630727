import { Component, OnInit, Output, EventEmitter, OnDestroy, Input } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { debounceTime } from "rxjs/operators";
import { Subscription } from "rxjs";
import { SubscriptionUtil } from "src/app/shared/utils/subscription.utils";
@Component({
  selector: "search-input",
  templateUrl: "./search-input.component.html"
})
export class SearchInputComponent implements OnInit, OnDestroy {
  @Input() placeholderText: string;
  @Input() searchValue;
  @Input() isMinLengthEventEmitDisabled = false;
  @Input() isDebounceDisabled: boolean = false;

  @Output() onchange: EventEmitter<string> = new EventEmitter();

  // Form
  readonly SEARCH = "search";

  searchForm: FormGroup;
  subscriptions: Subscription[] = [];

  constructor() { }

  ngOnInit() {
    this.initForm();
    this.onSearchListener();
  }

  onSearchListener() {
    this.subscriptions.push(
      this.searchForm.get(this.SEARCH).valueChanges.pipe(
        debounceTime(this.isDebounceDisabled ? 0 : 700)
      ).subscribe(searchText => {
        searchText = searchText.trim();
        if (!this.isMinLengthEventEmitDisabled) {
          if (searchText.length == 0 || searchText.length >= 3) {
            this.onchange.emit(searchText);
          }
        } else {
          this.onchange.emit(searchText);
        }
      })
    );
  }

  ngOnDestroy(): void {
    SubscriptionUtil.unsubscribe(this.subscriptions);
  }

  initForm() {
    const formControls: LooseObject = {};
    formControls[this.SEARCH] = new FormControl("");
    this.searchForm = new FormGroup(formControls);
  }
}
