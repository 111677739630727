import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";
import { environment } from "src/environments/environment";

import { TranslateService } from "../../node_modules/@ngx-translate/core";
import { languageDefaults } from "./core/consts/language-defaults";
import { AuthService } from "./core/services/auth.service";
import { SessionStorageService } from "./core/services/session-storage.service";
import { SubscriptionUtil } from "./shared/utils/subscription.utils";
import { Subscription } from "rxjs";
import { routerPaths } from "./core/consts/router-paths";
import { SideNavService } from "./modules/my-apps/components/side-nav/services/side-nav.service";
import { SessionChangeService } from "./shared/services/session-change.service";

declare var gtag;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html"
})
export class AppComponent implements OnInit, OnDestroy {
  isLoggedIn: boolean;
  currentYear: number = (new Date()).getFullYear();
  routerPaths = routerPaths;

  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private translate: TranslateService,
    private authService: AuthService,
    private sessionStorageService: SessionStorageService,
    private sessionChangeService: SessionChangeService,
    public sideNavService: SideNavService
  ) {
    this.isLoggedIn = this.sessionStorageService.isAuthenticated();
    this.initTranslations();
    this.initOrgTheme();
    this.appendScript();
    this.listenRouterChangesForAnalyticsTracking();
  }

  ngOnInit() {
    this.translate.use(this.sessionStorageService.getLanguageCode());
    this.listenLanguageChange();
    this.subscriptions.push(
      this.authService.loggedIn.subscribe(loggedIn => {
        if (loggedIn && !loggedIn.isFromInitialization) {
          this.isLoggedIn = loggedIn.isAuth;

          const user = this.sessionStorageService.getUserInfo();
          const organizationInfo = this.sessionStorageService.getOrganizationInfo();
          if (user && user.LanguageID && organizationInfo.AvailableLanguages && organizationInfo.AvailableLanguages.find(item => item.ID == user.LanguageID)) {
            this.translate.use(organizationInfo.AvailableLanguages.find(item => item.ID == user.LanguageID).Code);
          }
        }
      })
    );
  }
  listenLanguageChange() {
    this.sessionChangeService.getLanguageSubject().subscribe(response => {
      this.translate.use(response);
    });

  }

  ngOnDestroy(): void {
    SubscriptionUtil.unsubscribe(this.subscriptions);
  }

  onActivate(event) {
    document.getElementById("app-wrapper").scrollTop = 0;
  }

  private initTranslations() {
    const organizationInfo = this.sessionStorageService.getOrganizationInfo();
    this.translate.langs = [];
    if (organizationInfo && organizationInfo.AvailableLanguages) {
      const languages: string[] = [];
      organizationInfo.AvailableLanguages.forEach(item => {
        languages.push(item.Code);
      });
      this.translate.addLangs(languages);
    } else {
      this.translate.addLangs(languageDefaults.supportedLanguageCodes);
    }

    if (organizationInfo && organizationInfo.DefaultLanguageID && organizationInfo.AvailableLanguages
      && organizationInfo.AvailableLanguages.find(item => item.ID == organizationInfo.DefaultLanguageID)) {
      this.translate.setDefaultLang(organizationInfo.AvailableLanguages.find(item => item.ID == organizationInfo.DefaultLanguageID).Code);
    } else {
      this.translate.setDefaultLang(languageDefaults.defaultLanguageCode);
    }

    const user = this.sessionStorageService.getUserInfo();
    if (user && user.LanguageID && organizationInfo && organizationInfo.AvailableLanguages
      && organizationInfo.AvailableLanguages.find(item => item.ID == user.LanguageID)) {
      this.translate.use(organizationInfo.AvailableLanguages.find(item => item.ID == user.LanguageID).Code);
    }
  }

  private initOrgTheme() {
    const organizationInfo = this.sessionStorageService.getOrganizationInfo();
    if (organizationInfo && organizationInfo.Theme) {
      organizationInfo.Theme.forEach(element => {
        if (element.ThemeItemName && element.ThemeItemValue) {
          document.documentElement.style.setProperty("--" + element.ThemeItemName, element.ThemeItemValue);
        }
      });
    }
  }

  private appendScript() {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=" + environment.Ga_Tracking_Id;
    document.head.prepend(script);
  }

  private listenRouterChangesForAnalyticsTracking() {
    const navEndEvent$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    );
    navEndEvent$.subscribe((event: NavigationEnd) => {
      gtag("config", environment.Ga_Tracking_Id, { "page_path": event.urlAfterRedirects });
    });
  }
}
