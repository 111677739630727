import { Component, Input, Output, EventEmitter } from "@angular/core";
import { SelectableTileData } from "./selectable-tile.model";

@Component({
  selector: "selectable-tile",
  templateUrl: "./selectable-tile.component.html"
})
export class SelectableTileComponent {
  @Input() data: SelectableTileData;
  @Output() onTileClick = new EventEmitter();

  onTileSelect(event) {
    // TODO: should be investigated how to prevent parent click
    // event.preventDefault();
    this.onTileClick.emit();
  }
}
