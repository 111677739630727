import { Component, Output, EventEmitter, Input, ElementRef, ViewChild, TemplateRef } from "@angular/core";
import { ImageUtils } from "src/app/shared/utils/image.util";
import { AlertType } from "../../../model/bootstrap-alerts.enum";
import { ItemToDelete } from "../../confirm-delete-modal/item-to-delete";
import { ModalUtils } from "../../../utils/modal.utils";
import { BsModalService, BsModalRef } from "ngx-bootstrap";

@Component({
  selector: "image-uploader",
  templateUrl: "./image-uploader.component.html"
})
export class ImageUploaderComponent {
  @Input() imageSource: string;
  @Input() isImageHeaderOn: boolean = true;
  @Input() isImageValid: boolean;
  @Input() subtitle: string;
  @Input() pageHeaderImageDescription: string;
  @Input() uploadButtonTitle: string;
  @Input() imageValidationText: string;

  @Output() onUploadImage: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<any> = new EventEmitter();

  @ViewChild("fileInput") input: ElementRef;

  readonly NO_IMAGE_PATH = "assets/images/no-image.png";

  itemToDelete: ItemToDelete<any>;
  deleteModalRef: BsModalRef;

  alertIcon = {
    type: AlertType.Danger
  };

  constructor(private modalService: BsModalService) { }

  uploadImage(event) {
    try {
      const fileType: string = event.target.files[0].name.split(".")[1].toLowerCase();
      const imgTypeFound = ImageUtils.availableImageFormats.find(type => type === fileType);

      if (imgTypeFound) {
        this.isImageValid = true;
        ImageUtils.getBase64Image(event.target.files[0], imageSource => {
          this.onUploadImage.emit(imageSource);
        }, this.isImageHeaderOn);
      } else {
        this.setInputAsInvalid();
      }
    } catch (error) {
      this.setInputAsInvalid();
    }
  }

  setInputAsInvalid() {
    this.isImageValid = false;
    this.input.nativeElement.value = null;
    this.imageSource = null;
  }

  openConfirmationModal(template: TemplateRef<any>) {
    this.itemToDelete = new ItemToDelete(0, "");
    this.deleteModalRef = ModalUtils.showModalIgnoringBackdropClick(this.modalService, template);
  }

  deleteImage() {
    this.input.nativeElement.value = null;
    this.imageSource = null;
    this.deleteModalRef.hide();
    this.onDelete.emit();
  }
}
