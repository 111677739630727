import { ResponseStatus } from "src/app/core/enums/response-status.enum";

export class ErrorUtil {
  static buildError(status: ResponseStatus, message?: string): Error {
    return message ?
      new Error("The response status is: " + status + " (" + ResponseStatus.getName(status) + ")")
     :
      new Error(message + ".The response status is: " + status + " (" + ResponseStatus.getName(status) + ")")
    ;
  }
}
