import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "phone"
})
export class PhonePipe implements PipeTransform {

  transform(tel: number): string {
    const value = tel ? tel.toString().trim().replace(/^\+/, "") : "";

    if (value.match(/[^0-9]/)) {
      return tel.toString();
    }

    let country;
    let city;
    let phoneNUmber;

    switch (value.length) {
      case 10:
        country = 1;
        city = value.slice(0, 3);
        phoneNUmber = value.slice(3);
        break;

      case 11:
        country = value[0];
        city = value.slice(1, 4);
        phoneNUmber = value.slice(4);
        break;

      case 12:
        country = value.slice(0, 3);
        city = value.slice(3, 5);
        phoneNUmber = value.slice(5);
        break;

      default:
        return tel ? tel.toString() : "";
    }

    if (country == 1) {
      country = "";
    }

    phoneNUmber = phoneNUmber.slice(0, 3) + "-" + phoneNUmber.slice(3);

    return (country + " (" + city + ") " + phoneNUmber).trim();
  }

}
