import { BaseComponent } from "./base-component";
import { FormGroup } from "@angular/forms";
import { FormValidationModel } from "../../validation/form-validation.model";

export abstract class BaseFormComponent extends BaseComponent {
  formGroup: FormGroup;
  validationModels: any = {};
  formValidation: FormValidationModel;

  abstract isFormValid(): boolean;
}
