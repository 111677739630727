import { GeneralResponse } from "src/app/core/models/base-responses.model";

export class AlphaTechServerMessage {

  public static isAlphaTechResponse(response: any): boolean {
    return response && response.hasOwnProperty("Status") && response.hasOwnProperty("UserMessage");
  }

  userMessage: string;
  status: number;

  constructor(
    response?: any
  ) {
    if (AlphaTechServerMessage.isAlphaTechResponse(response)) {
      this.userMessage = (<GeneralResponse>response).UserMessage;
      this.status = (<GeneralResponse>response).Status;
    }
  }

}
