import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { CustomValidators } from "ng2-validation/dist";

import { routerPaths } from "src/app/core/consts/router-paths";
import { ResponseStatus } from "src/app/core/enums/response-status.enum";
import { UserRegisterEmailResponse } from "src/app/core/models/user-register-email.model";
import { AuthService } from "src/app/core/services/auth.service";
import { SessionStorageService } from "src/app/core/services/session-storage.service";
import { FormValidationModel } from "src/app/shared/validation/form-validation.model";
import { InternalCustomValidators } from "src/app/shared/validation/internal-custom-validators.model";
import { ValidationModel } from "src/app/shared/validation/validation.model";
import { emailMaxLength, passwordMaxLength, Validation } from "../../core/consts/validation";
import { FormValidatorUtil } from "../../shared/utils/form-validator.util";
import { LoggerUtil } from "src/app/shared/utils/logger.util";
import { LoginResponse } from "src/app/core/models/login.model";
import { SessionRoutingParams } from "src/app/core/models/session-params.model";

@Component({
  templateUrl: "login.component.html"
})
export class LoginComponent implements OnInit {
  readonly EMAIL: string = "email";
  readonly PASSWORD: string = "password";

  routerPaths = routerPaths;
  responseStatusEnum = ResponseStatus;

  loginForm: FormGroup;
  validationModels: any = {};
  formValidation: FormValidationModel;
  logoUrl: string;
  userEmail: string;
  status: ResponseStatus;
  userMessage: string;
  isSaving: boolean;
  isRegisterEnabled: boolean;
  isPasswordVisible: boolean = false;
  isActivationLinkResent: boolean;
  emailByChangePassword: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    private sessionStorageService: SessionStorageService
  ) { }

  ngOnInit() {
    const organizationInfo = this.sessionStorageService.getOrganizationInfo();
    if (organizationInfo) {
      this.isRegisterEnabled = organizationInfo.FlagRegisterEnabled !== 0;
      this.logoUrl = organizationInfo.Logo;
    }

    this.initFormValidation();
  }

  login(event: any) {
    event.preventDefault();
    this.reInitLoginScreen();

    if (this.loginForm.valid) {
      this.userEmail = this.loginForm.controls[this.EMAIL].value;
      const pass = this.loginForm.controls[this.PASSWORD].value;

      this.isSaving = true;
      this.authService.login(this.userEmail, pass).subscribe((loginResponse: LoginResponse) => {
        this.isSaving = false;
        this.status = loginResponse.Status;
        this.userMessage = loginResponse.UserMessage;

        if (loginResponse.Status == ResponseStatus.Success) {
          this.onLoginSuccess();
        }
      }, (error: any) => {
        this.isSaving = false;
        this.status = ResponseStatus.Exception;
        LoggerUtil.log(this.constructor.name, "login", error);
      });
    } else {
      FormValidatorUtil.validateFormElements(this.loginForm);
    }
  }

  changePasswordVisibility(isVisible: boolean) {
    this.isPasswordVisible = isVisible;
  }

  resendActivation(event) {
    event.preventDefault();
    this.authService.sendRegisterEmail(this.userEmail).subscribe((response: UserRegisterEmailResponse) => {
      if (response.Status == ResponseStatus.Success) {
        this.isActivationLinkResent = true;
      }
    });
  }

  reInitLoginScreen() {
    this.isActivationLinkResent = false;
    this.status = ResponseStatus.Default;
    this.userMessage = "";
    this.emailByChangePassword = "";
  }

  /**
   * If the navigation happened from change-password page, then the email is stored. Will read it and empty it in storage
   */
  private initEmailByChangePassword() {
    const routingParams: SessionRoutingParams = this.sessionStorageService.getRoutingParams();
    if (routingParams && routingParams.changePasswordEmail) {
      this.emailByChangePassword = routingParams.changePasswordEmail;
      routingParams.changePasswordEmail = undefined;
      this.sessionStorageService.setRoutingParams(routingParams);
    }
  }

  private initFormValidation() {
    this.initEmailByChangePassword();

    const baseValidations = [Validators.required, InternalCustomValidators.noWhitespace];
    const formControls: LooseObject = {};
    formControls[this.EMAIL] = new FormControl(this.emailByChangePassword, [...baseValidations, CustomValidators.email, Validators.maxLength(emailMaxLength)]);
    formControls[this.PASSWORD] = new FormControl("", [...baseValidations, Validators.maxLength(passwordMaxLength)]);
    this.loginForm = new FormGroup(formControls);

    // email
    this.validationModels.emailInvalid = new ValidationModel(this.loginForm, this.EMAIL, Validation.Email, "Validation.InvalidEmail");
    this.validationModels.emailMaxLength = new ValidationModel(this.loginForm, this.EMAIL, Validation.MaxLength, "Validation.MaxLength", emailMaxLength);

    // password
    this.validationModels.passwordRequired = new ValidationModel(this.loginForm, this.PASSWORD, Validation.Required, "Validation.Required");
    this.validationModels.passwordMaxLength =
      new ValidationModel(this.loginForm, this.PASSWORD, Validation.MaxLength, "Validation.MaxLength", passwordMaxLength);

    this.formValidation = new FormValidationModel(this.loginForm);
  }

  private onLoginSuccess() {
    this.router.navigate([routerPaths.Dashboard]).then(() => {
      this.authService.loggedIn.next({ isAuth: true, isFromInitialization: false });
    });
  }
}
