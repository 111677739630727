export class CloneUtil {

  /**
   * This util makes new clone from your object not keeping the type of it.
   *
   * @typeparam T object to clone.
   */
  public static getClonedInstance<T>(object: T): T {
    return object ? JSON.parse(JSON.stringify(object)) : undefined;
  }

  /**
   * This util makes new clone from your object keeping the type of it.
   * Use this method when your object to clone contains other objects.
   *
   * @typeparam T object to clone.
   */
  public static deepCopyObject<T>(object: T): T {
    let copy: any;

    // In case of primitive types, and null or undefined return back the original object
    if (object == null || object == undefined || typeof object != "object") {
      return object;
    }

    // Handle Date
    if (object instanceof Date) {
      copy = new Date();
      copy.setTime(object.getTime());
      return copy;
    }

    // Handle Array
    if (object instanceof Array) {
      copy = [];
      for (let i = 0, len = object.length; i < len; i++) {
        copy[i] = this.deepCopyObject(object[i]);
      }
      return copy;
    }

    // Handle Object
    if (object instanceof Object) {
      copy = {} as T;
      for (const attr in object) {
        if (object.hasOwnProperty(attr)) {
          copy[attr] = this.deepCopyObject(object[attr]);
        }
      }
      return copy;
    }

    throw new Error("Unable to copy the obj! Its type isn't supported.");
  }
}
