import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { UserGetListParams, UserGetListResponse, UserGetParams as UserGetParams, UserGetResponse, UserLanguageUpdateParams, UserUpdateParams } from "../model/user.model";
import { EndpointsService } from "src/app/core/services/endpoint.service";
import { HttpService } from "src/app/core/services/http.service";

@Injectable({
  providedIn: "root"
})
export class UserService extends EndpointsService {

  private readonly POST_USER_GET: string = `${this.API_URL}/UserGet`;
  private readonly POST_USER_GET_LIST: string = `${this.API_URL}/UserGetList`;
  private readonly POST_USER_UPDATE: string = `${this.API_URL}/UserUpdate`;
  private readonly POST_USER_LANGUAGE_UPDATE: string = `${this.API_URL}/UserLanguageUpdate`;
  private readonly POST_USER_EMAIL_UPDATE: string = `${this.API_URL}/UserEmailUpdate`;

  constructor(private httpService: HttpService) {
    super();
  }

  getUser(params: UserGetParams): Observable<UserGetResponse> {
    return this.httpService.post_authorized(this.POST_USER_GET, params);
  }

  getUsers(params: UserGetListParams): Observable<UserGetListResponse> {
    return this.httpService.post_authorized(this.POST_USER_GET_LIST, params);
  }

  updateUser(params: UserUpdateParams): Observable<UserGetResponse> {
    return this.httpService.post_authorized(this.POST_USER_UPDATE, params);
  }

  updateUserLanguage(params: UserLanguageUpdateParams): Observable<UserGetResponse> {
    return this.httpService.post_authorized(this.POST_USER_LANGUAGE_UPDATE, params);
  }

  updateUserEmail(params: UserLanguageUpdateParams): Observable<UserGetResponse> {
    return this.httpService.post_authorized(this.POST_USER_EMAIL_UPDATE, params);
  }
}
