import { Injectable } from "@angular/core";

import { VisibilityLookup } from "../../../shared/model/lookup/visibility-lookup.model";
import { BehaviorSubject } from "rxjs";
import { VisibilityLookupChange } from "src/app/shared/model/lookup/lookup-visibility-change";

@Injectable({
  providedIn: "root"
})
export class VisibilityLookupChangeService {
  private readonly visibilityLookupChangeSubject = new BehaviorSubject<VisibilityLookupChange>(new VisibilityLookupChange([], false));

  constructor() { }

  notifyAll(visibilityLookups: VisibilityLookup[]) {
    this.visibilityLookupChangeSubject.next(new VisibilityLookupChange(visibilityLookups, true));
  }

  getSubject(): BehaviorSubject<VisibilityLookupChange> {
    return this.visibilityLookupChangeSubject;
  }

}
