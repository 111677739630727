import { GenericBaseListParams } from "../../core/models/base-requests.model";
import { DateData, GeneralResponse, GenericGeneralListResponse, LanguageData } from "../../core/models/base-responses.model";

// ---------------------- Get List ---------------------------

export class MetadataGetListParams extends GenericBaseListParams<MetadataItemFilter> { }

export class MetadataGetListResponse extends GenericGeneralListResponse<MetadataData> { }

// ---------------------- Get ---------------------------

export class MetadataGetLastUpdateResponse extends GeneralResponse {
  LastUpdated: DateData;
}

// ---------------------- Related ---------------------------

export class MetadataData {
  CreatedByName: string;
  CreatedDate: DateData;
  ID: number;
  ModifiedByName: string;
  ModifiedDate: DateData;
  Code: string;
  Status: number;
  Language: LanguageData;
  Languages: LanguageData[];
  Category: string;
  FQFieldName: string;
  FieldName: string;
  Module: string;
  OrganizationID: number;
}

export class MetadataItemFilter {
  Status: number;
  Category: string;
  Module: string;
  FlagReturnIdOnly: number;
}

export class LastMetadataSet {
  lastUpdated: DateData = new DateData();
  metadataDataList: MetadataData[] = [];
}
