import { Component, Input } from "@angular/core";
import { IconFontFamilyEnum } from "../../model/tile-base.model";
import { IconMeta } from "./icon.model";

@Component({
  selector: "icon",
  templateUrl: "./icon.component.html"
})
export class IconComponent {
  @Input() iconMeta: IconMeta;

  iconFontFamilyEnum = IconFontFamilyEnum;

  constructor() { }

}
