import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";

import { ToastrHelper } from "../../helpers/toaster.helper";
import { ItemAction } from "../../enums/item-action.enum";
import { SessionStorageService } from "src/app/core/services/session-storage.service";
import { SessionChangeService } from "../../services/session-change.service";

@Component({
  selector: "actions-context-menu",
  templateUrl: "./actions-context-menu.component.html",
  styleUrls: ["actions-context-menu.component.scss"]
})
export class ActionsContextMenuComponent implements OnInit {
  @Input() isDeleteDisabled: boolean = false;
  @Input() isEditHidden: boolean = false;
  @Input() isViewVisible: boolean = false;
  @Input() isCloneHidden: boolean = false;
  @Input() isDowngradeHidden: boolean = true;
  @Input() isActionInProgress: boolean;
  @Input() displaySmall: boolean = false;
  @Input() isOnWhiteBackground: boolean = false;
  @Output() onContextMenuChange: EventEmitter<ItemAction> = new EventEmitter();

  contextMenuData = ItemAction;
  isDropUp: boolean;

  toastrHelper: ToastrHelper;
  constructor(
    private sessionStorageService: SessionStorageService,
    private sessionChangeService: SessionChangeService,
    toastrService: ToastrService,
    public translateService: TranslateService
  ) {
    this.toastrHelper = new ToastrHelper(toastrService, translateService);
  }
  ngOnInit() {
    this.translateService.use(this.sessionStorageService.getLanguageCode());
    this.listenLanguageChange();
  }

  getMenuHeight() {
    if (this.displaySmall) {
      return 175;
    } else { return 100; }
  }

  toggleDropUp(dropdown) {
    const position: number = dropdown.getBoundingClientRect().top;
    const buttonHeight: number = dropdown.getBoundingClientRect().height;
    const menuHeight = this.getMenuHeight();

    if (position > menuHeight && window.innerHeight - position < buttonHeight + menuHeight) {
      this.isDropUp = true;
    } else {
      this.isDropUp = false;
    }
  }

  listenLanguageChange() {
    this.sessionChangeService.getLanguageSubject().subscribe(response => {
      this.translateService.use(response);
    });
  }


  contextMenuChanged(action: ItemAction) {
    if (action == ItemAction.Delete && this.isDeleteDisabled) {
      this.toastrHelper.toastDeleteDisabled();
    } else {
      this.onContextMenuChange.emit(action);
    }
  }
}
