import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

const PLACEHOLDER_IMAGE = "https://via.placeholder.com/150x150";
@Pipe({
  name: "safe"
})
export class SafePipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer) { }

  public transform(value: string): SafeHtml {
    if (value) {
      if (value.substring(0, 4) == "http") {
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      } else if (value.startsWith("data:image")) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      } else {
        return this.sanitizer.bypassSecurityTrustResourceUrl("data:image/png;base64," + value);
      }
    } else {
      return PLACEHOLDER_IMAGE;
    }
  }
}
