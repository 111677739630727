import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { SessionStorageService } from "../services/session-storage.service";

@Injectable()
export class RegisterEnabledGuard implements CanActivate {

  constructor(private sessionStorage: SessionStorageService) { }

  canActivate() {
    return this.sessionStorage.getOrganizationInfo().FlagRegisterEnabled !== 0;
  }
}
