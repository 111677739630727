import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { UserGetListParams, UserGetParams, UserGetResponse, UserData, UserUpdateParams, UserLanguageUpdateParams } from "../model/user.model";
import { UserService } from "../service/user.service";
import { AlphaTechSubscribeUtils } from "src/app/shared/utils/alpha-tech-subscribe.utils";
import { SORTING_ID_DESC } from "src/app/core/models/base-requests.model";
import { AppInfo } from "../../my-apps/model/my-apps.model";
import { SessionStorageService } from "src/app/core/services/session-storage.service";
import { AlphaTechResponseData, AlphaTechResponseDataList } from "src/app/shared/model/alphatech-response-data.model";
import { ChangePasswordService } from "src/app/components/change-password/service/change-password.service";
import { PasswordUpdateModel } from "src/app/components/change-password/change-password.model";
import { GeneralResponse, LanguageData } from "src/app/core/models/base-responses.model";
import { UserResetPasswordParams } from "src/app/core/models/user-reset-password.model";
import { AlphaTechServerMessage } from "src/app/shared/model/alphatech-server-message.model";

@Injectable()
export class UserPresenter {
  USER_PAGE_SIZE: number = 15;

  constructor(
    private sessionStorageService: SessionStorageService,
    private passwordService: ChangePasswordService,
    private userService: UserService
  ) { }

  getUser(userId: number): Observable<AlphaTechResponseData<UserData>> {
    const params: UserGetParams = new UserGetParams();
    params.ID = userId;

    return AlphaTechSubscribeUtils.subscribeDataResponseHandling(
      this.userService.getUser(params)
    );
  }

  updateUser(params: UserUpdateParams): Observable<AlphaTechResponseData<UserData>> {
    return AlphaTechSubscribeUtils.subscribeDataResponseHandling(
      this.userService.updateUser(params)
    );
  }

  updateUserEmail(params: UserLanguageUpdateParams): Observable<AlphaTechResponseData<UserData>> {
    return AlphaTechSubscribeUtils.subscribeDataResponseHandling(
      this.userService.updateUserEmail(params)
    );
  }

  getAllUsers(): Observable<AlphaTechResponseDataList<UserData>> {
    const params: UserGetListParams = new UserGetListParams();
    params.ForceAll = 1;
    params.Filter.OrganizationID = this.sessionStorageService.getOrganizationInfo().ID;
	params.Filter.FlagIgnoreDetails = 1;

    return AlphaTechSubscribeUtils.subscribeDataListResponseHandling<UserData>(this.userService.getUsers(params));
  }

  getUsers(pageNumber: number, searchText?: string): Observable<AlphaTechResponseDataList<UserData>> {
    const appInfo: AppInfo = this.sessionStorageService.getSelectedAppInfo();
    const params: UserGetListParams = new UserGetListParams();
    params.Sorting = SORTING_ID_DESC;
    params.Take = this.USER_PAGE_SIZE;
    params.Position = pageNumber * this.USER_PAGE_SIZE;

    if (searchText) {
      params.Filter.Keyword = searchText;
    }

    if (appInfo) {
      params.Filter.AppID = appInfo.id;
    }

    return AlphaTechSubscribeUtils.subscribeDataListResponseHandling<UserData>(this.userService.getUsers(params));
  }

  // Password
  userPasswordUpdate(params: PasswordUpdateModel): Observable<AlphaTechServerMessage> {
    return AlphaTechSubscribeUtils.subscribeResponseHandling(
      this.passwordService.userPasswordUpdate(params)
    );
  }

  updatePasswordAfterLogin(newPass: string): Observable<AlphaTechServerMessage> {
    const params: PasswordUpdateModel = new PasswordUpdateModel();
    params.Password = newPass;

    return AlphaTechSubscribeUtils.subscribeResponseHandling(
      this.passwordService.updatePasswordAfterLogin(params)
    );
  }

  resetUserPassword(activationCode: string, password: string): Observable<AlphaTechResponseData<any>> {
    const params: UserResetPasswordParams = new UserResetPasswordParams(activationCode, password);

    return AlphaTechSubscribeUtils.subscribeDataResponseHandling(
      this.passwordService.resetUserPassword(params)
    );
  }

  // Language
  updateUserLanguage(params: UserLanguageUpdateParams): Observable<AlphaTechResponseData<UserData>> {
    return AlphaTechSubscribeUtils.subscribeDataResponseHandling(
      this.userService.updateUserLanguage(params)
    );
  }

}
