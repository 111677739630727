import { BaseRecord } from "src/app/core/models/base-responses.model";

export class EditorData extends BaseRecord {
  AppID: number;
  Code: string;
  Email: string;
  FlagIsCreator: number;
  FlagIsDefault: number;
  FlagIsOwner: number;
  FlagIsReadonly: number;
  Status: string;
  FullName: string;
  UserID: number;

  constructor(userId?: number) {
    super();
    this.UserID = userId ? userId : 0;
  }
}
