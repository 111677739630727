import { Component, Injector, Input, OnChanges, OnDestroy, OnInit, TemplateRef } from "@angular/core";
import { NavigationStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterEvent } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap";

import { environment } from "../../../environments/environment";
import { ModuleLoadListenerService } from "../../app-load/module-load-listener.service";
import { routerPaths } from "../../core/consts/router-paths";
import { ResponseStatus } from "../../core/enums/response-status.enum";
import { OrganizationInfo } from "../../core/models/organization-info.model";
import { UserInfo } from "../../core/models/user-info.model";
import { AuthService } from "../../core/services/auth.service";
import { SessionStorageService } from "../../core/services/session-storage.service";
import { AppInfo } from "../../modules/my-apps/model/my-apps.model";
import { AppPresenter } from "../../modules/my-apps/presenters/app.presenter";
import { BaseComponent } from "../../shared/components/base-components/base-component";
import { SessionChangeService } from "../../shared/services/session-change.service";
import { LoggerUtil } from "../../shared/utils/logger.util";
import { StringUtil } from "../../shared/utils/string.utils";
import { NavBarType } from "./nav-bar-type.model";
import { SessionRoutingParams } from "src/app/core/models/session-params.model";
import { TranslateService } from "@ngx-translate/core";
import { languageDefaults } from "src/app/core/consts/language-defaults";
import { ModalUtils } from "src/app/shared/utils/modal.utils";

@Component({
  selector: "navbar",
  templateUrl: "nav-bar.component.html",
  styleUrls: ["nav-bar.component.scss"]
})
export class NavBarComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isLoggedIn: boolean;

  readonly routerPaths = routerPaths;
  readonly navBarTypeEnum = NavBarType;
  readonly environment = environment;

  publishConfirmationModalRef: BsModalRef;

  navBarType: NavBarType = NavBarType.NotLoggedIn;
  fullUserName: string = "";
  logoPath: string = "";
  appInfo: AppInfo = new AppInfo();
  noNavbarRoutes: string[] = [
    this.routerPaths.ViewNotification
  ];
  pageBuilderPreviewNavBarRoutes: string[] = [];
  pageBuilderNavBarRoutes: string[] = [
    this.routerPaths.PageBuilder,
    this.routerPaths.AppDetails,
    this.routerPaths.AppThemes,
    this.routerPaths.AppThemeEditor,
    this.routerPaths.AppGlobalOptions,
    this.routerPaths.AppImages,
    this.routerPaths.AppUsers,
    this.routerPaths.AssignedDeltas,
    this.routerPaths.AssignedOutboundMessages,
    this.routerPaths.PushNotifications,
    this.routerPaths.AppAudit,
    this.routerPaths.PageDesigner,
    this.routerPaths.Analytics,
    this.routerPaths.Subscriptions,
    this.routerPaths.Shopping,
    this.routerPaths.AlphaProducts,
    this.routerPaths.AppInApp,

  ];

  constructor(
    private auth: AuthService,
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private sessionChangeService: SessionChangeService,
    private appPresenter: AppPresenter,
    private translate: TranslateService,
    public modalService: BsModalService,
    public moduleLoadChangeListener: ModuleLoadListenerService,
    injector: Injector
  ) {
    super(injector);
    this.isModuleLoadingListener();
  }

  ngOnInit(): void {
    this.translate.use(this.sessionStorageService.getLanguageCode());
    this.listenLanguageChange();
    this.initRouterChangeListener();
    this.listenSessionAppInfoChange();

    const organizationInfo: OrganizationInfo = this.sessionStorageService.getOrganizationInfo();
    if (organizationInfo) {
      this.logoPath = organizationInfo.Logo;
    }
  }
  listenLanguageChange() {
    this.sessionChangeService.getLanguageSubject().subscribe(response => {
      this.translateService.use(response);
    });
  }


  ngOnChanges(changes: any) {
    if (changes.isLoggedIn.currentValue === true) {
      const userInfo: UserInfo = this.sessionStorageService.getUserInfo();
      if (userInfo) {
        this.fullUserName = userInfo.FirstName + " " + userInfo.LastName;
      }

      this.navBarType = this.navBarTypeEnum.General;
    }
  }

  isModuleLoadingListener() {
    this.subscriptions.push(this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.moduleLoadChangeListener.notifyIsLoadingDataChanged(true);
      } else if (event instanceof RouteConfigLoadEnd) {
        this.moduleLoadChangeListener.notifyIsLoadingDataChanged(false);
      }
    }));
  }

  logout(event: any) {
    event.preventDefault();
    this.auth.logout();
  }

  toProfile(event: Event) {
    event.preventDefault();
    const routeParams: SessionRoutingParams = this.sessionStorageService.getRoutingParams() ?
      this.sessionStorageService.getRoutingParams() : new SessionRoutingParams();
    routeParams.isFromUpdateBillingButton = false;
    this.sessionStorageService.setRoutingParams(routeParams);
    this.router.navigate([routerPaths.Profile]);
  }

  toBusinessProfile(event: Event) {
    event.preventDefault();
    this.router.navigate([routerPaths.BusinessProfile]);
  }

  toUserAudit(event: Event) {
    event.preventDefault();
    this.router.navigate([routerPaths.UserAudit]);
  }

  publishApp() {
    this.publishConfirmationModalRef.hide();
    this.isSaving = true;
    this.appPresenter.publishApp(this.appInfo.id).subscribe(response => {
      this.isSaving = false;
      if (response.Status == ResponseStatus.Success) {
        this.appInfo.version = response.App.Version;
        this.sessionStorageService.setSelectedAppInfo(this.appInfo);
        this.toastrHelper.toastPublishSucceeded();
      } else {
        this.toastrHelper.toastPublishFailed(response.UserMessage);
      }
    },
      error => {
        this.isSaving = false;
        this.toastrHelper.toastPublishFailed();
        LoggerUtil.log(this.constructor.name, "publishApp" + error);
      });
  }

  showPublishConfirmationModal(template: TemplateRef<any>) {
    this.publishConfirmationModalRef = ModalUtils.showModalIgnoringBackdropClick(this.modalService, template);
  }

  private listenSessionAppInfoChange() {
    this.subscriptions.push(
      this.sessionChangeService.getSessionAppInfoSubject().subscribe(
        sessionAppInfoChange => {
          if (sessionAppInfoChange.isRestored) {
            this.appInfo = sessionAppInfoChange.appInfo;
          }
        }
      ));
  }

  private initRouterChangeListener() {
    this.subscriptions.push(this.router.events.subscribe(event => {
      this.initNavBarTypeChangeByRoutPath(<RouterEvent>event);
    }));
  }

  private initNavBarTypeChangeByRoutPath(routerEvent: RouterEvent) {
    if (routerEvent instanceof NavigationStart) {
      if (this.isLoggedIn) {
        if (StringUtil.containsOneOfTheArrayElement(routerEvent.url, this.pageBuilderNavBarRoutes)) {
          this.appInfo = this.sessionStorageService.getSelectedAppInfo();
          this.navBarType = this.navBarTypeEnum.AppBuilder;
        } else if (StringUtil.containsOneOfTheArrayElement(routerEvent.url, this.pageBuilderPreviewNavBarRoutes)) {
          this.appInfo = this.sessionStorageService.getSelectedAppInfo();
          this.navBarType = this.navBarTypeEnum.AppBuilderPreview;
        } else if (StringUtil.containsOneOfTheArrayElement(routerEvent.url, this.noNavbarRoutes)) {
          this.navBarType = this.navBarTypeEnum.None;
        } else {
          this.navBarType = this.navBarTypeEnum.General;
        }
      } else {
        this.navBarType = this.navBarTypeEnum.NotLoggedIn;
      }
    }
  }
}
