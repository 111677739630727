import { BaseParams } from "./base-requests.model";
import { GeneralResponse } from "./base-responses.model";

export class UserForgotPasswordParams extends BaseParams {
  Email: string;

  constructor(email: string, authToken?: string, languageId?) {
    super(authToken, languageId);
    this.Email = email;
  }
}

export class UserForgotPasswordResponse extends GeneralResponse { }
