import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { GeneralResponse } from "../../../core/models/base-responses.model";
import { EndpointsService } from "../../../core/services/endpoint.service";
import { HttpService } from "../../../core/services/http.service";
import { GenericItemsPositionsUpdateParams } from "../../model/generic-item-positions-params";

@Injectable({
  providedIn: "root"
})
export class GenericItemPositionService extends EndpointsService {

  private readonly UPDATE_GENERIC_ITEMS_POSITIONS: string = `${this.API_URL}/GenericItemsPositionsUpdate`;

  constructor(
    private httpService: HttpService
  ) {
    super();
  }

  updateGenericItemPosition(genericItemsPositionsUpdateParams: GenericItemsPositionsUpdateParams): Observable<GeneralResponse> {
    return this.httpService.post_authorized(this.UPDATE_GENERIC_ITEMS_POSITIONS, genericItemsPositionsUpdateParams);
  }

}
