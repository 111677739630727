import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { EndpointsService } from "../../../core/services/endpoint.service";
import { HttpService } from "../../../core/services/http.service";
import { CountryParams, CountryResponse } from "../model/country.model";

@Injectable({
  providedIn: "root"
})
export class CountryService extends EndpointsService {
  private readonly POST_COUNTRY_GET: string = `${this.API_URL}/CountryGetList`;

  constructor(private httpService: HttpService) {
    super();
  }

  getCountries(params: CountryParams): Observable<CountryResponse> {
    return this.httpService.post_authorized(this.POST_COUNTRY_GET, params);
  }

}
