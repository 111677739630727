import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CustomValidators } from "ng2-validation";

import { USA_COUNTRY_CODE } from "../../../../../core/consts/country.const";
import { maxMoth as maxMonth, maxYear, minMonth, minYear, NUMBER_WITH_SPACE_PATTERN, Validation } from "../../../../../core/consts/validation";
import { CountryData } from "../../../../../modules/user-profile/model/country.model";
import { BaseFormComponent } from "../../../../../shared/components/base-components/base-form-component";
import { RequestOperationType } from "../../../../../shared/components/base-components/request-operation-type.model";
import { ModalType } from "../../../../../shared/components/modals/modal-type.model";
import { CloneUtil } from "../../../../../shared/utils/clone.utils";
import { DomUtils, InputFieldType } from "../../../../../shared/utils/dom.utils";
import { FormValidatorUtil } from "../../../../../shared/utils/form-validator.util";
import { StringUtil } from "../../../../../shared/utils/string.utils";
import { FormValidationModel } from "../../../../../shared/validation/form-validation.model";
import { ValidationModel } from "../../../../../shared/validation/validation.model";
import { UserBillingData } from "../../../models/user-billing.model";
import { BillingPresenter } from "../../../presenters/billing.presenter";
import { SessionChangeService } from "src/app/shared/services/session-change.service";
import { SessionStorageService } from "src/app/core/services/session-storage.service";
import { UserLoginActionType } from "src/app/core/enums/response-status.enum";
import { NotificationBarListenerService } from "src/app/components/notification-bar/services/notification-bar.service";
import { ServerMessageUtil } from "src/app/shared/utils/server-message.utils";

@Component({
  selector: "add-edit-user-billing-modal",
  templateUrl: "./add-edit-user-billing-modal.component.html"
})
export class AddEditUserBillingModalComponent extends BaseFormComponent implements OnInit, OnDestroy {
  @Input() modalType: ModalType;
  @Input() userBillingData: UserBillingData;
  @Input() countryDataList: CountryData[];
  @Output() onAdded: EventEmitter<UserBillingData> = new EventEmitter();
  @Output() onEdited: EventEmitter<UserBillingData> = new EventEmitter();
  @Output() onClose: EventEmitter<void> = new EventEmitter();

  readonly USA_COUNTRY_CODE = USA_COUNTRY_CODE;
  readonly modalTypeEnum = ModalType;

  // Billing information
  readonly FIRST_NAME = "firstName";
  readonly LAST_NAME = "lastName";
  readonly ADDRESS = "address";
  readonly APARTMENT = "apartment";
  readonly STATE = "state";
  readonly COUNTRY = "country";
  readonly CITY = "city";
  readonly ZIP = "zip";
  readonly COMPANY = "company";
  readonly EMAIL = "email";
  readonly PHONE = "phone";
  readonly REGISTRATIONNUMBER = "registrationnumber";
  readonly TAXIDENTIFICATION = "taxidentification";
  readonly VATIDENTIFICATION = "vatidentification";
  readonly PONUMBER = "ponumber";

  // Card info
  readonly CARD_NUMBER = "cardNumber";
  readonly EXPIRATION_YEAR = "expirationYear";
  readonly EXPIRATION_MONTH = "expirationMonth";
  readonly CCV = "ccv";

  selectedCountry: string;

  constructor(
    private billingPresenter: BillingPresenter,
    private sessionStorageService: SessionStorageService,
    private sessionChangeService: SessionChangeService,
    private notificationBarListener: NotificationBarListenerService,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.initForm();
    this.stateValidationListening();
  }

  closeModal() {
    this.onClose.emit();
  }

  saveClick() {
    if (this.isFormValid()) {
      const userBillingInstance: UserBillingData = (this.modalType == ModalType.Add) ? new UserBillingData() : CloneUtil.getClonedInstance(this.userBillingData);

      userBillingInstance.BusinessName = this.formGroup.get(this.COMPANY).value;
      userBillingInstance.FirstName = this.formGroup.get(this.FIRST_NAME).value;
      userBillingInstance.LastName = this.formGroup.get(this.LAST_NAME).value;
      userBillingInstance.Address = this.formGroup.get(this.ADDRESS).value;
      userBillingInstance.Suite = this.formGroup.get(this.APARTMENT).value;
      userBillingInstance.City = this.formGroup.get(this.CITY).value;
      userBillingInstance.State = this.formGroup.get(this.STATE).value;
      userBillingInstance.Zip = this.formGroup.get(this.ZIP).value;
      userBillingInstance.Country = this.formGroup.get(this.COUNTRY).value;
      userBillingInstance.Email = this.formGroup.get(this.EMAIL).value;
      userBillingInstance.Phone = this.formGroup.get(this.PHONE).value;
      userBillingInstance.RegistrationNumber = this.formGroup.get(this.REGISTRATIONNUMBER).value;
      userBillingInstance.TaxIdentification = this.formGroup.get(this.TAXIDENTIFICATION).value;
      userBillingInstance.VATIdentification = this.formGroup.get(this.VATIDENTIFICATION).value;
      userBillingInstance.PONumber = this.formGroup.get(this.PONUMBER).value;

      userBillingInstance.CreditCardNumber = this.formGroup.get(this.CARD_NUMBER).value;
      // TODO: find some example on UI with year/month selection
      userBillingInstance.ExpirationYear = this.formGroup.get(this.EXPIRATION_YEAR).value;
      userBillingInstance.ExpirationMonth = this.formGroup.get(this.EXPIRATION_MONTH).value;
      userBillingInstance.CCV = this.formGroup.get(this.CCV).value;

      this.presenterRequestHandler(
        this.billingPresenter.updateUserBilling(userBillingInstance),
        RequestOperationType.IsSaving,
      ).subscribe(response => {
        this.removeBillingUpdateNotification();
        this.toastrHelper.showServerSuccess(response.serverMessage.userMessage);
        switch (this.modalType) {
          case ModalType.Add:
            this.onAdded.emit(response.data);
            break;
          case ModalType.Edit:
            this.onEdited.emit(response.data);
            break;
        }
        this.closeModal();
      });
    } else {
      FormValidatorUtil.validateFormElements(this.formGroup);
      this.toastrHelper.toastInvalidFields();
    }
  }

  isFormValid(): boolean {
    let isValid: boolean = true;
    const { controls, valid } = this.formGroup;
    const { Input: input, Select: select } = InputFieldType;

    if (!valid) {
      isValid = false;

      if (!controls[this.CARD_NUMBER].valid) {
        DomUtils.focus(input, this.CARD_NUMBER);
      } else if (!controls[this.EXPIRATION_YEAR].valid) {
        DomUtils.focus(input, this.EXPIRATION_YEAR);
      } else if (!controls[this.EXPIRATION_MONTH].valid) {
        DomUtils.focus(input, this.EXPIRATION_MONTH);
      } else if (!controls[this.CCV].valid) {
        DomUtils.focus(input, this.CCV);
      } else if (!controls[this.COMPANY].valid) {
        DomUtils.focus(input, this.COMPANY);
      } else if (!controls[this.FIRST_NAME].valid) {
        DomUtils.focus(input, this.FIRST_NAME);
      } else if (!controls[this.LAST_NAME].valid) {
        DomUtils.focus(input, this.LAST_NAME);
      } else if (!controls[this.ADDRESS].valid) {
        DomUtils.focus(input, this.ADDRESS);
      } else if (!controls[this.APARTMENT].valid) {
        DomUtils.focus(input, this.APARTMENT);
      } else if (!controls[this.CITY].valid) {
        DomUtils.focus(input, this.CITY);
      } else if (!controls[this.STATE].valid) {
        DomUtils.focus(input, this.STATE);
      } else if (!controls[this.ZIP].valid) {
        DomUtils.focus(input, this.ZIP);
      } else if (!controls[this.COUNTRY].valid) {
        DomUtils.focus(select, this.COUNTRY);
      }
    }

    return isValid;
  }

  private initForm() {
    const formControl: LooseObject = {};
    const isEditMode = this.modalType == ModalType.Edit;
    const monthValidations = [Validators.required, CustomValidators.number, CustomValidators.gte(minMonth), CustomValidators.lte(maxMonth)];
    const yearValidations = [Validators.required, CustomValidators.number, CustomValidators.gte(minYear), CustomValidators.lte(maxYear)];

    // Billing information
    formControl[this.COMPANY] = new FormControl(isEditMode ? this.userBillingData.BusinessName : "");
    formControl[this.FIRST_NAME] = new FormControl(isEditMode ? this.userBillingData.FirstName : "", [Validators.required]);
    formControl[this.LAST_NAME] = new FormControl(isEditMode ? this.userBillingData.LastName : "", [Validators.required]);
    formControl[this.ADDRESS] = new FormControl(isEditMode ? this.userBillingData.Address : "", [Validators.required]);
    formControl[this.APARTMENT] = new FormControl(isEditMode ? this.userBillingData.Suite : "");
    formControl[this.CITY] = new FormControl(isEditMode ? this.userBillingData.City : "", [Validators.required]);
    formControl[this.STATE] = new FormControl(isEditMode ? this.userBillingData.State : "");
    formControl[this.ZIP] = new FormControl(isEditMode ? this.userBillingData.Zip : "", [Validators.required]);
    formControl[this.COUNTRY] = new FormControl(isEditMode ? this.userBillingData.Country : "", [Validators.required]);
    formControl[this.EMAIL] = new FormControl(isEditMode ? this.userBillingData.Email : "");
    formControl[this.PHONE] = new FormControl(isEditMode ? this.userBillingData.Phone : "");
    formControl[this.REGISTRATIONNUMBER] = new FormControl(isEditMode ? this.userBillingData.RegistrationNumber : "");
    formControl[this.TAXIDENTIFICATION] = new FormControl(isEditMode ? this.userBillingData.TaxIdentification : "");
    formControl[this.VATIDENTIFICATION] = new FormControl(isEditMode ? this.userBillingData.VATIdentification : "");
    formControl[this.PONUMBER] = new FormControl(isEditMode ? this.userBillingData.PONumber : "");

    // Card information
    formControl[this.CARD_NUMBER] = new FormControl(
      isEditMode ? StringUtil.replaceAll(this.userBillingData.CreditCardNumber, "*", "X") : "",
      [Validators.required, Validators.pattern(NUMBER_WITH_SPACE_PATTERN)]
    );
    // TODO: find some example on UI with year/month selection
    formControl[this.EXPIRATION_YEAR] = new FormControl(isEditMode ? this.userBillingData.ExpirationYear : undefined, yearValidations);
    formControl[this.EXPIRATION_MONTH] = new FormControl(isEditMode ? this.userBillingData.ExpirationMonth : undefined, monthValidations);
    formControl[this.CCV] = new FormControl(
      isEditMode ? StringUtil.replaceAll(this.userBillingData.CCV, "*", "X") : "",
      [Validators.required, Validators.pattern(NUMBER_WITH_SPACE_PATTERN)]
    );

    this.formGroup = new FormGroup(formControl);
    this.formValidation = new FormValidationModel(this.formGroup);

    this.validationModels.cardNumber = new ValidationModel(this.formGroup, this.CARD_NUMBER, Validation.Pattern, "Validation.IsNumericWithWhiteSpace");
    this.validationModels.invalidCCVNumber = new ValidationModel(this.formGroup, this.CCV, Validation.Number, "Validation.IsNumericWithWhiteSpace");
    this.validationModels.invalidYearNumber = new ValidationModel(this.formGroup, this.EXPIRATION_YEAR, Validation.Number, "Validation.IsNumeric");
    this.validationModels.invalidMonthNumber = new ValidationModel(this.formGroup, this.EXPIRATION_MONTH, Validation.Number, "Validation.IsNumeric");

    this.validationModels.invalidLowYear = new ValidationModel(this.formGroup, this.EXPIRATION_YEAR, Validation.GreaterThanEqual, "Validation.YearIsToLow");
    this.validationModels.invalidHighYear = new ValidationModel(this.formGroup, this.EXPIRATION_YEAR, Validation.LowerThanEqual, "Validation.YearIsToHigh");
    this.validationModels.invalidLowMonth = new ValidationModel(this.formGroup, this.EXPIRATION_MONTH, Validation.GreaterThanEqual, "Validation.MonthIsToLow");
    this.validationModels.invalidHighMonth = new ValidationModel(this.formGroup, this.EXPIRATION_MONTH, Validation.LowerThanEqual, "Validation.MonthIsToHigh");
  }

  /**
   * Just in case of USA needed the state validation and field
   */
  private stateValidationListening() {
    this.subscriptions.push(this.formGroup.get(this.COUNTRY).valueChanges.subscribe(countryCode => {
      this.selectedCountry = countryCode;

      if (countryCode == USA_COUNTRY_CODE) {
        this.formGroup.get(this.STATE).setValidators([Validators.required]);
      } else {
        this.formGroup.get(this.STATE).clearValidators();
      }

      this.formGroup.get(this.STATE).updateValueAndValidity();
    }));
  }

  private removeBillingUpdateNotification() {
    const userActions = this.sessionStorageService.getUserActions() || []; // avoid null value
    const billingUpdateAction = userActions.find(item => item.UserLoginActionType == UserLoginActionType.UpdateBillingRequired);
    if (billingUpdateAction) {
      this.sessionChangeService.notifyUpdateBillingRequiredChange(null);
      this.sessionStorageService.setUserActions(
        this.sessionStorageService.getUserActions().filter(item => item.UserLoginActionType != billingUpdateAction.UserLoginActionType)
      );
    }
    this.notificationBarListener.notifyNotificationBarVisibilityChange(false);
  }

}
