import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";

import { routerPaths } from "../consts/router-paths";
import { SessionStorageService } from "../services/session-storage.service";

@Injectable()
export class NotAuthorizedGuard implements CanActivate {
  constructor(
    private router: Router,
    private sessionStorage: SessionStorageService
  ) { }

  canActivate() {
    if (this.sessionStorage.isAuthenticated()) {
      this.router.navigate([routerPaths.Dashboard]);
      return false;
    }

    return true;
  }
}
