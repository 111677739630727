import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";

import { passwordMaxLength, passwordMinLength, Validation } from "../../../core/consts/validation";
import { UserLoginActionType } from "../../../core/enums/response-status.enum";
import { AuthService } from "../../../core/services/auth.service";
import { SessionStorageService } from "../../../core/services/session-storage.service";
import { FormValidationModel } from "../../../shared/validation/form-validation.model";
import { InternalCustomValidators } from "../../../shared/validation/internal-custom-validators.model";
import { ValidationModel } from "../../../shared/validation/validation.model";
import { LoginActions } from "../login-actions";
import { UserPresenter } from "src/app/modules/user-profile/presenters/user.presenter";
import { ServerMessageUtil } from "src/app/shared/utils/server-message.utils";
import { ToastrHelper } from "src/app/shared/helpers/toaster.helper";
import { SessionChangeService } from "src/app/shared/services/session-change.service";

@Component({
  templateUrl: "./action-change-password.component.html"
})
export class ActionChangePasswordComponent extends LoginActions implements OnInit, OnDestroy {

  formValidation: FormValidationModel;
  newPasswordFormGroup: FormGroup;
  validationModels: any = {};
  errorMessage: string = "";
  isPasswordVisible: boolean = false;
  passwordChanged: boolean = false;
  secondsToRedirect: number = 5;
  isSaving: boolean;
  toastrHelper: ToastrHelper;

  readonly PASSWORD = "password";

  private countDownInterval;

  constructor(

    private userPresenter: UserPresenter,
    private sessionStorageService: SessionStorageService,
    private sessionChangeService: SessionChangeService,
    authService: AuthService,
    sessionStorage: SessionStorageService,
    router: Router,
    toastrService: ToastrService,
    public translateService: TranslateService
  ) {
    super(router, sessionStorage, authService, UserLoginActionType.PasswordChange);
    this.toastrHelper = new ToastrHelper(toastrService, translateService);
  }

  ngOnInit() {
    this.translateService.use(this.sessionStorageService.getLanguageCode());
    this.listenLanguageChange();
    this.initFormValidation();
  }
  listenLanguageChange() {
    this.sessionChangeService.getLanguageSubject().subscribe(response => {
      this.translateService.use(response);
    });
  }



  ngOnDestroy() {
    this.clearInterval();
  }

  saveNewPassword() {
    if (this.newPasswordFormGroup.valid) {
      const newPassword: string = this.newPasswordFormGroup.controls[this.PASSWORD].value;

      this.isSaving = true;
      this.userPresenter.updatePasswordAfterLogin(newPassword).subscribe(response => {
        this.isSaving = false;
        this.removeActionFromStorage();
        this.startCountDown();
        this.toastrHelper.showServerSuccess(response.userMessage);
      }, error => {
        this.isSaving = false;
        ServerMessageUtil.toastServerMessage(this.toastrHelper, error);
        this.errorMessage = error.statusText;
      });
    } else {
      this.newPasswordFormGroup.controls[this.PASSWORD].markAsTouched();
    }
  }

  changePasswordVisibility(isVisible: boolean) {
    this.isPasswordVisible = isVisible;
  }

  private startCountDown() {
    this.passwordChanged = true;
    this.countDownInterval = setInterval(() => {
      if (this.secondsToRedirect == 0) {
        this.clearInterval();
        this.goToDashboard();
      } else {
        this.secondsToRedirect--;
      }
    }, 1000);
  }

  private clearInterval() {
    if (this.countDownInterval) {
      clearInterval(this.countDownInterval);
    }
  }

  private initFormValidation() {
    // Form Group
    const formControls: LooseObject = {};
    formControls[this.PASSWORD] = new FormControl("", [Validators.required, Validators.minLength(passwordMinLength), Validators.maxLength(passwordMaxLength),
    InternalCustomValidators.noWhitespace]);
    this.newPasswordFormGroup = new FormGroup(formControls);

    // Validation models
    this.validationModels.passwordMinLength =
      new ValidationModel(this.newPasswordFormGroup, this.PASSWORD, Validation.MinLength, "Validation.MinLength", passwordMinLength);
    this.validationModels.passwordMaxLength =
      new ValidationModel(this.newPasswordFormGroup, this.PASSWORD, Validation.MaxLength, "Validation.MaxLength", passwordMaxLength);

    this.formValidation = new FormValidationModel(this.newPasswordFormGroup);
  }

}
