import { Observable, Subscriber } from "rxjs";
import { ResponseStatus } from "src/app/core/enums/response-status.enum";
import { AlphaTechServerMessage } from "src/app/shared/model/alphatech-server-message.model";
import { AlphaTechServerErrorService } from "src/app/components/error/alphatech-server-error.service";
import { AlphaTechResponseData, AlphaTechResponseDataList } from "src/app/shared/model/alphatech-response-data.model";
import { GenericGeneralListResponse, GenericGeneralResponse, GeneralResponse } from "src/app/core/models/base-responses.model";
import { ServerMessageUtil } from "./server-message.utils";

export class AlphaTechSubscribeUtils {

  // ----- SUBSCRIBERS -----

  static subscribeDataResponseHandling<ReturnedDataType>(
    observable: Observable<GenericGeneralResponse<ReturnedDataType>>,
    alphaTechServerErrorService?: AlphaTechServerErrorService
  ): Observable<AlphaTechResponseData<ReturnedDataType>> {
    return new Observable(observer => {
      observable.subscribe(
        response => {
          this.dataResponseHandling<ReturnedDataType>(response, observer, alphaTechServerErrorService);
        }, error => {
          observer.error(error);
        }
      );
    });
  }

  static subscribeDataListResponseHandling<ReturnedDataType>(
    observable: Observable<GenericGeneralListResponse<ReturnedDataType>>,
    alphaTechServerErrorService?: AlphaTechServerErrorService
  ): Observable<AlphaTechResponseDataList<ReturnedDataType>> {
    return new Observable(observer => {
      observable.subscribe(
        response => {
          this.dataArrayResponseHandling<ReturnedDataType>(response, observer, alphaTechServerErrorService);
        }, error => {
          observer.error(error);
        }
      );
    });
  }

  static subscribeResponseHandling(
    observable: Observable<GeneralResponse>
  ): Observable<AlphaTechServerMessage> {
    return new Observable(observer => {
      observable.subscribe(
        response => {
          this.emptyResponseHandling(response, observer);
        }, error => {
          observer.error(error);
        }
      );
    });
  }

  // ------ RESPONSE HANDLERS -----

  private static dataResponseHandling<ReturnedDataType>(
    response: GenericGeneralResponse<ReturnedDataType>,
    observer: Subscriber<AlphaTechResponseData<ReturnedDataType>>,
    alphaTechServerErrorService?: AlphaTechServerErrorService
  ) {
    const serverMessage: AlphaTechServerMessage = ServerMessageUtil.getServerMessage(response);

    if (response.Status == ResponseStatus.Success) {
      const alphaTechResponseData = new AlphaTechResponseData<ReturnedDataType>();
      alphaTechResponseData.serverMessage = serverMessage;
      alphaTechResponseData.data = response.Data;
      alphaTechResponseData.userInfo = response.UserInfo;
      alphaTechResponseData.organizationInfo = response.OrganizationInfo;
      observer.next(alphaTechResponseData);
      observer.complete();
    } else {
      if (alphaTechServerErrorService) {
        alphaTechServerErrorService.setUserMessageAndStatus(serverMessage);
      }

      observer.error(serverMessage);
    }
  }

  private static dataArrayResponseHandling<ReturnedDataType>(
    response: GenericGeneralListResponse<ReturnedDataType>,
    observer: Subscriber<AlphaTechResponseDataList<ReturnedDataType>>,
    alphaTechServerErrorService?: AlphaTechServerErrorService
  ) {
    const serverMessage: AlphaTechServerMessage = ServerMessageUtil.getServerMessage(response);

    if (response.Status == ResponseStatus.Success) {
      const alphaTechResponseData = new AlphaTechResponseDataList<ReturnedDataType>();
      alphaTechResponseData.serverMessage = serverMessage;
      alphaTechResponseData.dataList = response.Data;
      alphaTechResponseData.totalCount = response.TotalCount;
      alphaTechResponseData.userInfo = response.UserInfo;
      observer.next(alphaTechResponseData);
      observer.complete();
    } else {
      if (alphaTechServerErrorService) {
        alphaTechServerErrorService.setUserMessageAndStatus(serverMessage);
      }

      observer.error(serverMessage);
    }
  }

  private static emptyResponseHandling(response: any, observer: Subscriber<any>) {
    const serverMessage: AlphaTechServerMessage = ServerMessageUtil.getServerMessage(response);

    if (response.Status == ResponseStatus.Success) {
      observer.next(serverMessage);
      observer.complete();
    } else {
      observer.error(serverMessage);
    }
  }

}
