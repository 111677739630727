import { Component, Input } from "@angular/core";
import { UrlStatus } from "./url-status.model";

@Component({
  selector: "url-status",
  templateUrl: "./url-status.component.html"
})
export class UrlStatusComponent {
  @Input() urlStatus: UrlStatus;
  @Input() successfulMessagePath: string;
  @Input() failedMessagePath: string;

  urlStatusEnum = UrlStatus;

  constructor() { }

}
