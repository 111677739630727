import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { ToastrHelper } from "../../helpers/toaster.helper";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { SessionStorageService } from "src/app/core/services/session-storage.service";
import { SessionChangeService } from "../../services/session-change.service";

@Component({
  selector: "copy-to-clipboard-button",
  templateUrl: "./copy-to-clipboard-button.component.html"
})
export class CopyToClipboardButtonComponent implements OnInit {
  @Input() dataToCopy: string = "";

  toastrHelper: ToastrHelper;

  constructor(
    private sessionStorageService: SessionStorageService,
    private sessionChangeService: SessionChangeService,
    private toastrService: ToastrService,
    private translateService: TranslateService
  ) {
    this.toastrHelper = new ToastrHelper(toastrService, translateService);
  }

  ngOnInit() {
    this.translateService.use(this.sessionStorageService.getLanguageCode());
    this.listenLanguageChange();
  }


  public onCopyToClipboard() {
    if (!this.dataToCopy) {
      return;
    }
    const clipboardInput = document.createElement("textarea");
    clipboardInput.style.position = "fixed";
    clipboardInput.style.left = "0";
    clipboardInput.style.top = "0";
    clipboardInput.style.opacity = "0";
    clipboardInput.value = this.dataToCopy;
    document.body.appendChild(clipboardInput);
    clipboardInput.focus();
    clipboardInput.select();
    document.execCommand("copy");
    document.body.removeChild(clipboardInput);
    this.toastrHelper.toastCopiedToClipboard();
  }

  listenLanguageChange() {
    this.sessionChangeService.getLanguageSubject().subscribe(response => {
      this.translateService.use(response);
    });
  }

}


