import { Observable, of } from "rxjs";
import { map, share } from "rxjs/operators";

import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class CacheService {
  public cachedData: Map<string, any> = new Map();
  public cachedObservable: Map<string, Observable<any>> = new Map();

  constructor() { }

  get(cacheKey: string, request: Observable<any>) {
    if (this.cachedData.get(cacheKey)) {
      return of(this.cachedData.get(cacheKey));
    } else if (this.cachedObservable.get(cacheKey)) {
      return this.cachedObservable.get(cacheKey);
    } else {
      const requestData = request.pipe(map(response => {
        this.cachedObservable.set(cacheKey, null);
        this.cachedData.set(cacheKey, response);
        return this.cachedData.get(cacheKey);
      }), share());

      this.cachedObservable.set(cacheKey, requestData);
      return this.cachedObservable.get(cacheKey);
    }
  }

  clear(key: string) {
    this.cachedData.delete(key);
  }

  clearAll() {
    this.cachedData = null;
    this.cachedObservable = null;
  }
}
