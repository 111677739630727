import { Component, OnInit, Input } from "@angular/core";
import * as moment from "moment";
import { DateFormatter } from "../../model/date-formats";

@Component({
  selector: "timepicker-dropdown",
  templateUrl: "./timepicker-dropdown.component.html"
})
export class TimePickerDropdownComponent implements OnInit {
  @Input() date: Date;
  constructor() { }

  ngOnInit() {
  }

  convertDateToTime(date: Date): string {
    return moment(date).format(DateFormatter.HH_mm);
  }
}
