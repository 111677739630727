import { FormGroup } from "@angular/forms/src/model";

export class ValidationModel {
  constructor(
    public form: FormGroup,
    public controlName: string,
    public validator: string,
    public messageKey: string,
    public messageParam: any = ""
  ) { }

  public showErrorMessage(): boolean {
    if (this.controlName === "") {
      return false;
    }

    const control = this.form.get(this.controlName);
    if ((control || null) === null) {
      return false;
    }

    return this.validator === ""
      ? control.invalid && control.touched
      : control.hasError(this.validator) && control.touched;
  }
}
