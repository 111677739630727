import { Component } from "@angular/core";
import { Location } from "@angular/common";

@Component({
  selector: "internal-server-error",
  templateUrl: "./internal-server-error.component.html"
})
export class InternalServerErrorComponent {

  constructor(private location: Location) { }

  backPage() {
    this.location.back();
  }

}
