export class MapCoordinates {
  lat: number;
  lng: number;

  constructor(lat?: number, lng?: number) {
    if (lat && lng) {
      this.lat = lat;
      this.lng = lng;
    }
  }
}

export class MapMarker extends MapCoordinates {
  label?: string;
  draggable: boolean;

  constructor(lat?: number, lng?: number) {
    super(lat, lng);
    if (lat && lng) {
      this.lat = lat;
      this.lng = lng;
    }
  }
}

export class LocationMapCoordinates {
  static readonly Denver  = new MapCoordinates(39.57609948596421, -104.93139070807717);
}
