import { Component, EventEmitter, Output, Input } from "@angular/core";
import { PurchasePackageTile } from "./purchase-package-tile.modal";

@Component({
  selector: "purchase-package-tile",
  templateUrl: "./purchase-package-tile.component.html",
  styleUrls: ["./purchase-package-tile.component.scss"]
})
export class PurchasePackageTileComponent {
  @Input() tileData: PurchasePackageTile;
  @Output() onTileClick: EventEmitter<PurchasePackageTile> = new EventEmitter();

  constructor() { }

  tileClicked() {
    this.tileData.isSelected = true;
    this.onTileClick.emit(this.tileData);
  }
}
