import { Injectable, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { SessionStorageService } from "src/app/core/services/session-storage.service";
import { SessionChangeService } from "../services/session-change.service";

@Injectable()
export class AppTranslateService implements OnInit {
  items: Array<TranslateService> = new Array<TranslateService>();

  constructor(
    private translate: TranslateService,
    private sessionChangeService: SessionChangeService,
    private sessionStorageService: SessionStorageService,
  ) { }

  ngOnInit() {
    this.translate.use(this.sessionStorageService.getLanguageCode());
    this.listenLanguageChange();
  }

  listenLanguageChange() {
    this.sessionChangeService.getLanguageSubject().subscribe(response => {
      this.translate.use(response);
    });
  }


  add(item: TranslateService) {
    this.items.push(item);
  }

  update(lang: string) {
    this.items.forEach(
      item => item.use(lang)
    );
  }
}

