import { Component } from "@angular/core";

@Component({
  selector: "bank-card-meta-tile",
  templateUrl: "./bank-card-meta-tile.component.html"
})
export class BankCardMetaTileComponent {

  constructor() { }

}
