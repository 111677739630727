import { ImageTileData } from "./images-list/image-tile/image-tile.model";
import { GenericBaseListParams, BaseParams } from "src/app/core/models/base-requests.model";
import { GenericGeneralListResponse, ImageData, GeneralResponse, GenericGeneralResponse } from "src/app/core/models/base-responses.model";

export class AppImagesData {
  appId: number;

  imageTileList: ImageTileData[] = [];
  constructor() {
    this.imageTileList = [];
  }
}

// ---------------------- Get List ---------------------------

export class AppImageGetListParams extends GenericBaseListParams<ImageFilter> { }

export class AppImageGetListResponse extends GenericGeneralListResponse<ImageData> { }


// ---------------------- Add ---------------------------

export class ImageAddParams extends BaseParams {
  Data: ImageData;
  CloneID: number;
}

export class ImageAddResponse extends GenericGeneralResponse<ImageData> { }

// ---------------------- Delete ---------------------------

export class ImageDeleteParams extends BaseParams {
  ID: number;
}

// ---------------------- Update ---------------------------

export class ImageUpdateParams extends BaseParams {
  Data: ImageData;
}

export class ImageUpdateResponse extends GenericGeneralResponse<ImageData> { }

export class ImageFilter extends BaseParams {
  Status: number;
  AppID: number;
  SelectedAppID: number;
  FlagShowSystemImages: number;
  FlagShowImageType: number;
  ImageTag: string;
  TargetItemID: number;
}

export enum ImageType {
  All = -1,
  Drawable = 0,
  Picture = 1
}
