import { NgModule, APP_INITIALIZER } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";

import { AppLoadService } from "./app-load.service";
import { AppCoreModule } from "../core/core.module";
import { StorageService } from "../core/services/storage.service";
import { SessionStorageService } from "../core/services/session-storage.service";

// tslint:disable-next-line:only-arrow-functions
export function init_app(appLoadService: AppLoadService) {
  return () => appLoadService.initializeApp();
}

@NgModule({
  imports: [HttpClientModule, AppCoreModule],
  providers: [
    StorageService,
    SessionStorageService,
    AppLoadService,
    { provide: APP_INITIALIZER, useFactory: init_app, deps: [AppLoadService], multi: true }
  ]
})
export class AppLoadModule { }
