import { Component, Injector, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { zip } from "rxjs";
import { SessionStorageService } from "src/app/core/services/session-storage.service";
import { SessionChangeService } from "src/app/shared/services/session-change.service";
import { AppTranslateService } from "src/app/shared/translate/app-translate.service";
import { LanguageData, LanguageGetListParams, LanguageSetParams } from "./language-model/language-model.model";
import { LanguagePresenter } from "./language-presenter/language-presenter.presenter";
import { LanguageService } from "./language-service/language-service.service";

@Component({
  selector: "translation-menu-block",
  templateUrl: "./translation-menu-block.component.html"
})
export class TranslationMenuBlockComponent implements OnInit {
  usedLanguage: string = "";
  languageList: LanguageData[] = [];
  formGroup: FormGroup;

  readonly LANGUAGE: string = "language";

  constructor(
    private translate: TranslateService,
    private appTranslateService: AppTranslateService,
    private sessionStorageService: SessionStorageService,
    private sessionChangeService: SessionChangeService,
    private languagePresenter: LanguagePresenter,
    private languageService: LanguageService
  ) {
  }

  ngOnInit() {
    this.initForm();
    this.getLanguageList();
    this.translate.use(this.sessionStorageService.getLanguageCode());
    this.listenLanguageChange();
    this.changeLanguage();
    this.usedLanguage = this.sessionStorageService.getLanguageCode();
  }

  initForm() {
    const formControls: LooseObject = {};
    formControls[this.LANGUAGE] = new FormControl(null);
    this.formGroup = new FormGroup(formControls);
  }

  changeLanguage() {
    this.formGroup.controls[this.LANGUAGE].valueChanges.subscribe(response => {
      this.translate.use(response);
      this.sessionStorageService.setLanguageCode(response);
      const languageItem = this.languageList.find(value => value.LanguageCode === response);
      this.setLanguage(languageItem.ID, languageItem.LanguageID);
    });
  }

  listenLanguageChange() {
    this.sessionChangeService.getLanguageSubject().subscribe(response => {
      this.translate.use(response);
    });
  }

  private getLanguageList() {
    const params: LanguageGetListParams = new LanguageGetListParams();
    zip(
      this.languagePresenter.getLanguageList(params)
    ).subscribe(([response]) => {
      this.languageList = response.dataList;
      this.sessionStorageService.setLanguageList(response.dataList);
      this.formGroup.controls[this.LANGUAGE].setValue(this.languageList.find(item => item.LanguageCode ===
        this.sessionStorageService.getLanguageCode()) ? this.languageList.find(item => item.LanguageCode ===
          this.sessionStorageService.getLanguageCode()).LanguageCode : "en");
    });
  }

  private setLanguage(id: number, languageId: number) {
    const params: LanguageSetParams = new LanguageSetParams();
    params.LanguageID = languageId;
    params.TargetLanguageID = languageId;
    zip(
      this.languageService.setLanguage(params)
    ).subscribe(([response]) => {

    });
  }
}
