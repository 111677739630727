import { GeneralResponse } from "src/app/core/models/base-responses.model";

export class PasswordUpdateModel {
  AuthToken: string;
  LanguageID: number;
  Password: string;
  PasswordOld: string;
}

export class UserPasswordUpdateResponse extends GeneralResponse { }
