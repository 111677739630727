import { EnumUtils } from "src/app/shared/utils/enum.utils";
import { IconMeta } from "../components/icon/icon.model";

export class TileBase {
  id: number;
  iconMeta: IconMeta;
  title: string;
  description: string;
  isSelected: boolean;
}

export enum IconFontFamilyEnum {
  FontAwesomeRegular = "FontAwesome",
  FontAwesomeSolid = "FontAwesomefas",
  FontAwesomeBrand = "FontAwesomefab",
  MaterialDesign = "MaterialDesign",
  Ionicons = "Ionicons"
}

export namespace IconFontFamilyEnum {
  export const getByValue = (fontFamilyEnumValue: string): IconFontFamilyEnum => {
    return EnumUtils.getEnumValues(IconFontFamilyEnum, "string").find(value => value == fontFamilyEnumValue);
  };

  export const getByKey = (fontFamilyName: string): IconFontFamilyEnum => {
    return IconFontFamilyEnum[fontFamilyName];
  };
}

