import { Injectable } from "@angular/core";

import { CookieStorageService } from "./cookie-storage.service";

@Injectable()
export class StorageService {
    private storage: any;

    constructor() {
        this.getStorageType();
    }

    public get<T>(key: string) {
        const value = this.storage.getItem(key);
        if (!value) { return null; }
        return <T>JSON.parse(value);
    }

    public set<T>(key: string, value: T) {
        this.storage.setItem(key, JSON.stringify(value));
    }

    public remove(key: string) {
        this.storage.removeItem(key);
    }

    private getStorageType() {
        if (this.storage) {
            return;
        }

        try {
            this.storage = localStorage;
        } catch (e) {
            if (navigator.cookieEnabled) {
                this.storage = new CookieStorageService();
            }
        }
    }

}
