import { BaseParams, GenericBaseListParams } from "src/app/core/models/base-requests.model";
import { DateData, GenericGeneralListResponse } from "src/app/core/models/base-responses.model";

export class LanguageGetListParams extends GenericBaseListParams<LanguageFilter> { }

export class LanguageFilter {
  Status: number;
}

export class LanguageGetListResponse extends GenericGeneralListResponse<LanguageData> { }

export class LanguageData {
  CreatedByName: string;
  CreatedDate: DateData;
  ID: number;
  ModifiedByName: string;
  ModifiedDate: DateData;
  Code: string;
  Status: number;
  LanguageCode: string;
  LanguageID: number;
  LanguageName: string;
  LanguageProductID: number;
  OrganizationID: number;
  Position: number;
}

export class AppLanguageData {
  CreatedByName: string;
  CreatedDate: DateData;
  ID: number;
  ModifiedByName: string;
  ModifiedDate: DateData;
  Code: string;
  Name: string;
  NativeName: string;
}

export class AppAvaiableData {
  CreatedByName: string;
  CreatedDate: DateData;
  Status: number;
  CreatedBy: number;
  ID: number;
  ModifiedByName: string;
  ModifiedDate: DateData;
  Code: string;
  AppID: number;
  LanguageID: number;
  Position: number;
}

export class AppAvailableLanguageGetFilter {
  Status: number;
  AppID: number;
}

export class AppAvailableGetLanguageListParams extends GenericBaseListParams<AppAvailableLanguageGetFilter> { }

export class AppAvailableGetLanguageListParamsUpdate extends BaseParams {
  AppID: number;
  Data: AppAvaiableData[];
}

export class AppAvailableAddParams extends BaseParams {
  Data: AppAvaiableData[];
}

export class AppAvailableGetLanguageListResponse extends GenericGeneralListResponse<AppAvaiableData> { }

export class AppGetLanguageListParams extends GenericBaseListParams<LanguageGetFilter> { }

export class LanguageGetFilter { }

export class AppGetLanguageListResponse extends GenericGeneralListResponse<AppLanguageData> { }

export class LanguageSetParams {
  AuthToken: string;
  LanguageID: number;
  FlagForceCompact: number;
  TargetLanguageID: number;
}
