import { UserInfo } from "../../core/models/user-info.model";
import { SessionStorageService } from "../../core/services/session-storage.service";

export class CreditUtils {
  static getClientCreditCount(sessionStorageService: SessionStorageService) {
    const userInfo: UserInfo = sessionStorageService.getUserInfo();
    return (userInfo) ? this.getClientCreditCountByUser(userInfo) : 0;
  }

  static getClientCreditCountByUser(userInfo: UserInfo) {
    return (userInfo) ? userInfo.AppMaxPremium - userInfo.AppCountPremium : 0;
  }
}
