import { BaseParams } from "../../../core/models/base-requests.model";
import { GeneralResponse, LanguageData, ImageData, BaseRecord, DateData } from "../../../core/models/base-responses.model";
import { SaveData } from "../common-elements/components/save-module/save-module.model";
import { VisibilityData } from "../common-elements/components/visibility/visibility-data.model";
import { DeleteData } from "../common-elements/components/delete-page/delete-page.model";

export class ModuleBaseData extends BaseRecord {
  // UI component related data with lower case
  imageSource: string;
  isImageValid: boolean;
}

export class ModuleData extends ModuleBaseData {
  Code: string;
  Status: number;
  Language: ModuleLanguageData = new ModuleLanguageData();
  Languages: ModuleLanguageData[] = [];
  Image: ImageData = new ImageData();
  Thumbnail: string;
  AppItemID: number;
  DeltaFields: string;
  Level: number;
  ModuleAddonTypeID: number;
  ModuleVisibilityTypeID: number;
  Position: number;
  ShoppingAppItemID: number;
  AppInAppAppItemID: number;
  AppItemData: AppItemData = new AppItemData();

  constructor(moduleData?: ModuleData) {
    super();
    if (moduleData) {
      Object.assign(this, moduleData);
    }
  }
}

export class ModuleItemLanguageData extends LanguageData {
  ShareText: string = "";
  ShareURL: string = "";
}

export class ModuleLanguageData extends ModuleItemLanguageData {
  NoItemsMessage: string = "";
}

export class AppItemData {
  CreatedBy: number;
  CreatedByName: string;
  CreatedDate: DateData;
  ID: number;
  ModifiedByName: string;
  ModifiedDate: DateData;
  Code: string;
  Status: number;
  Language: ModuleLanguageData = new ModuleLanguageData();
  Languages: ModuleLanguageData[] = [];
  AppID: number;
  AppItemContentBanks: ContentBanks[] = [];
}

export class ContentBanks {
  CreatedBy: number;
  CreatedByName: string;
  CreatedDate: DateData;
  ID: number;
  ModifiedByName: string;
  ModifiedDate: DateData;
  Code: string;
  Status: number;
  AppItemID: number;
  Tag: string;
}

export class CommonModuleData {
  saveData: SaveData = new SaveData();
  visibilityData: VisibilityData = new VisibilityData();
  deleteData: DeleteData = new DeleteData();
  contentBanks: ContentBanks[] = [];
}

export class CommonComponentData extends CommonModuleData {
  moduleData: ModuleData = new ModuleData();
}

export class ModuleGetParams extends BaseParams {
  AppItemID: number;

  constructor(appId?: number) {
    super();

    if (appId) {
      this.AppItemID = appId;
    }
  }
}

export class ModuleUpdateParams extends BaseParams {
  CloneID: number;
  Data: ModuleData;
}

export class ModuleResponse extends GeneralResponse {
  Data: ModuleData;
}

export class ItemData extends BaseRecord {
  AppItemID: number;
  Code: string;
  DeltaFields: string;
  Language: LanguageData = new LanguageData();
  Languages: LanguageData[] = [];
  Images: ImageData[] = [];
  Position: number;
  Status: number;
}

export class ItemModulesData extends ItemData {
  FlagIsTemplate: number;
}
