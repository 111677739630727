import { Injectable } from "@angular/core";
import { HttpService } from "src/app/core/services/http.service";
import { Observable } from "rxjs";
import { EndpointsService } from "../../../../../../core/services/endpoint.service";
import { LayoutScreenGetListParams, LayoutScreenGetListResponse } from "../models/layout-screen.model";

@Injectable()
export class LayoutScreenService extends EndpointsService {

  private readonly LAYOUT_SCREEN_GET_LIST: string = `${this.API_URL}/LayoutScreenGetList`;

  constructor(private httpService: HttpService) {
    super();
  }

  getLayoutScreenList(params: LayoutScreenGetListParams): Observable<LayoutScreenGetListResponse> {
    return this.httpService.post_authorized(this.LAYOUT_SCREEN_GET_LIST, params);
  }

}
