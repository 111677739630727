import { Injectable } from "@angular/core";

import { AppInfo } from "../../modules/my-apps/model/my-apps.model";
import { LastMetadataSet } from "../../shared/model/metadata.model";
import { SessionChangeService } from "../../shared/services/session-change.service";
import { CloneUtil } from "../../shared/utils/clone.utils";
import { UserLoginAction } from "../models/login.model";
import { OrganizationInfo } from "../models/organization-info.model";
import { SessionRoutingParams } from "../models/session-params.model";
import { UserInfo } from "../models/user-info.model";
import { StorageService } from "./storage.service";
import { NotificationHeaderData } from "src/app/modules/notifications/model/models";
import { AbstractModuleData } from "src/app/modules/my-apps/model/page-builder.model";
import { SystemLookupData } from "src/app/shared/model/system-lookup.model";
import { AppLanguageData, LanguageData } from "src/app/shared/components/menu/translation-menu-block/language-model/language-model.model";

@Injectable()
export class SessionStorageService {
  private readonly userKey: string = "user";
  private readonly organizationInfoKey: string = "organizationInfo";
  private readonly sessionKey: string = "sessionId";
  private readonly needToLoginKey: string = "needToLogin";
  private readonly languageCodeKey: string = "languageCode";
  private readonly userActionsKey: string = "userActions";
  private readonly sessionRoutParamsKey: string = "routeParams";
  private readonly selectedAppInfoKey: string = "selectedAppInfo";
  private readonly lastIdMetadataSet: string = "lastIdMetadataSet";
  private readonly lastMetadataSet: string = "lastMetadataSet";
  private readonly notificationData: string = "notificationData";
  private readonly isSideNavExpandedValue: string = "isSideNavExpanded";
  private readonly featuresList: string = "featuresList";
  private readonly lookupsList: string = "lookupsList";
  private readonly languageList: string = "languageList";
  private readonly appLanguageList: string = "appLanguageList";

  constructor(
    private storage: StorageService,
    private sessionChangeService: SessionChangeService
  ) { }

  // Session Id

  public setSessionId(sessionId: string) {
    this.storage.set(this.sessionKey, sessionId);
  }

  public getSessionId(): string {
    return <string>this.storage.get(this.sessionKey);
  }

  public removeSessionId() {
    this.storage.remove(this.sessionKey);
  }

  // User Info

  public setUserInfo(user: UserInfo) {
    this.storage.set(this.userKey, user);
  }

  public getUserInfo(): UserInfo {
    return <UserInfo>this.storage.get(this.userKey);
  }

  public removeUser() {
    this.storage.remove(this.userKey);
  }

  // Organization Info

  public setOrganizationInfo(organizationInfo: OrganizationInfo) {
    this.storage.set(this.organizationInfoKey, organizationInfo);
  }

  public getOrganizationInfo(): OrganizationInfo {
    return <OrganizationInfo>this.storage.get(this.organizationInfoKey);
  }

  public removeOrganizationInfo() {
    this.storage.remove(this.organizationInfoKey);
  }

  public getOrganizationName(): string {
    const organizationInfo = <OrganizationInfo>this.storage.get(this.organizationInfoKey);
    return organizationInfo ? organizationInfo.Name : "";
  }

  public getOrganizationId(): number {
    const organizationInfo = <OrganizationInfo>this.storage.get(this.organizationInfoKey);
    return organizationInfo ? organizationInfo.ID : undefined;
  }

  // Need To Login

  public setNeedToLogin() {
    this.storage.set(this.needToLoginKey, true);
  }

  public getNeedToLogin(): boolean {
    return <boolean>this.storage.get(this.needToLoginKey);
  }

  public removeNeedToLogin() {
    this.storage.remove(this.needToLoginKey);
  }

  // Language Code

  public setLanguageCode(languageCode: string) {
    this.sessionChangeService.notifyLanguageChange(languageCode);
    this.storage.set(this.languageCodeKey, languageCode);
  }

  public getLanguageCode(): string {
    return <string>this.storage.get(this.languageCodeKey);
  }

  public removeLanguageCode() {
    this.storage.remove(this.languageCodeKey);
  }

  public isAuthenticated(): boolean {
    return this.getUserInfo() != null;
  }

  // User Actions

  public setUserActions(userActions: UserLoginAction[]) {
    this.storage.set(this.userActionsKey, userActions);
  }

  public getUserActions(): UserLoginAction[] {
    return <UserLoginAction[]>this.storage.get(this.userActionsKey);
  }

  public removeUserActions() {
    this.storage.remove(this.userActionsKey);
  }

  // Routing Params

  public setRoutingParams(params: SessionRoutingParams) {
    const mergedParams: SessionRoutingParams = {
      ...this.getRoutingParams(),
      ...params
    };
    return this.storage.set(this.sessionRoutParamsKey, mergedParams);
  }

  public getRoutingParams() {
    return <SessionRoutingParams>this.storage.get(this.sessionRoutParamsKey);
  }

  public removeRoutingParams() {
    this.storage.remove(this.sessionRoutParamsKey);
  }

  // Selected AppInfo

  public setSelectedAppInfo(appInfo: AppInfo) {
    // clone the instance otherwise will be used the same instance on the notified side and on the notifier side and it has side effect if the instance is modified
    this.sessionChangeService.notifyAppInfoChange(CloneUtil.getClonedInstance(appInfo));
    return +this.storage.set(this.selectedAppInfoKey, appInfo);
  }

  public getSelectedAppInfo(): AppInfo {
    return <AppInfo>this.storage.get(this.selectedAppInfoKey);
  }

  public removeSelectedAppInfo() {
    this.storage.remove(this.selectedAppInfoKey);
  }

  // Metadata fields
  public setLastMetadataSet(lastIdMetadataSet: LastMetadataSet) {
    this.storage.set(this.lastMetadataSet, lastIdMetadataSet);
  }

  public getLastMetadataSet(): LastMetadataSet {
    return <LastMetadataSet>this.storage.get(this.lastMetadataSet);
  }

  public removeLastMetadataSet() {
    this.storage.remove(this.lastMetadataSet);
  }

  // ID fields metadata
  public setLastIdMetadataSet(lastIdMetadataSet: LastMetadataSet) {
    this.storage.set(this.lastIdMetadataSet, lastIdMetadataSet);
  }

  public getLastIdMetadataSet(): LastMetadataSet {
    return <LastMetadataSet>this.storage.get(this.lastIdMetadataSet);
  }

  public removeLastIdMetadataSet() {
    this.storage.remove(this.lastIdMetadataSet);
  }

  // Notification

  public setNotification(notification: NotificationHeaderData) {
    this.storage.set(this.notificationData, notification);
  }

  public getNotification(): NotificationHeaderData {
    return <NotificationHeaderData>this.storage.get(this.notificationData);
  }

  public removeNotification() {
    this.storage.remove(this.notificationData);
  }

  // Keep Side Menu Open

  public setIsSideNavExpandedValue(value: boolean) {
    this.storage.set(this.isSideNavExpandedValue, value);
  }

  public getIsSideNavExpandedValue(): boolean {
    return <boolean>this.storage.get(this.isSideNavExpandedValue);
  }

  public removeIsSideNavExpandedValue() {
    this.storage.remove(this.isSideNavExpandedValue);
  }

  // Features List
  public setFeaturesList(list: AbstractModuleData[]) {
    this.storage.set(this.featuresList, list);
  }

  public getFeaturesList(): AbstractModuleData[] {
    return <AbstractModuleData[]>this.storage.get(this.featuresList);
  }

  public removeFeaturesList() {
    this.storage.remove(this.featuresList);
  }

  public setLookupsList(list: SystemLookupData[]) {
    this.storage.set(this.lookupsList, list);
  }

  public getLookupsList(): SystemLookupData[] {
    return <SystemLookupData[]>this.storage.get(this.lookupsList);
  }

  public removeLookupsList() {
    this.storage.remove(this.lookupsList);
  }

  public setLanguageList(list: LanguageData[]) {
    this.storage.set(this.languageList, list);
  }

  public getLanguageList(): LanguageData[] {
    return <LanguageData[]>this.storage.get(this.languageList);
  }

  public removeLanguageList() {
    this.storage.remove(this.languageList);
  }

  public setAppLanguageList(list: AppLanguageData[]) {
    this.storage.set(this.appLanguageList, list);
  }

  public getAppLanguageList(): AppLanguageData[] {
    return <AppLanguageData[]>this.storage.get(this.appLanguageList);
  }

  public removeAppLanguageList() {
    this.storage.remove(this.appLanguageList);
  }

  public removeAll() {
    this.removeOrganizationInfo();
    this.removeUser();
    this.removeLanguageCode();
    this.removeNeedToLogin();
    this.removeSessionId();
    this.removeUserActions();
    this.removeRoutingParams();
    this.removeSelectedAppInfo();
    this.removeLastIdMetadataSet();
    this.removeLastMetadataSet();
    this.removeNotification();
    this.removeIsSideNavExpandedValue();
    // this.removeFeaturesList();
    this.removeLanguageList();
    this.removeAppLanguageList();
  }
}
