import { ResponseStatus } from "../enums/response-status.enum";
import { OrganizationInfo } from "./organization-info.model";
import { UserInfo } from "./user-info.model";

// TODO: when somebody has time they should change GeneralResponse to GenericGeneralResponse everywhere
export class GeneralResponse {
  Header: HeaderData;
  OrganizationInfo: OrganizationInfo;
  Status: ResponseStatus;
  UserInfo: UserInfo;
  UserMessage: string;
}

export class GenericGeneralResponse<T> extends GeneralResponse {
  Data: T;
}

export class GeneralListResponse extends GeneralResponse {
  StartPosition: number;
  EndPosition: number;
  TotalCount: number;
}

export class GenericGeneralListResponse<T> extends GeneralResponse {
  StartPosition: number;
  EndPosition: number;
  TotalCount: number;
  Data: T[] = [];
}

export class HeaderData {
  ServerProcessInfo: string;
  ServerProcessTime: string;
}

export class BaseRecord {
  ID: number;
  CreatedByName: string;
  CreatedDate: DateData = new DateData();
  ModifiedByName: string;
  ModifiedDate: DateData = new DateData();
}

export class LanguageData extends BaseRecord {
  Content: string;
  ContentPrivacy: string;
  Description: string;
  LanguageID: number;
  LanguageURL: string;
  Name: string;
  OwnerID: number;
  Title: string;
}

export class ImageData extends BaseRecord {
  Height: number;
  HorizontalResolution: number;
  ImageName: string;
  ImageTypeID: number;
  ImageTypeText: string;
  PublicURL: string;
  VerticalResolution: number;
  Width: number;
  AlphaFeature: number;
  AppID: number;
  AppItemID: number;
  Contents: ImageContent[] = [];
  Deleted: boolean;
  Drawable: string;
  FlagIsDrawable: number;
  FlagIsFree: number;
  FlagIsPremium: number;
  FlagIsAvatar: number;
  ImportFromImageID: number;
  Tags: string = "";
  TargetItemID: number;
}

export class ImageContent {
  Content: string = "";
  Density: number = 0;
  Filename: string = "";
  FlagIsDrawable: number = 0;
  ImageExt: string = "";
  PublicURL: string = "";

  constructor(imageSource: string) {
    this.Content = imageSource;
  }
}

export class DateData {
  Date: string;

  constructor(dateString?: string) {
    this.Date = dateString ? dateString : "";
  }
}

export class DateRangeData {
  StartDate: string;
  EndDate: string;

  constructor(startDate?: string, endDate?: string) {
    this.StartDate = startDate ? startDate : "";
    this.EndDate = endDate ? endDate : "";
  }
}
