import { Injectable } from "@angular/core";
import { AlphaTechServerMessage } from "../../shared/model/alphatech-server-message.model";

/**
 * Stores an alpha tech custom error which comes by api responses
 */
@Injectable({
  providedIn: "root"
})
export class AlphaTechServerErrorService {
  alphaTechServerMessage = new AlphaTechServerMessage();

  setUserMessageAndStatus(alphaTechServerMessage: AlphaTechServerMessage) {
    this.alphaTechServerMessage = alphaTechServerMessage;
  }

  resetUserMessageAndStatus() {
    this.alphaTechServerMessage.userMessage = null;
    this.alphaTechServerMessage.status = 0;
  }
}

