import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "activation-link-sent",
  templateUrl: "./activation-link-sent.component.html"
})
export class ActivationLinkSentComponent {

  @Input() emailAddress: string;
  @Output() onNavigateToLoginClick: EventEmitter<void> = new EventEmitter();

  constructor() { }

  navigateToLogin() {
    this.onNavigateToLoginClick.emit();
  }

}
