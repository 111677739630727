import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { ResponseStatus } from "../../core/enums/response-status.enum";

export class ToastrHelper {
  private error: string;
  private success: string;
  private warning: string;

  private toastrService: ToastrService;
  private translateService: TranslateService;

  constructor(toastrService: ToastrService, translateService: TranslateService) {
    this.toastrService = toastrService;
    this.translateService = translateService;

    this.translateService.get(["Error.Error", "Success.Success", "Error.Warning"]).subscribe(
      response => {
        this.error = response["Error.Error"];
        this.success = response["Success.Success"];
        this.warning = response["Error.Warning"];
      }
    );
  }

  public toastError(errorMessage) {
    this.showErrorToast(this.error, this.translateService.instant(errorMessage));
  }

  public toastPublishSucceeded() {
    this.showSuccessToast(this.success, this.translateService.instant("Success.SuccessPublish"));
  }

  public toastDeleteDisabled() {
    this.showErrorToast(this.warning, this.translateService.instant("Error.CannotDelete"));
  }

  public toastInvalidFields() {
    this.showErrorToast(this.error, this.translateService.instant("Validation.FieldsAreNotValid"));
  }

  public toastFillMandatoryFields() {
    this.showErrorToast(this.error, this.translateService.instant("Validation.FillMandatoryFields"));
  }

  public toastCopiedToClipboard() {
    this.showSuccessToast(this.success, this.translateService.instant("CopiedClipboard"));
  }

  public toastSelectTemplate() {
    this.showErrorToast(this.error, this.translateService.instant("SelectTemplate"));
  }

  public toastTheresIsNoImageSelected() {
    this.showErrorToast(this.error, this.translateService.instant("Validation.ThereIsNoImageSelected"));
  }

  public toastPublishFailed(message?: string) {
    if (!message) {
      message = this.translateService.instant("Error.CantPublish");
    }

    this.showErrorToast(this.error, message);
  }

  public showServerError(message: string) {
    this.showErrorToast(this.error, message);
  }

  public showServerSuccess(message: string) {
    this.showSuccessToast(this.success, message);
  }

  private showErrorToast(title: string, message: string) {
    setTimeout(() => {
      this.toastrService.error(message, title, {
        closeButton: true,
        positionClass: "toast-bottom-right"
      });
    });
  }

  private showSuccessToast(title: string, message: string) {
    setTimeout(() => {
      this.toastrService.success(message, title, {
        closeButton: true,
        positionClass: "toast-bottom-right"
      });
    });
  }
}
