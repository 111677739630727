import { Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";

import { languageDefaults } from "../core/consts/language-defaults";
import { routerParams } from "../core/consts/router-params";
import { RouterPath, routerPaths } from "../core/consts/router-paths";
import { ResponseStatus } from "../core/enums/response-status.enum";
import { OrganizationInfo } from "../core/models/organization-info.model";
import { UserInfo } from "../core/models/user-info.model";
import { EndpointsService } from "../core/services/endpoint.service";
import { HttpService } from "../core/services/http.service";
import { SessionStorageService } from "../core/services/session-storage.service";
import { SessionChangeService } from "../shared/services/session-change.service";
import { CreditUtils } from "../shared/utils/credit.utils";
import { LoggerUtil } from "../shared/utils/logger.util";
import { StringUtil } from "../shared/utils/string.utils";

@Injectable()
export class AppLoadService extends EndpointsService {

  private readonly checkAction = "Check";
  private readonly checkMeAction = "CheckMe";
  private readonly CHECK_URL: string = `${this.API_ACCOUNT_URL}/${this.checkAction}`;
  private readonly CHECK_ME_URL: string = `${this.API_ACCOUNT_URL}/${this.checkMeAction}`;

  // in case of register/ - claim account; change-password/ - forgot password
  private readonly skipLoginRedirectRoutPaths = [RouterPath.Register + "/", RouterPath.ActivateUser, RouterPath.ChangePassword + "/"];

  constructor(
    private httpService: HttpService,
    private sessionStorageService: SessionStorageService,
    private sessionChangeService: SessionChangeService,
    private injector: Injector
  ) {
    super();
  }

  initializeApp(): Promise<any> {
    LoggerUtil.log(this.constructor.name, "initializeApp()", "app load module before loading app module");

    if (!window.location.href.includes(routerPaths.Redirect) && !this.sessionStorageService.getSessionId()) {
      LoggerUtil.log(this.constructor.name, "initializeApp()", "first time, need to do check call");
      if (!this.skipLoginRedirect(window.location.href)) {
        this.sessionStorageService.setNeedToLogin();
        const returnUrl = `${window.location.origin}${routerPaths.Redirect}?${routerParams.SessionId}=SessionID`;
        const encodedReturnUrl = encodeURIComponent(returnUrl);
        window.location.replace(`${this.CHECK_URL}?q=${encodedReturnUrl}`);
        return this.generateWaitingPromise();
      }
    } else if (this.sessionStorageService.getSessionId()) {
      LoggerUtil.log(this.constructor.name, "initializeApp()", "we have sessionId, need to call checkMe");
      const promise = this.httpService.get(`${this.CHECK_ME_URL}/${this.sessionStorageService.getSessionId()}`)
        .toPromise()
        .then((settings: any) => {
          if (settings.OrganizationInfo) {
            const organizationInfo = <OrganizationInfo>settings.OrganizationInfo;
            if (organizationInfo) {
              this.sessionStorageService.setOrganizationInfo(organizationInfo);
              if (organizationInfo.AvailableLanguages && organizationInfo.DefaultLanguageID
                && organizationInfo.AvailableLanguages.find(item => item.ID == organizationInfo.DefaultLanguageID)) {
                this.sessionStorageService.setLanguageCode(organizationInfo.AvailableLanguages.find(item => item.ID == organizationInfo.DefaultLanguageID).Code);
              } else {
                this.sessionStorageService.setLanguageCode(languageDefaults.defaultLanguageCode);
              }
            }
          }

          if (this.sessionStorageService.getNeedToLogin()) {
            const router = this.injector.get(Router);
            this.sessionStorageService.removeNeedToLogin();
            this.sessionStorageService.removeUser();
            return router.navigate([routerPaths.Login]);
          } else if (settings.Status != ResponseStatus.Success) {
            if (!this.skipLoginRedirect(window.location.href)) {
              this.sessionStorageService.removeAll();
              window.location.reload();
              return this.generateWaitingPromise();
            }
          } else {
            const userInfo = <UserInfo>settings.UserInfo;
            const organizationInfo = <OrganizationInfo>settings.OrganizationInfo;
            if (userInfo) {
              this.sessionStorageService.setUserInfo(userInfo);
              this.sessionChangeService.notifyUserCreditChange(CreditUtils.getClientCreditCountByUser(userInfo));
              if (userInfo.LanguageID && organizationInfo.AvailableLanguages && organizationInfo.AvailableLanguages.find(item => item.ID == userInfo.LanguageID)) {
                this.sessionStorageService.setLanguageCode(organizationInfo.AvailableLanguages.find(item => item.ID == userInfo.LanguageID).Code);
              }
            }
          }
          return settings;
        }, reason => {
          LoggerUtil.log(this.constructor.name, "initializeApp()", "there was an error while executing checkMe:" + reason);
          const router = this.injector.get(Router);
          router.navigate([routerPaths.InternalServerError]);
          return this.generateWaitingPromise();
        });

      return promise;
    } else { // on redirect
      const items = window.location.href.split(routerParams.SessionId + "=");
      if (items && items.length > 1) {
        this.sessionStorageService.setSessionId(items[1]);
        window.location.href = window.location.origin;
      }
      return this.generateWaitingPromise();
    }
  }

  // we need this to block the app to load until window.location.href or window.location.reload or window.location.replace otherwise will cause issues like unauthorize
  // to be displayed
  private generateWaitingPromise(): Promise<any> {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, 10000);
    });
  }

  /**
   * Check the route path that is not the case when has to skip the login redirect
   * @param routePath route path
   */
  private skipLoginRedirect(routePath: string) {
    return StringUtil.containsOneOfTheArrayElement(routePath, this.skipLoginRedirectRoutPaths);
  }

}
