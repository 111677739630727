import { BaseParams, Sorting, BaseListParams } from "../../core/models/base-requests.model";
import { GeneralListResponse, LanguageData } from "../../core/models/base-responses.model";

export class SystemLookupParams extends BaseListParams {
  Filter: SystemLookupFilter = new SystemLookupFilter();
}

export class SystemLookupFilter {
  Status: number;
  SystemLookupTypes: LookupType[];
}

export enum LookupType {
  ActionTypes = "ActionTypes",
  AddressTypes = "AddressTypes",
  AlphaFeatures = "AlphaFeatures",
  AlphaIntegrations = "AlphaIntegrations",
  AlphaProducts = "AlphaProducts",
  AppItemRestrictTypes = "AppItemRestrictTypes",
  AppItemSubscriptionRestrictTypes = "AppItemSubscriptionRestrictTypes",
  AppItemVisibilityTypes = "AppItemVisibilityTypes",
  CallbackTypes = "CallbackTypes",
  ClientTypes = "ClientTypes",
  CustomElementTypes = "CustomElementTypes",
  CultureInfos = "CultureInfoTypes",
  CheckoutOptionStatuses = "CheckoutOptionStatuses",
  CheckoutOptions = "CheckoutOptions",
  DataSourceTypes = "DataSourceTypes",
  DeltaRefreshTypes = "DeltaRefreshTypes",
  DeltaMappingTypes = "DeltaMappingTypes",
  DeltaValueTypes = "DeltaValueTypes",
  OutboundMessageValueTypes = "OutboundMessageValueTypes",
  DensityTypes = "DensityTypes",
  DistanceUnits = "DistanceUnits",
  EmailSettingTypes = "EmailSettingTypes",
  EventTypes = "EventTypes",
  FileTypes = "FileTypes",
  GeofencingActionTypes = "GeofencingActionTypes",
  GuestRelationships = "GuestRelationships",
  GuestTypes = "GuestTypes",
  ImageTypes = "ImageTypes",
  InputVisibilityTypes = "InputVisibilityTypes",
  InputTypes = "InputTypes",
  InputNameTypes = "InputNameTypes",
  InputPasswordTypes = "InputPasswordTypes",
  InputDateTypes = "InputDateTypes",
  InputFacebookLoginTypes = "InputFacebookLoginTypes",
  InputCustomerCodeTypes = "InputCustomerCodeTypes",
  IntervalTypes = "IntervalTypes",
  LayoutDimensionUnitTypes = "LayoutDimensionUnitTypes",
  LayoutEllipsisTypes = "LayoutEllipsisTypes",
  LayoutGravityTypes = "LayoutGravityTypes",
  LayoutImageViewScaleTypes = "LayoutImageViewScaleTypes",
  LayoutInputTypes = "LayoutInputTypes",
  LayoutScreenDensities = "LayoutScreenDensities",
  LayoutScreenOrientations = "LayoutScreenOrientations",
  LayoutScreenSizes = "LayoutScreenSizes",
  LayoutScreenTypes = "LayoutScreenTypes",
  LayoutScrollbarStyleTypes = "LayoutScrollbarStyleTypes",
  LayoutTypefaceTypes = "LayoutTypefaceTypes",
  LayoutUnitActionTypes = "LayoutUnitActionTypes",
  LayoutUnitAutoSizeTypes = "LayoutUnitAutoSizeTypes",
  LayoutUnitDimensionTypes = "LayoutUnitDimensionTypes",
  LayoutUnitImageScaleTypes = "LayoutUnitImageScaleTypes",
  LayoutUnitLayoutManagerTypes = "LayoutUnitLayoutManagerTypes",
  LayoutUnitRuleTypes = "LayoutUnitRuleTypes",
  LayoutUnitTypes = "LayoutUnitTypes",
  LayoutValueTypes = "LayoutValueTypes",
  LayoutScreenKinds = "LayoutScreenKinds",
  MerchantAccounts = "MerchantAccounts",
  MobilePlatformTypes = "MobilePlatformTypes",
  ModuleAddonTypes = "ModuleAddonTypes",
  ModuleVisibilityTypes = "ModuleVisibilityTypes",
  MultipageItemTypes = "MultipageItemTypes",
  NewsFeedActionTypes = "NewsFeedActionTypes",
  NotificationRecipientTypes = "NotificationRecipientTypes",
  NotificationTypes = "NotificationTypes",
  NotificationItemTypes = "NotificationItemTypes",
  NotificationActionTypes = "NotificationActionTypes",
  NotificationRecipientType = "NotificationRecipientTypes",
  OrderStatusTypes = "OrderStatusTypes",
  OrderActionTypes = "OrderActionTypes",
  OrderFulfilmentTypes = "OrderFulfilmentTypes",
  OrderNotificationTypes = "OrderNotificationTypes",
  OwnerTypes = "OwnerTypes",
  PackageTypes = "PackageTypes",
  PackageVisibilityTypes = "PackageVisibilityTypes",
  PortalNotificationActionTypes = "PortalNotificationActionTypes",
  PortalNotificationRecipientTypes = "PortalNotificationRecipientTypes",
  PortalNotificationTypes = "PortalNotificationTypes",
  PushNotificationStatusTypes = "PushNotificationStatusTypes",
  ReactionTypes = "ReactionTypes",
  StatusCodes = "StatusCodes",
  TagViews = "TagViews",
  Timezones = "Timezones",
  TriggerActionTypes = "TriggerActionTypes",
  UserAppLevels = "UserAppLevels",
  UserChatTypes = "UserChatTypes",
  UserLevels = "UserLevels",
  UserLoginActionTypes = "UserLoginActionTypes",
  VoucherRedeemTypes = "VoucherRedeemTypes",
  VoucherTypes = "VoucherTypes"
}

export namespace LookupType {
  export const getName = (type: LookupType): string => {
    return LookupType[type];
  };
}

export enum ForceAllType {
  NotActivated = 0,
  Activated = 1
}

export class SystemLookupResponse extends GeneralListResponse {
  Data: SystemLookupData[];
}

export class SystemLookupData {
  ID: number;
  Code: string;
  Status: number;
  Language: LanguageData = new LanguageData();
  Languages: LanguageData[] = [];
  FlagIsDefault: number;
  LookupType: string;
  LookupValue: number;
  SystemLookupType: LookupType;
}
