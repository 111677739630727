export class ItemToDelete<T> {
  name: string;
  id: number;
  isDeleting: boolean = false;
  instance: T;

  constructor(itemId: number, itemName: string, instance?: T) {
    this.id = itemId;
    this.name = itemName;
    this.instance = instance;
  }
}
