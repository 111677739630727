import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { UserResetPasswordParams } from "../../../core/models/user-reset-password.model";
import { EndpointsService } from "../../../core/services/endpoint.service";
import { HttpService } from "../../../core/services/http.service";
import { PasswordUpdateModel, UserPasswordUpdateResponse } from "../change-password.model";

@Injectable()
export class ChangePasswordService extends EndpointsService {
  private readonly POST_USER_PASSWORD_UPDATE: string = `${this.API_URL}/UserPasswordUpdate`;
  private readonly POST_PASSWORD_UPDATE_AFTER_LOGIN: string = `${this.API_URL}/UserPasswordUpdateAfterLogin`;
  private readonly POST_USER_PASSWORD_RESET: string = `${this.API_URL}/UserResetPassword`;

  constructor(
    private httpService: HttpService
  ) {
    super();
  }

  userPasswordUpdate(params: PasswordUpdateModel): Observable<UserPasswordUpdateResponse> {
    return this.httpService.post(this.POST_USER_PASSWORD_UPDATE, params);
  }

  updatePasswordAfterLogin(params: PasswordUpdateModel): Observable<UserPasswordUpdateResponse> {
    return this.httpService.post(this.POST_PASSWORD_UPDATE_AFTER_LOGIN, params);
  }

  resetUserPassword(params: UserResetPasswordParams) {
    return this.httpService.post(this.POST_USER_PASSWORD_RESET, params);
  }

}
