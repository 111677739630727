import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ItemToDelete } from "./item-to-delete";

@Component({
  selector: "confirm-delete-modal",
  templateUrl: "./confirm-delete-modal.component.html"
})
export class ConfirmDeleteModalComponent {
  @Input() itemToDelete: ItemToDelete<any>;
  @Input() confirmDeleteDescription: string;
  @Output() yesCallback: EventEmitter<void> = new EventEmitter();
  @Output() noCallback: EventEmitter<void> = new EventEmitter();

  getConfirmDeleteDescription(): string {
    return this.confirmDeleteDescription ? this.confirmDeleteDescription : "ConfirmDeleteDescription";
  }
}
