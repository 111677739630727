import { UserLoginAction } from "src/app/core/models/login.model";
import { Router } from "@angular/router";
import { RouterPath } from "src/app/core/consts/router-paths";
import { SessionStorageService } from "src/app/core/services/session-storage.service";
import { UserLoginActionType } from "src/app/core/enums/response-status.enum";
import { ArrayUtils } from "src/app/shared/utils/array.utils";
import { AuthService } from "src/app/core/services/auth.service";

export class LoginActions {
  protected actions: UserLoginAction[];
  protected actionIndex: number;
  protected actionInProgress: UserLoginAction;

  constructor(
    private router: Router,
    private sessionStorage: SessionStorageService,
    private authenticationService: AuthService,
    private actionType: UserLoginActionType
  ) {
    // get from storage the current action type. If it doesn't exists then go to dashboard
    this.actions = this.sessionStorage.getUserActions();
    if (this.actions && this.actions.length > 0) {
      this.actionIndex = this.actions.findIndex(item => item.UserLoginActionType == actionType);
      this.actionInProgress = this.actions[this.actionIndex];
      if (!this.actionInProgress) {
        this.goToDashboard();
      }
    } else {
      this.goToDashboard();
    }
  }

  public logout(event: any) {
    event.preventDefault();
    this.authenticationService.logout();
  }

  protected removeActionFromStorage() {
    if (this.actionIndex >= 0) {
      ArrayUtils.removeItemByIndex(this.actions, this.actionIndex);
      if (this.actions.length > 0) {
        this.sessionStorage.setUserActions(this.actions);
      } else {
        this.sessionStorage.removeUserActions();
      }
      this.authenticationService.actionSubmit(this.actionType).subscribe();
    }
  }

  protected goToDashboard() {
    this.router.navigate([RouterPath.Dashboard]);
  }
}
