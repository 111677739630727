import { Component, Input } from "@angular/core";

@Component({
    selector: "success-message",
    templateUrl: "success-message.component.html"
})
export class SuccessMessageComponent {
    @Input()
    messageKey: string;

    @Input()
    show: boolean;

    constructor() { }
}
