import { Component, Output, EventEmitter, Input, TemplateRef } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { MiniDeletableTileType } from "./mini-deletable-tile.model";
import { ItemToDelete } from "src/app/shared/components/confirm-delete-modal/item-to-delete";
import { ModalUtils } from "src/app/shared/utils/modal.utils";

@Component({
  selector: "mini-deletable-tiles",
  templateUrl: "./mini-deletable-tiles.component.html"
})
export class MiniDeletableTilesComponent {
  @Input() data: string;
  @Input() type: MiniDeletableTileType;
  @Input() isTagIconVisible: boolean;
  @Output() onDelete: EventEmitter<string> = new EventEmitter();

  miniDeletableTileTypeEnum = MiniDeletableTileType;

  itemToDelete: ItemToDelete<any>;
  deleteConfirmationModalRef: BsModalRef;

  constructor(private modalService: BsModalService) { }

  showDeleteConfirmationModal(template: TemplateRef<any>) {
    this.itemToDelete = new ItemToDelete(-1, this.data);
    this.deleteConfirmationModalRef = ModalUtils.showModalIgnoringBackdropClick(this.modalService, template);
  }

  deleteBadge() {
    this.onDelete.emit(this.data);
    this.deleteConfirmationModalRef.hide();
  }
}
