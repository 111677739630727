import { AgmCoreModule } from "@agm/core";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { AlertModule, BsDropdownModule, ButtonsModule, TimepickerModule } from "ngx-bootstrap";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { ToastrModule } from "ngx-toastr";

import { AppImagesService } from "../modules/my-apps/components/app-images/app-images.service";
import { ImagePresenter } from "../modules/my-apps/presenters/app-images.presenter";
import { AutofocusDirective } from "./autofocus/autofocus.directive";
import { ActionsContextMenuComponent } from "./components/actions-context-menu/actions-context-menu.component";
import { ActivationLinkSentComponent } from "./components/activation-link-sent/activation-link-sent.component";
import { ConfirmDeleteModalComponent } from "./components/confirm-delete-modal/confirm-delete-modal.component";
import { ConfirmPublishModalComponent } from "./components/confirm-publish-modal/confirm-publish-modal.component";
import { IconComponent } from "./components/icon/icon.component";
import { AlphaTechImageUploaderModalComponent } from "./components/image-uploaders/alpha-tech-image-uploader-modal/alpha-tech-image-uploader-modal.component";
import { AlphaTechImageUploaderComponent } from "./components/image-uploaders/alpha-tech-image-uploader/alpha-tech-image-uploader.component";
import { ImageUploaderComponent } from "./components/image-uploaders/image-uploader/image-uploader.component";
import { MultipleImageUploaderComponent } from "./components/image-uploaders/multiple-image-uploader/multiple-image-uploader.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { FullscreenModalWrapperComponent } from "./components/modals/fullscreen-modal-wrapper/fullscreen-modal-wrapper.component";
import { ModalWrapperComponent } from "./components/modals/modal-wrapper/modal-wrapper.component";
import { SearchInputComponent } from "./components/search-input/search-input.component";
import { SelectableTileComponent } from "./components/selectable-tile/selectable-tile.component";
import { UrlStatusComponent } from "./components/url-status/url-status.component";
import { PhonePipe } from "./pipes/phone.pipe";
import { SafePipe } from "./pipes/safe.pipe";
import { XmlPipe } from "./pipes/xml.pipe";
import { IconPresenter } from "./presenters/icon-presenter";
import { LookupPresenter } from "./presenters/lookup.presenter";
import { MetadataPresenter } from "./presenters/metadata.presenter";
import { IconService } from "./services/icon.service";
import { LookupService } from "./services/lookup.service";
import { MetadataService } from "./services/metadata.service";
import { SvgDefinitionsComponent } from "./svg-definitions/svg-definitions.component";
import { SvgIconComponent } from "./svg-definitions/svg-icon/svg-icon.component";
import { TranslateRootModule } from "./translate/translate-root.module";
import { HasErrorDirective } from "./validation/has-error.directive";
import { ServerValidationMessageComponent } from "./validation/server-validation-message/server-validation-message.component";
import { SuccessMessageComponent } from "./validation/success-message/success-message.component";
import { ValidationHelperComponent } from "./validation/validation-helper.component";
import { ValidationMessageComponent } from "./validation/validation-message/validation-message.component";
import { GeneralMenuBlockComponent } from "./components/menu/general-menu-block/general-menu-block.component";
import { GoogleMapsLocationPickerComponent } from "./components/google-maps-location-picker/google-maps-location-picker.component";
import { environment } from "src/environments/environment";
import { TimePickerDropdownComponent } from "./components/timepicker-dropdown/timepicker-dropdown.component";
import { CustomValidationMessageComponent } from "./validation/custom-validation-message/custom-validation-message.component";
import { MiniDeletableTilesComponent } from "./components/mini-deletable-tiles/mini-deletable-tiles.component";
import { NgxMaskModule } from "ngx-mask";
import { CopyToClipboardButtonComponent } from "./components/copy-to-clipboard-button/copy-to-clipboard-button.component";
import { LayoutScreenService } from "../modules/app-modules/common-elements/components/layout-screen/services/layout-screen.service";
import { TextSelectDirective } from "./components/text-select-directive/text-selection.directive";
import { CreatedUpdatedStatusInfoComponent } from "./components/created-updated-status-info/created-updated-status-info.component";
import { EscapeHtmlPipe } from "./pipes/html.pipe";
import { TranslationMenuBlockComponent } from "./components/menu/translation-menu-block/translation-menu-block.component";
import { LanguageService } from "./components/menu/translation-menu-block/language-service/language-service.service";
import { LanguagePresenter } from "./components/menu/translation-menu-block/language-presenter/language-presenter.presenter";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateRootModule,
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule,
    TooltipModule.forRoot(),
    ToastrModule.forRoot(),
    AlertModule.forRoot(),
    ButtonsModule.forRoot(),
    TimepickerModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: environment.Google_Maps_API
    }),
    NgxMaskModule.forRoot(),
    AngularEditorModule
  ],
  declarations: [
    ValidationMessageComponent,
    ServerValidationMessageComponent,
    CustomValidationMessageComponent,
    SuccessMessageComponent,
    LoaderComponent,
    HasErrorDirective,
    AutofocusDirective,
    ValidationHelperComponent,
    PhonePipe,
    SafePipe,
    XmlPipe,
    EscapeHtmlPipe,
    ActivationLinkSentComponent,
    ConfirmDeleteModalComponent,
    ConfirmPublishModalComponent,
    ActionsContextMenuComponent,
    SvgDefinitionsComponent,
    SvgIconComponent,
    ImageUploaderComponent,
    MultipleImageUploaderComponent,
    AlphaTechImageUploaderComponent,
    AlphaTechImageUploaderModalComponent,
    UrlStatusComponent,
    SearchInputComponent,
    SelectableTileComponent,
    MiniDeletableTilesComponent,
    FullscreenModalWrapperComponent,
    ModalWrapperComponent,
    IconComponent,
    GeneralMenuBlockComponent,
    GoogleMapsLocationPickerComponent,
    TimePickerDropdownComponent,
    CopyToClipboardButtonComponent,
    TextSelectDirective,
    CreatedUpdatedStatusInfoComponent,
    TranslationMenuBlockComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateRootModule,
    TooltipModule,
    ToastrModule,
    AlertModule,
    ButtonsModule,
    AngularEditorModule,
    NgxMaskModule,
    HasErrorDirective,
    AutofocusDirective,
    PhonePipe,
    SafePipe,
    XmlPipe,
    EscapeHtmlPipe,
    ValidationMessageComponent,
    ServerValidationMessageComponent,
    CustomValidationMessageComponent,
    SuccessMessageComponent,
    LoaderComponent,
    ValidationHelperComponent,
    ActivationLinkSentComponent,
    ConfirmDeleteModalComponent,
    ConfirmPublishModalComponent,
    ActionsContextMenuComponent,
    SvgDefinitionsComponent,
    SvgIconComponent,
    ImageUploaderComponent,
    MultipleImageUploaderComponent,
    AlphaTechImageUploaderComponent,
    AlphaTechImageUploaderModalComponent,
    UrlStatusComponent,
    SearchInputComponent,
    SelectableTileComponent,
    MiniDeletableTilesComponent,
    FullscreenModalWrapperComponent,
    ModalWrapperComponent,
    IconComponent,
    GeneralMenuBlockComponent,
    GoogleMapsLocationPickerComponent,
    TimePickerDropdownComponent,
    CopyToClipboardButtonComponent,
    TextSelectDirective,
    CreatedUpdatedStatusInfoComponent,
    TranslationMenuBlockComponent
  ],
  providers: [
    IconService,
    IconPresenter,
    LookupService,
    LookupPresenter,
    MetadataService,
    MetadataPresenter,
    AppImagesService,
    ImagePresenter,
    LayoutScreenService,
    LanguageService,
    LanguagePresenter
  ]
})
export class SharedModule { }
