import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class EndpointsService {

  protected readonly API_BASE_URL: string = environment.Api_Base_Path;
  protected readonly API_URL: string = environment.Api_Url;
  protected readonly API_ACCOUNT_URL: string = environment.Api_Account_Url;

  constructor() { }
}
