import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class ModuleLoadListenerService {

  public isLoading: boolean = false;
  public isLoadingChangeSubject = new BehaviorSubject<boolean>(null);

  notifyIsLoadingDataChanged(state: boolean) {
    this.isLoading = state;
    this.isLoadingChangeSubject.next(state);
  }

  getIsLoadingDataSubject(): BehaviorSubject<boolean> {
    return this.isLoadingChangeSubject;
  }
}
