import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { CloneUtil } from "src/app/shared/utils/clone.utils";
import { GenericListInterface, GenericSingleItemInterface, BoolItemInterface } from "./generic-listener.interface";

@Injectable()
export class GenericComponentListenerService<T> implements GenericListInterface<T>, GenericSingleItemInterface<T>, BoolItemInterface {
  private list: T[] = [];
  private singleItem: T = {} as T;
  private boolValue: boolean;
  private cachedList: T[] = [];

  private singleItemChangeSubject = new BehaviorSubject<T>({} as T);
  private listChangeSubject = new BehaviorSubject<T[]>([]);
  private boolValueChangeSubject = new BehaviorSubject<boolean>(false);

  notifyListChanged(data: T[]): void {
    this.list = CloneUtil.getClonedInstance(data);
    this.listChangeSubject.next(data);
  }

  getListSubject(): BehaviorSubject<T[]> {
    return this.listChangeSubject;
  }

  setListData(data: T[]) {
    this.list = CloneUtil.getClonedInstance(data);
  }

  getListData(): T[] {
    return this.list;
  }

  public getCachedList() {
    return this.cachedList;
  }

  public setCachedList(list: T[]) {
    this.cachedList = list;
  }

  notifySingleItemDataChanged(data: T) {
    this.setSingeItemData(data);
    this.singleItemChangeSubject.next(data);
  }

  getSingleItemDataSubject(): BehaviorSubject<T> {
    return this.singleItemChangeSubject;
  }

  setSingeItemData(data: T) {
    this.singleItem = CloneUtil.getClonedInstance(data);
  }

  getSingleItemData(): T {
    return this.singleItem;
  }

  notifyBoolValueDataChanged(data: boolean) {
    this.boolValue = data;
    this.boolValueChangeSubject.next(data);
  }

  getBoolValueDataSubject(): BehaviorSubject<boolean> {
    return this.boolValueChangeSubject;
  }

  setBooleanData(data: boolean) {
    this.boolValue = data;
  }

  getBooleanData(): boolean {
    return this.boolValue;
  }
}
