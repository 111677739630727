import { Component, Input } from "@angular/core";

@Component({
  selector: "server-validation-message",
  templateUrl: "server-validation-message.component.html"
})
export class ServerValidationMessageComponent {
  @Input() message: string;

  constructor() { }
}
