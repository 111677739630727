import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { SORTING_ID_DESC } from "../../core/models/base-requests.model";
import { IconGetListParams, IconGetListResponse, IconSetData, IconSetGetListParams, IconSetListResponse } from "../model/icons.model";
import { ForceAllType } from "../model/system-lookup.model";
import { IconService } from "../services/icon.service";
import { AlphaTechSubscribeUtils } from "../utils/alpha-tech-subscribe.utils";
import { AlphaTechResponseData, AlphaTechResponseDataList } from "../model/alphatech-response-data.model";

@Injectable({
  providedIn: "root"
})
export class IconPresenter {

  readonly ICON_PAGE_SIZE: number = 180;

  constructor(private iconService: IconService) { }

  getIconListByIconSet(pageNumber: number, iconSetId: number, searchText?: string): Observable<IconGetListResponse> {
    const params: IconGetListParams = new IconGetListParams();
    params.Sorting = SORTING_ID_DESC;
    params.Take = this.ICON_PAGE_SIZE;
    params.Position = pageNumber * this.ICON_PAGE_SIZE;
    params.Filter.IconSetID = iconSetId;

    if (searchText) {
      params.Filter.Keyword = searchText;
    }

    return this.iconService.getIconList(params);
  }

  getAllIconSetList(): Observable<AlphaTechResponseDataList<IconSetData>> {
    const params: IconSetGetListParams = new IconSetGetListParams();
    params.ForceAll = ForceAllType.Activated;

    return AlphaTechSubscribeUtils.subscribeDataListResponseHandling<IconSetData>(
      this.iconService.getIconSetList(params)
    );
  }
}
