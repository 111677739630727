import { LanguageData, GeneralListResponse } from "src/app/core/models/base-responses.model";
import { BaseParams } from "src/app/core/models/base-requests.model";
import { Icon } from "../../../shared/model/icons.model";
import { ContentBanks } from "../../app-modules/models/common.model";

export class AbstractModuleData {
  ID: number;
  Code: string;
  Language: LanguageData;
  MaxPerApp: number;
  Type: number;
  Icon: Icon;
}

export class MyAppModules extends GeneralListResponse {
  Data: AppModuleData[];
}

export class MyAppModuleUpdate extends BaseParams {
  Data: AppModuleData;
}

export class AppModuleData {
  ID: number;
  FeatureID: number;
  AppID: number;
  AppItemContentBanks: ContentBanks[] = [];
  AppItemVisibilityTypeID: number;
  AppItemVisibilityTypeName: string;
  NotificationHeaderID: number;
  NotificationEmails: string[] = [];
  IconID: number;
  FeatureIcons: Icon[];
  Language: AppModuleDataLanguage;
  Status: ModuleStatus;
  Position: number;
  Type: number;
  TagViewType: number;
  LayoutScreenID: number;
  LayoutScreenPrefix: string;
  LayoutScreenFieldName: string;
  LayoutScreenFieldQualifier: string;
  VoucherID: number;
  PartnerID: number;
  ShoppingAppItemID: number;
  AppInAppAppItemID: number;
}

export class AppModuleDataLanguage extends LanguageData {
  FeatureName: string;
}

export enum ModuleStatus {
  All = -1,
  Inactive = 0,
  Active = 1
}
