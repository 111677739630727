import { Component, Output, EventEmitter, Input, ElementRef, ViewChild } from "@angular/core";
import { ImageUtils } from "src/app/shared/utils/image.util";

const ALLOWED_IMAGE_TYPES = ["image/jpeg", "image/jpg", "image/png"];
const SHOW_MESSAGE_TIMEOUT = 5000;
const MIN_IMAGE_SIZE = 800;
// TODO: Max image size should be decided. This value is an approximately data
const MAX_IMAGE_SIZE = 1500;
@Component({
  selector: "multiple-image-uploader",
  templateUrl: "./multiple-image-uploader.component.html",
  styleUrls: ["./multiple-image-uploader.component.scss"]
})
export class MultipleImageUploaderComponent {
  @Input() imageSources: string[] = [];
  @Input() isImageValid: boolean;
  @Input() isImageHeaderOn: boolean = true;

  @Output() onUploadImage: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<number> = new EventEmitter();

  @ViewChild("fileInput") input: ElementRef;
  visibleImageDeleteField: string;

  readonly NO_IMAGE_PATH = "assets/images/no-image.png";

  constructor() { }

  uploadImage(event) {
    this.visibleImageDeleteField = null;
    const files = [].slice.call(event.target.files);

    // Uploaded image with validation
    files.forEach(file => {
      if (ALLOWED_IMAGE_TYPES.indexOf(file.type) != -1) {
        ImageUtils.getBase64Image(file, imageSource => {
          if (imageSource.width < MIN_IMAGE_SIZE && imageSource.height < MIN_IMAGE_SIZE && imageSource.width > MAX_IMAGE_SIZE && imageSource.height > MAX_IMAGE_SIZE) {
            this.validationMessageShow();
          } else {
            this.onUploadImage.emit(imageSource);
          }
        }, this.isImageHeaderOn);
      } else {
        this.validationMessageShow();
      }
    });
  }

  deleteImage(index: number, imgPath: string) {
    this.imageSources.splice(index, 1);
    this.onDelete.emit(index);
  }

  // TODO: clarify & implement the image validation
  validationMessageShow() {
    this.isImageValid = false;
    setTimeout(() => {
      this.isImageValid = true;
    }, SHOW_MESSAGE_TIMEOUT);
  }
}
