import { FormControl } from "@angular/forms";
const validCharacters = /[^\s\w,.:&\/()+%'`@-]/;

export class InternalCustomValidators {
  static noWhitespace = (control: FormControl) => {
    const tempVal = control.value + "";
    const isWhitespace = (tempVal).trim().length === 0;

    const isValid = !isWhitespace;

    return isValid ? null : { "required": true };
  }

  static validateCharacters = (control: FormControl) => {
    if (control.value && control.value.length > 0) {
      const matches = control.value.match(validCharacters);
      return matches && matches.length ? { invalid_characters: matches } : null;
    } else {
      return null;
    }
  }
}
