// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  App_Name: "alpha-tech-portal",
  Api_Base_Path: "https://stageserver.alphatech.technology",
  Api_Url: "https://stageserver.alphatech.technology/AlphaTech",
  Api_Account_Url: "https://stageserver.alphatech.technology/Accounts",
  QrCode_Url: "https://stageserver.alphatech.technology/Barcodes/GenerateQRCode",
  isLogging: true,
  isGeneralNavBarAlwaysVisible: true,
  Google_Maps_API: "AIzaSyBQbQxSnrg9eehXGhJ5jLYYewtt3m4YhTY",
  Ga_Tracking_Id: "UA-126545259-3"
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
