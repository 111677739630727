import { Injectable } from "@angular/core";

import { ModuleTileData } from "../../my-apps/components/page-builder/modules-list/module-tile/module-tile.model";
import { VisibilityLookup } from "../../../shared/model/lookup/visibility-lookup.model";
import { AppPresenter } from "../../my-apps/presenters/app.presenter";
import { VisibilityLookupChangeService } from "../../my-apps/services/visibility-lookup-change.service";
import { TagViews } from "../common-elements/components/general/tag-view/tag-view.model";
import { AppModuleData } from "../../my-apps/model/page-builder.model";
import { GenericSingleItemListenerService } from "src/app/shared/services/generic/generic-single-item-listener.service";
import { GeneralData } from "../common-elements/components/general/general-data.model";

@Injectable({
  providedIn: "root"
})
export class CommonModulesService {
  private moduleTileDataList: ModuleTileData[] = [];
  private selectedModuleTileData: ModuleTileData = new ModuleTileData();
  private visibilityLookups: VisibilityLookup[] = [];

  constructor(
    private appPresenter: AppPresenter,
    private visibilityLookupChangeService: VisibilityLookupChangeService
  ) {
    this.visibilityLookupChangeService.getSubject().subscribe(
      visibilityLookupChange => {
        if (visibilityLookupChange.isRestored) {
          this.visibilityLookups = visibilityLookupChange.visibilityLookups;
        }
      }
    );
  }

  onIsActiveChange(isActive: boolean) {
    // TODO: handle success and error
    this.appPresenter.updateAppModuleStatus(this.selectedModuleTileData.id, isActive).subscribe();
  }

  updateSelectedModuleVisibility(visibilityTypeId: number) {
    const selectedLookup: VisibilityLookup = this.visibilityLookups.find(lookup => lookup.id == visibilityTypeId);
    this.selectedModuleTileData.visibilityName = selectedLookup ? selectedLookup.title : "";
    this.selectedModuleTileData.visibilityTypeId = visibilityTypeId;
  }

  updateTagViews(tag: TagViews) {
    this.moduleTileDataList.forEach(data => {
      if (data.id == this.selectedModuleTileData.id) {
        data.tagView = tag;
      }
    });
  }

  setSelectedModuleTileData(moduleTileData: ModuleTileData) {
    this.selectedModuleTileData = moduleTileData;
  }

  getSelectedModuleTileData(): ModuleTileData {
    return this.selectedModuleTileData;
  }

  setModuleTileDataList(moduleTileDataList: ModuleTileData[]) {
    this.moduleTileDataList = moduleTileDataList;
  }

  getModuleTileDataList(): ModuleTileData[] {
    return this.moduleTileDataList;
  }

  getVisibilityLookupValue(visibilityTypeId: number): number {
    const visibilityLookupValue = this.visibilityLookups.find(item => item.id == visibilityTypeId);
    return visibilityLookupValue ? visibilityLookupValue.lookupValue : -1;
  }
}

@Injectable({
  providedIn: "root"
})
export class ModuleDataListener extends GenericSingleItemListenerService<AppModuleData> { }
