import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";

import { TranslateRootModule } from "../../../../shared/translate/translate-root.module";
import { BillingPresenter } from "../../presenters/billing.presenter";
import { BillingService } from "../../services/billing-service";
import { UserBillingModule } from "../user-billing/user-billing.module";
import { PurchaseMenuBlockComponent } from "./purchase-menu-block/purchase-menu-block.component";
import { PurchaseModalComponent } from "./purchase-modal/purchase-modal.component";
import { PurchasePackageTileComponent } from "./purchase-package-tile/purchase-package-tile.component";
import { PurchaseContentComponent } from "./purchase-content/purchase-content.component";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateRootModule,
    UserBillingModule
  ],
  declarations: [
    PurchaseModalComponent,
    PurchasePackageTileComponent,
    PurchaseMenuBlockComponent,
    PurchaseContentComponent
  ],
  exports: [
    PurchaseModalComponent,
    PurchasePackageTileComponent,
    PurchaseMenuBlockComponent,
    PurchaseContentComponent
  ],
  providers: [
    BillingService,
    BillingPresenter
  ]
})
export class PurchaseModule { }
