import { LanguageData, GeneralResponse, GeneralListResponse, DateData, ImageData } from "src/app/core/models/base-responses.model";
import { BaseParams, RequestFilter, BaseListParams } from "src/app/core/models/base-requests.model";
import { ThemeBundleData } from "../../themes/model/themes-bundle.model";
import { AppModuleData } from "./page-builder.model";
import { SystemLookupData } from "src/app/shared/model/system-lookup.model";
import { EditorData } from "src/app/shared/components/editors-list/model/editors-list.model";
import { AppLanguageData } from "src/app/shared/components/menu/translation-menu-block/language-model/language-model.model";

// ---------------------- Get App List ---------------------------

export class AppGetListParams extends BaseListParams {
  Filter: AppFilter = new AppFilter();
}

export class AppGetListResponse extends GeneralListResponse {
  Data: AppData[];
}

// ---------------------- Add App ---------------------------

export class AppAddParams extends BaseParams {
  CloneID: number;
  Data: AppData;

  constructor() {
    super();
    this.Data = new AppData();
  }
}

export class AppCloneParams extends BaseParams {
  CloneID: number;
}

// ---------------------- Update App ---------------------------

export class AppUpdateParams extends BaseParams {
  Data: AppData;

  constructor() {
    super();
    this.Data = new AppData();
  }
}

// ---------------------- My App ------------------------------

export class MyApp extends GeneralResponse {
  Data: AppData;
}

// ---------------------- Get App Modules List ----------------

export class GetAppModuleListParams extends BaseParams {
  Filter: AppModuleRequestFilter;
  Take: number;
}

export class GetAppModuleDefaultParams extends BaseParams {
  AppID: number;
  FeatureID: number;
}

export class AppModuleRequestFilter extends RequestFilter {
  FeatureType: number;
}


export class AddAppModulesListParams extends BaseParams {
  Data: AppModuleData[];

  constructor() {
    super();
    this.Data = [];
  }
}

// ---------------------- Get App Publish ---------------------------

export class AppPublishParams extends BaseParams {
  AppID: number;
}

export class AppPublishResponse extends GeneralResponse {
  App: AppPublishData;
}

// ---------------------- App Enforce Unique ---------------------------

export class AppEnforceUniqueParams extends BaseParams {
  AppID: number;
}

export class AppEnforceUniqueResponse extends GeneralResponse {
  App: AppData;
}

// ---------------------- App related ---------------------------

export class AppData extends GeneralResponse {
  BinaryFiles: BinaryFiles[];
  ID: number;
  CreatedByName: string;
  CreatedDate: DateData = new DateData();
  ModifiedByName: string;
  ModifiedDate: DateData = new DateData();
  Code: string;
  Status: number;
  Language: LanguageData = new LanguageData();
  Languages: LanguageData[] = [];
  AppConfigURL: string;
  AppIcon: ImageData = new ImageData();
  AppSplash: ImageData = new ImageData();
  AppItemCount: number;
  AppleCertificate: string;
  AppleCertificateContent: string;
  ApplePassword: string;
  AppleProvisioningProfile: string;
  AppleProvisioningProfileContent: string;
  BrandingID: number;
  Currency: string;
  CultureInfoName: string;
  CultureInfoID: number;
  DistanceUnitName: string;
  DistanceUnitID: number;
  DeltaFields: string;
  Sha1: string;
  FacebookIdentifier: string;
  FacebookClientToken: string;
  FacebookProtocolScheme: string;
  GoogleMapsIdentifier: string;
  FlagIsLive: number;
  FlagIsPremium: number;
  FlagIsAvatar: number;
  FlagIsTemplate: number;
  FlagIsTemplatePublic: number;
  FlagIsRealTime: number;
  FlagIsRealTimeSystem: number;
  FlagShowProgressBar: number;
  FlagUserCanDelete: number;
  FlagUserCanEdit: number;
  FlagIsSocialDiscoverable: number;
  FlagWebShopEnabled: number;
  NavigationIconType: number;
  DataRefreshInSeconds: number;
  GoogleServicesJson: string;
  GoogleAccountsJson: string;
  GoogleServicesPlist: string;
  InstallsCount: number;
  ThemeBundleCount: number;
  LayoutCount: number;
  OrganizationID: number;
  PreviewURL: string;
  PublishedDate: DateData = new DateData();
  RepositoryDate: DateData = new DateData();
  SupportEmail: string;
  SupportName: string;
  SupportPhone: string;
  SupportURL: string;
  TermsAndConditionID: number;
  ThemeBundle: ThemeBundleData;
  ThemeBundleID: number;
  TimezoneID: number;
  URLAppleStore: string;
  URLGoogleStore: string;
  URLMicrosoftStore: string;
  Version: string;
  Warnings: string[];
  WarningsCount: number;
  EditorContributors: EditorData[];
  EditorOwners: EditorData[];
  EditorSubscribers: EditorData[];
  WebShopHomepageURL: string;
  WebShopURL: string;
  FlagIsConfigProcessing: number;
  FlagShowDebug: number;
  FlagIgnoreRepository: number;
  FlagIgnoreLocalScreens: number;
  CommentCount: number;
  RatingCount: number;
  RatingValue: number;
  constructor() {
    super();
  }
}

export class AppFilter {
  Status: number;
  FlagIsLive: number;
  FlagIsPremium: number;
  FlagIsAvatar: number;
  FlagIsTemplate: number;
  OrganizationID: number;
  PackageID: number;
  Keyword: string;
}

// ---------------------- App Publish related ---------------------------

export class AppPublishData {
  CreatedByName: string;
  ID: number;
  ModifiedByName: string;
  Code: string;
  Status: number;
  Language: LanguageData;
  Languages: LanguageData[];
  AppConfigURL: string;
  AppIcon: string;
  AppSplash: string;
  AppleCertificate: string;
  ApplePassword: string;
  AppleProvisioningProfile: string;
  BrandingID: number;
  Currency: string;
  CultureInfoID: number;
  FacebookIdentifier: string;
  FacebookProtocolScheme: string;
  GoogleMapsIdentifier: string;
  FlagIsLive: number;
  FlagIsRealTime: number;
  FlagShowProgressBar: number;
  DataRefreshInSeconds: number;
  GoogleServicesJson: string;
  GoogleAccountsJson: string;
  GoogleServicesPlist: string;
  OrganizationID: number;
  SupportEmail: string;
  SupportName: string;
  SupportPhone: string;
  SupportURL: string;
  TermsAndConditionID: number;
  ThemeBundleID: number;
  TimezoneID: number;
  URLAppleStore: string;
  URLGoogleStore: string;
  URLMicrosoftStore: string;
  Version: string;
}

// ---------------------- App Info related ---------------------------

export class MyAppsInfo {
  appsAllCount: number;
  appInfoList: AppInfo[] = [];
}

export class AppInfo {
  id: number;
  appName: string;
  appCode: string;
  binaryFiles: BinaryFiles[];
  description: string;
  appPreviewLink: string;
  startMode: StartMode;
  acceptedTerms: boolean;
  timeZone: TimeZoneEntry;
  currency: CurrencyEntry;
  cultureInfo: SystemLookupData;
  distanceUnit: SystemLookupData;
  languageId: number;
  translationId: number;
  appIcon: ImageData = new ImageData();
  splashScreen: ImageData = new ImageData();
  themeBundleId: number;
  themeBundle: ThemeBundleData;
  version: string;
  createdBy: string;
  warningsCount: number;
  warnings: string[];
  installsCount: number;
  themeBundleCount: number;
  layoutCount: number;
  publishedDate: string;
  repositoryDate: string;
  createdDate: string;
  updatedDate: string;
  appItemCount: number;
  googlePlayLink: string;
  isRealTime: boolean;
  isRealTimeSystem: boolean;
  isTemplate: boolean;
  isTemplatePublic: boolean;
  isSocialDiscoverable: boolean;
  isWebshopEnabled: boolean;
  navigationIconType: number;
  canUserDelete: boolean;
  canUserEdit: boolean;
  templateId: number;
  showProgressBar: boolean;
  dataRefreshInSeconds: number;
  googleServicesJson: string;
  googleAccountsJson: string;
  googleServicesPlist: string;
  appleStoreLink: string;
  appleCertificationContent: string;
  appleCertificationName: string;
  appleCertificationPassword: string;
  appleProvisioningProfileName: string;
  appleProvisioningProfileContent: string;
  sha1: string;
  facebookAppId: string;
  facebookClientToken: string;
  facebookProtocolScheme: string;
  googleMapsId: string;
  adminName: string;
  adminEmail: string;
  adminPhone: string;
  adminURL: string;
  termsAndConditionsId: number;
  isPublished: boolean;
  isDraft: boolean;
  isPremium: boolean;
  isAvatar: boolean;
  isFilteredOut: boolean = false;
  isCloned?: boolean;
  editorContributors: EditorData[];
  editorOwners: EditorData[];
  editorSubscribers: EditorData[];
  deltaFields: string;
  webShopHomepageUrl: string;
  webShopUrl: string;
  languageList: AppLanguageData[] = [];
  languageDataList: LanguageData[] = [];
  isConfigProcessing: boolean = false;
  showDebug: boolean = false;
  ignoreRepository: boolean = false;
  ignoreLocalScreens: boolean = false;
  commentCount: number;
  ratingCount: number;
  ratingValue: number;

  constructor() {
    this.startMode = StartMode.StartFresh;
    this.timeZone = new TimeZoneEntry();
    this.currency = new CurrencyEntry();
    this.cultureInfo = new SystemLookupData();
    this.distanceUnit = new SystemLookupData();
  }
}

export enum StartMode {
  StartFresh,
  ConnectBlog
}

export class DropdownContent {
  translationPath: string;
  value: number;
}

// ---------------------- App Config ---------------------------

export class AppConfigGetParams extends BaseParams {
  AppID: number;
  Version: string;
}

export class AppConfigResponse extends GeneralResponse {
  AppConfig: any;
}

// ---------------------- Time zone ---------------------------

export class TimeZoneGetListResponse extends GeneralResponse {
  Data: TimeZoneEntry[];
  TotalCount: number;
  StartPosition: number;
  EndPosition: number;
}

export class TimeZoneEntry {
  ID: number;
  Name: string;
}

// ---------------------- Currency ---------------------------

export class CurrencyGetListResponse extends GeneralResponse {
  Data: CurrencyEntry[];
  TotalCount: number;
  StartPosition: number;
  EndPosition: number;
}

export class CurrencyEntry {
  Code: string;
  Name: string;
}

export class BinaryFiles {
  Name: string;
  URL: string;
}
