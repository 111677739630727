import { IconFontFamilyEnum } from "../../model/tile-base.model";

export class IconMeta {
  id: number;
  name: string;
  fontFamily: string;

  constructor(id?: number, name?: string, fontFamily?: string) {
    this.id = id ? id : -1;
    this.name = name ? name : "";
    this.fontFamily = fontFamily ? fontFamily : "";
  }
}
