import { ImageWrapper } from "../model/image-wrapper";
import { AlphaTechImageToUpload } from "../components/image-uploaders/alpha-tech-image-to-upload.model";
import { ImageData } from "../../core/models/base-responses.model";

export class ImageUtils {
  public static readonly BASE64 = "base64";
  public static readonly availableImageFormats: string[] = ["png", "jpg", "jpeg", "gif", "bmp", "tiff", "raw", "mp4"];

  public static getBase64Image(file: File, imageLoadedCallback: Function, withHeader: boolean = false): void {
    if (file) {
      const fileReader: FileReader = new FileReader();
      fileReader.onloadend = () => {
        let image: string = <string>fileReader.result;
        const img = new Image();
        const fileExt = "." + image.split(";")[0].split("/")[1];

        img.onload = () => {
          let index: string | number;
          if (!withHeader) {
            index = image.indexOf(this.BASE64);
            image = image.substring(index + 7);
          } else {
            index = image;
          }

          imageLoadedCallback(
            new ImageWrapper(image, img.width, img.height, file.name.replace(fileExt, ""))
          );
        };

        img.src = image;
      };
      fileReader.readAsDataURL(file);
    }
  }

  public static removeBase64Header(imageSource: string): string {
    const index = imageSource.indexOf(this.BASE64);
    return imageSource.substring(index + 7);
  }

  public static getImageSource(imageToUpload: AlphaTechImageToUpload): any {
    return imageToUpload.newImageSource ? imageToUpload.newImageSource.image : imageToUpload.imageData.PublicURL;
  }

  public static getImageUrl(imageData: ImageData, forceCacheReload?: boolean): any {
    let result: string;

    // if there are contents, then take the last one (that one has the best resolution)
    if (imageData && imageData.Contents) {
      const url: string = imageData.Contents[imageData.Contents.length - 1].PublicURL;
      result = forceCacheReload ? url + "?" + Date.now() : url;
    }

    return result;
  }

  public static buildImageData(imageToUpload: AlphaTechImageToUpload, oldImage?: ImageData): ImageData {
    const imageToSet = new ImageData();
    imageToSet.ID = oldImage ? oldImage.ID : undefined;
    imageToSet.Contents.push(imageToUpload.imageData.Contents[0]);
    imageToSet.Tags = imageToUpload.imageData.Tags;

    // drawable
    imageToSet.FlagIsDrawable = imageToUpload.imageData.FlagIsDrawable;
    imageToSet.Drawable = imageToUpload.imageData.Drawable;
    imageToSet.ImageName = imageToUpload.imageData.ImageName;

    // from other app
    imageToSet.ImportFromImageID = imageToUpload.imageData.ImportFromImageID;

    return imageToSet;
  }

}
