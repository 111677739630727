import { Component, Injector, OnInit, TemplateRef } from "@angular/core";

import { BaseComponent } from "../../../../../shared/components/base-components/base-component";
import { SessionChangeService } from "src/app/shared/services/session-change.service";
import { BillingPresenter } from "../../../presenters/billing.presenter";

@Component({
  selector: "purchase-menu-block",
  templateUrl: "./purchase-menu-block.component.html",
  styleUrls: ["./purchase-menu-block.component.scss"]
})
export class PurchaseMenuBlockComponent extends BaseComponent implements OnInit {
  clientCreditCount: number = 0;

  constructor(
    private sessionChangeService: SessionChangeService,
    private billingPresenter: BillingPresenter,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.listenSessionUserCreditChange();
    this.getPackageSubscriptionList();
  }

  onPurchaseClick(template: TemplateRef<any>) {
    this.bsModalRef = this.showModalInFullScreen(template);
  }

  onPurchaseConfirmed() {
    this.bsModalRef.hide();
  }

  onPurchaseCancelClick() {
    this.bsModalRef.hide();
  }

  private listenSessionUserCreditChange() {
    this.subscriptions.push(
      this.sessionChangeService.getSessionUserCreditSubject().subscribe(
        sessionUserCreditChange => {
          if (sessionUserCreditChange.isRestored) {
            this.clientCreditCount = sessionUserCreditChange.creditCount;
          }
        }
      ));
  }

  private getPackageSubscriptionList() {
    this.presenterRequestHandler(
      this.billingPresenter.getPackageSubscriptionListSummary(true),
    ).subscribe(
      response => {
        this.clientCreditCount = response.summary.AppCount;
      }
    );
  }
}
