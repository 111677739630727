import { Injectable } from "@angular/core";
import { HttpService } from "src/app/core/services/http.service";
import { Observable } from "rxjs";
import { IconSetGetListParams, IconSetListResponse, IconGetListParams, IconGetListResponse } from "../model/icons.model";
import { EndpointsService } from "src/app/core/services/endpoint.service";

@Injectable({
  providedIn: "root"
})
export class IconService extends EndpointsService {
  private readonly POST_ICON_GET: string = `${this.API_URL}/IconGet`;
  private readonly POST_ICON_ADD: string = `${this.API_URL}/IconAdd`;
  private readonly POST_ICON_Delete: string = `${this.API_URL}/IconDelete`;

  private readonly POST_ICON_GET_LIST: string = `${this.API_URL}/IconGetList`;

  private readonly POST_ICON_SET_GET: string = `${this.API_URL}/IconSetGet`;
  private readonly POST_ICON_SET_GET_LIST: string = `${this.API_URL}/IconSetGetList`;
  private readonly POST_ICON_SET_UPDATE: string = `${this.API_URL}/IconSetUpdate`;

  constructor(
    private httpService: HttpService
  ) {
    super();
  }

  getIconList(params: IconGetListParams): Observable<IconGetListResponse> {
    return this.httpService.post_authorized(this.POST_ICON_GET_LIST, params);
  }

  getIconSetList(params: IconSetGetListParams): Observable<IconSetListResponse> {
    return this.httpService.post_authorized(this.POST_ICON_SET_GET_LIST, params);
  }

}
