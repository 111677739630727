import { Component } from "@angular/core";
import { RouterPath } from "src/app/core/consts/router-paths";
import { Router } from "@angular/router";

@Component({
  templateUrl: "unauthorized.component.html"
})

export class UnauthorizedComponent {
  constructor(private router: Router) { }

  backHome() {
    this.router.navigate([RouterPath.Dashboard]);
  }
}
