import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";

import { ResponseStatus } from "../../core/enums/response-status.enum";
import { SessionStorageService } from "../../core/services/session-storage.service";
import { LastMetadataSet, MetadataData } from "../model/metadata.model";
import { MetadataService } from "../services/metadata.service";
import { AlphaTechSubscribeUtils } from "../utils/alpha-tech-subscribe.utils";
import { AlphaTechResponseDataList } from "../model/alphatech-response-data.model";
import { AlphaTechServerMessage } from "../model/alphatech-server-message.model";
import { CompressService } from "../services/compress.service";

@Injectable({
  providedIn: "root"
})
export class MetadataPresenter {
  readonly CANNOT_ACCESS_METADATA_LAST_UPDATED_DATE = "Cannot access metadata last updated date";
  readonly CANNOT_ACCESS_METADATA = "Cannot access metadata";

  constructor(
    private metadataService: MetadataService,
    private sessionStorageService: SessionStorageService,
    private compressService: CompressService<MetadataData[]>
  ) { }

  /**
   * Returns the last updated metadata. If there is no existing instance in the session storage the will get
   * by the api. If there is stored session metadata, then checks the last updated metadata and if not the
   * last updated metadata is in the session, then will get by the api. Otherwise will return the stored one.
   */
  getMetadataList(idFieldsOnly: boolean = false): Observable<AlphaTechResponseDataList<MetadataData>> {
    return new Observable(observer => {
      let lastMetadataSet: LastMetadataSet;
      if (idFieldsOnly) {
        lastMetadataSet = this.sessionStorageService.getLastIdMetadataSet();
      } else {
        lastMetadataSet = this.sessionStorageService.getLastMetadataSet();
      }

      this.metadataService.getMetadataLastUpdate().subscribe(response => {
        const alphaTechResponseData = new AlphaTechResponseDataList<MetadataData>();
        const serverMessage: AlphaTechServerMessage = AlphaTechServerMessage.isAlphaTechResponse(response) ? new AlphaTechServerMessage(response) : undefined;
        alphaTechResponseData.serverMessage = serverMessage;

        if (response.Status == ResponseStatus.Success) {
          if (lastMetadataSet && response.LastUpdated && response.LastUpdated.Date == lastMetadataSet.lastUpdated.Date) {
            if (idFieldsOnly) {
              alphaTechResponseData.dataList = lastMetadataSet.metadataDataList;
            } else {
              alphaTechResponseData.dataList = this.compressService.decompress(lastMetadataSet.metadataDataList);
            }
            observer.next(alphaTechResponseData);
            observer.complete();
          } else {
            this.getMetadataListObservableWithResponseHandling(idFieldsOnly).subscribe(
              metadataListResponse => {
                lastMetadataSet = new LastMetadataSet();
                lastMetadataSet.lastUpdated.Date = response.LastUpdated.Date;
                lastMetadataSet.metadataDataList = metadataListResponse.dataList;
                if (idFieldsOnly) {
                  this.sessionStorageService.setLastIdMetadataSet(lastMetadataSet);
                } else {
                  lastMetadataSet.metadataDataList = this.compressService.compress(metadataListResponse.dataList);
                  this.sessionStorageService.setLastMetadataSet(lastMetadataSet);
                }
                alphaTechResponseData.dataList = metadataListResponse.dataList;
                observer.next(alphaTechResponseData);
                observer.complete();
              },
              error => {
                observer.error(this.CANNOT_ACCESS_METADATA);
              });
          }
        } else {
          observer.error(this.CANNOT_ACCESS_METADATA_LAST_UPDATED_DATE);
        }
      });
    });
  }

  private getMetadataListObservableWithResponseHandling(idFieldsOnly: boolean): Observable<AlphaTechResponseDataList<MetadataData>> {
    return AlphaTechSubscribeUtils.subscribeDataListResponseHandling<MetadataData>(
      this.metadataService.getMetadataList(idFieldsOnly)
    );
  }
}
