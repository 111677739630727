import { Observable } from "rxjs";

import { Injectable } from "@angular/core";

import { VisibilityLookup } from "../model/lookup/visibility-lookup.model";
import { LookupService } from "../../shared/services/lookup.service";
import { LookupType, SystemLookupData } from "../model/system-lookup.model";
import { AlphaTechSubscribeUtils } from "./../utils/alpha-tech-subscribe.utils";
import { AlphaTechResponseDataList } from "../model/alphatech-response-data.model";

@Injectable({
  providedIn: "root"
})
export class LookupPresenter {

  constructor(
    private lookupService: LookupService
  ) { }

  getVisibilityLookup(): Observable<AlphaTechResponseDataList<SystemLookupData>> {
    return AlphaTechSubscribeUtils.subscribeDataListResponseHandling(
      this.lookupService.getSystemLookup([LookupType.AppItemVisibilityTypes])
    );
    //   const visibilityLookups: VisibilityLookup[] = [];
    //   .subscribe(systemLookupResponse => {
    //     if (systemLookupResponse.Data) {
    //       systemLookupResponse.Data.forEach(lookup => {
    //         const visibilityLookup: VisibilityLookup = new VisibilityLookup();
    //         visibilityLookup.id = lookup.ID;
    //         visibilityLookup.lookupValue = lookup.LookupValue;
    //         visibilityLookup.title = lookup.Language.Title;
    //         visibilityLookup.description = lookup.Language.Description;

    //         visibilityLookups.push(visibilityLookup);
    //       });
    //     }

    //     observer.next(visibilityLookups);
    //     observer.complete();
    //   });
    // });
  }

  getSystemLookup(lookupType: LookupType): Observable<AlphaTechResponseDataList<SystemLookupData>> {
    return AlphaTechSubscribeUtils.subscribeDataListResponseHandling(
      this.lookupService.getSystemLookup([lookupType])
    );
  }

  getSystemLookups(lookupTypes: LookupType[]): Observable<AlphaTechResponseDataList<SystemLookupData>> {
    return AlphaTechSubscribeUtils.subscribeDataListResponseHandling(
      this.lookupService.getSystemLookup(lookupTypes)
    );
  }
}
