import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GeneralResponse } from "src/app/core/models/base-responses.model";
import { EndpointsService } from "src/app/core/services/endpoint.service";
import { HttpService } from "src/app/core/services/http.service";
import {
  AppAvailableAddParams, AppAvailableGetLanguageListParams, AppAvailableGetLanguageListParamsUpdate,
  AppAvailableGetLanguageListResponse, AppGetLanguageListParams,
  AppGetLanguageListResponse, LanguageGetListParams, LanguageGetListResponse, LanguageSetParams
} from "../language-model/language-model.model";

@Injectable({
  providedIn: "root"
})
export class LanguageService extends EndpointsService {
  private readonly POST_LANGUAGE_GET_LIST: string = `${this.API_URL}/OrganizationLanguageGetList`;
  private readonly POST_APP_LANGUAGE_GET_LIST: string = `${this.API_URL}/LanguageGetList`;
  private readonly POST_APP_AVAILABLE_LANGUAGE_GET_LIST: string = `${this.API_URL}/AppAvailableLanguageGetList`;
  private readonly POST_APP_AVAILABLE_LANGUAGE_ADD_LIST: string = `${this.API_URL}/AppAvailableLanguageAddList`;
  private readonly POST_APP_AVAILABLE_LANGUAGE_UPDATE_LIST: string = `${this.API_URL}/AppAvailableLanguageUpdateList`;
  private readonly POST_LANGUAGE_SET: string = `${this.API_URL}/LanguageSet`;

  constructor(
    private httpService: HttpService
  ) {
    super();
  }

  getLanguageList(params: LanguageGetListParams): Observable<LanguageGetListResponse> {
    return this.httpService.post_authorized(this.POST_LANGUAGE_GET_LIST, params);
  }

  getAppLanguageList(params: AppGetLanguageListParams): Observable<AppGetLanguageListResponse> {
    return this.httpService.post_authorized(this.POST_APP_LANGUAGE_GET_LIST, params);
  }

  getAppAvailableLanguageList(params: AppAvailableGetLanguageListParams): Observable<AppAvailableGetLanguageListResponse> {
    return this.httpService.post_authorized(this.POST_APP_AVAILABLE_LANGUAGE_GET_LIST, params);
  }

  addAppAvailableLanguageList(params: AppAvailableAddParams): Observable<AppAvailableGetLanguageListResponse> {
    return this.httpService.post_authorized(this.POST_APP_AVAILABLE_LANGUAGE_ADD_LIST, params);
  }

  updateAppAvailableLanguageList(params: AppAvailableGetLanguageListParamsUpdate): Observable<AppAvailableGetLanguageListResponse> {
    return this.httpService.post_authorized(this.POST_APP_AVAILABLE_LANGUAGE_UPDATE_LIST, params);
  }

  setLanguage(params: LanguageSetParams): Observable<GeneralResponse> {
    return this.httpService.post_authorized(this.POST_LANGUAGE_SET, params);
  }
}
