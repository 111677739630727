import { Component, OnDestroy } from "@angular/core";
import { routerPaths } from "src/app/core/consts/router-paths";
import { Subscription } from "rxjs";
import { SubscriptionUtil } from "src/app/shared/utils/subscription.utils";
import { ModuleLoadListenerService } from "src/app/app-load/module-load-listener.service";

@Component({
  templateUrl: "dashboard.component.html"
})
export class DashboardComponent implements OnDestroy {
  routerPaths = routerPaths;
  subscriptions: Subscription[] = [];

  constructor(public moduleLoadChangeListener: ModuleLoadListenerService) {
    this.moduleLoaderListener();
  }

  ngOnDestroy(): void {
    SubscriptionUtil.unsubscribe(this.subscriptions);
  }

  private moduleLoaderListener() {
    this.subscriptions.push(this.moduleLoadChangeListener.getIsLoadingDataSubject().subscribe(response => {
      this.moduleLoadChangeListener.isLoading = response;
    }));
  }
}
