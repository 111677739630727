import { GeneralResponse } from "./base-responses.model";
import { BaseParams } from "./base-requests.model";

export class UserAuthCodeCheckParams extends BaseParams {
  ActivationCode: string;

  constructor(activationCode: string, authToken?: string, languageId?) {
    super(authToken, languageId);
    this.ActivationCode = activationCode;
  }
}

export class UserAuthCodeCheckResponse extends GeneralResponse {
  IsValid: boolean;
  UserEmail: string;
  UserFirstName: string;
  UserLastName: string;
}
