import { Subscription } from "rxjs";

export class SubscriptionUtil {

  static unsubscribe(subscriptions: Subscription[]) {
    if (subscriptions) {
      subscriptions.forEach(subscription => subscription.unsubscribe());
    }
  }
}
