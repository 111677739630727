import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";

import { routerPaths } from "../consts/router-paths";
import { SessionStorageService } from "../services/session-storage.service";
import { UserLoginAction } from "../models/login.model";
import { UserLoginActionType } from "../enums/response-status.enum";

@Injectable()
export class AuthorizedGuard implements CanActivate {
  constructor(
    private router: Router,
    private sessionStorageService: SessionStorageService
  ) { }

  canActivate() {
    const actions: UserLoginAction[] = this.sessionStorageService.getUserActions();

    if (actions && actions.length > 0) {
      const action = actions[0];
      if (action.FlagIsMandatory) {
        switch (action.UserLoginActionType) {
          case UserLoginActionType.PasswordChange:
            this.router.navigate([routerPaths.ActionChangePassword]);
            return false;

          case UserLoginActionType.ConsentRequired:
            this.router.navigate([routerPaths.ActionConsentRequired]);
            return false;
        }
      }
    }

    if (this.sessionStorageService.isAuthenticated()) {
      return true;
    }

    this.router.navigate([routerPaths.Unauthorized]);
    return false;
  }
}
