import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { RouterPath } from "src/app/core/consts/router-paths";
import { AlphaTechServerErrorService } from "./alphatech-server-error.service";
import { AlphaTechServerMessage } from "../../shared/model/alphatech-server-message.model";

@Component({
  templateUrl: "error.component.html"
})

export class ErrorPageComponent implements OnInit, OnDestroy {

  alphaTechServerMessage: AlphaTechServerMessage;

  constructor(
    public alphaTechServerErrorService: AlphaTechServerErrorService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.alphaTechServerMessage = this.alphaTechServerErrorService.alphaTechServerMessage;
  }

  ngOnDestroy(): void {
    this.alphaTechServerErrorService.resetUserMessageAndStatus();
  }

  backHome() {
    this.router.navigate([RouterPath.Dashboard]);
  }
}
