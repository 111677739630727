import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap";
import { Subscription } from "rxjs";

import { LoggerUtil } from "../../utils/logger.util";
import { SubscriptionUtil } from "../../utils/subscription.utils";
import { MapCoordinates, MapMarker, LocationMapCoordinates } from "./google-maps-location-picker.model";

@Component({
  selector: "google-maps-location-picker",
  templateUrl: "./google-maps-location-picker.component.html",
  styleUrls: ["google-maps-location-picker.component.scss"]
})
export class GoogleMapsLocationPickerComponent implements OnInit, OnDestroy {
  @Input() modalReference: BsModalRef;
  @Input() isPreview: boolean;
  @Input() mapMarker: MapMarker;
  @Output() onCoordinatesChanged: EventEmitter<MapCoordinates> = new EventEmitter();

  zoom: number;

  // Default coordinates of map in case of no access for user browser location data
  mapCoordinates: MapCoordinates = LocationMapCoordinates.Denver;
  markerCache: MapMarker = new MapMarker();
  marker: MapMarker = new MapMarker();

  subscriptions: Subscription[] = [];

  ngOnInit() {
    this.setMapMarker();
    this.getGeoLocationCurrentPosition();
    this.setZoom();
  }

  ngOnDestroy(): void {
    SubscriptionUtil.unsubscribe(this.subscriptions);
  }

  getGeoLocationCurrentPosition() {
    if (navigator.geolocation) {
      // This will grant access for browser location
      navigator.geolocation.getCurrentPosition(
        position => {
          this.mapCoordinates.lat = position.coords.latitude;
          this.mapCoordinates.lng = position.coords.longitude;
        },
        error => {
          LoggerUtil.log(this.constructor.name, error.message, error);
          LoggerUtil.log(
            this.constructor.name,
            "On http://localhost the geolocation.getCurrentPosition() works if in the browser the 'Insecure origins treated as secure'" +
            " is enabled with the corresponding url: http://localrebel1.alphatech.technology:4200"
          );
        }
      );
    }
  }

  onCancel() {
    this.closeModal();
  }

  closeModal() {
    this.modalReference.hide();
  }

  mapClicked($event: any) {
    this.marker.lat = $event.coords.lat;
    this.marker.lng = $event.coords.lng;
    this.mapCoordinates.lat = $event.coords.lat;
    this.mapCoordinates.lng = $event.coords.lng;
    this.marker.draggable = true;
  }

  markerDragEnd(marker: MapMarker, $event: any) {
    this.marker.lat = $event.coords.lat;
    this.marker.lng = $event.coords.lng;
    this.mapCoordinates.lat = $event.coords.lat;
    this.mapCoordinates.lng = $event.coords.lng;
  }

  onSave() {
    this.onCoordinatesChanged.emit(this.mapCoordinates);
    this.closeModal();
  }

  private setZoom() {
    this.zoom = this.isPreview ? 7 : 8;
  }

  private setMapMarker() {
    if (this.mapMarker && this.mapMarker.lat) {
      this.markerCache = this.mapMarker;
      this.marker = this.mapMarker;
      this.marker.draggable = true;
      this.mapCoordinates.lat = this.mapMarker.lat;
      this.mapCoordinates.lng = this.mapMarker.lng;
    }
  }
}
