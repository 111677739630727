import { FormGroup } from "@angular/forms/src/model";

export class FormValidationModel {
  constructor(public form: FormGroup) { }

  public hasError(controlName: string) {
    const error = this.form.controls[controlName].errors && (this.form.controls[controlName].dirty || this.form.controls[controlName].touched);
    return error ? true : false;
  }

  public isValid(controlName: string) {
    const error = this.form.controls[controlName].errors && (this.form.controls[controlName].dirty || this.form.controls[controlName].touched);
    return error ? false : true;
  }
}
