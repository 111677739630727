import { ModuleTileData } from "src/app/modules/my-apps/components/page-builder/modules-list/module-tile/module-tile.model";

export class DeleteData {
  moduleName: string;
  moduleId: number;

  constructor(moduleTileData?: ModuleTileData) {
    if (moduleTileData) {
      this.moduleName = moduleTileData.generalData.pageName;
      this.moduleId = moduleTileData.id;
    }
  }
}
