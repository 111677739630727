import { ChangeDetectorRef, Component, Input, AfterViewChecked } from "@angular/core";
import { ValidationModel } from "../validation.model";

@Component({
    selector: "validation-message",
    templateUrl: "validation-message.component.html"
})
export class ValidationMessageComponent implements AfterViewChecked {
    @Input() models: Array<ValidationModel>;
    public showPlaceholder: boolean = true;

    constructor(private cdr: ChangeDetectorRef) { }

    loadError(event: boolean) {
        this.showPlaceholder = !event;
    }

    ngAfterViewChecked() {
        this.cdr.detectChanges();
    }
}
