import { UserLoginActionType } from "../enums/response-status.enum";
import { GeneralResponse } from "./base-responses.model";

export class LoginParams {
  Email: string;
  Password: string;

  constructor(email: string, password: string) {
    this.Email = email;
    this.Password = password;
  }
}

export class LoginResponse extends GeneralResponse {
  UserLoginActions: UserLoginAction[];
}

export class UserLoginAction {
  ActionContent: string;
  ActionDescription: string;
  ActionTitle: string;
  FlagIsMandatory: boolean;
  UserLoginActionType: UserLoginActionType;
}
