import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { AlphaTechSubscribeUtils } from "../../../shared/utils/alpha-tech-subscribe.utils";
import { CountryData, CountryParams } from "../model/country.model";
import { CountryService } from "../service/country.service";
import { AlphaTechResponseDataList } from "src/app/shared/model/alphatech-response-data.model";

@Injectable({
  providedIn: "root"
})
export class CountryPresenter {

  constructor(private countryService: CountryService) { }

  getCountries(): Observable<AlphaTechResponseDataList<CountryData>> {
    const params: CountryParams = new CountryParams();

    return AlphaTechSubscribeUtils.subscribeDataListResponseHandling<CountryData>(this.countryService.getCountries(params));
  }

}
