import { Component, AfterViewInit } from "@angular/core";
import { HttpService } from "src/app/core/services/http.service";

declare let DevStudio: any;

@Component({
  selector: "dev-studio",
  templateUrl: "./dev-studio.component.html",
  styleUrls: ["dev-studio.component.scss"]
})
export class DevStudioComponent implements AfterViewInit {

  constructor(private httpService: HttpService) { }

  ngAfterViewInit(): void {
    const devStudio = new DevStudio("dev-studio", this.httpService.handleInternalErrorByStatus);
  }

}
