import { BaseListParams } from "../../core/models/base-requests.model";
import { LanguageData, GeneralListResponse, GenericGeneralListResponse } from "../../core/models/base-responses.model";

export class IconGetListParams extends BaseListParams {
  Filter: IconGetListFilter = new IconGetListFilter();
}

export class IconGetListResponse extends GenericGeneralListResponse<Icon> { }

export class IconSetGetListParams extends BaseListParams {
  Filter: any;
}

export class IconSetListResponse extends GenericGeneralListResponse<IconSetData> { }

export class IconGetListFilter {
  Status: number;
  FeatureID: number;
  IconSetID: number;
  Keyword: string;
}

export class IconSetData {
  ID: number = 0;
  Code: string;
  Status: number;
  Language: LanguageData;
  Languages: LanguageData[];
  FlagIsDefault: number;
  FlagIsLive: number;
  FlagIsSystem: number;
  FlagIsVisible: number;
  IconSetID: number;
  Icons: Icon[];
  OrganizationID: number;
}

export class IconFontFamily {
  ID: number;
  Code: string;
  Status: number;
  FlagIsDefault: number;
  FontClass: string;
  FontFamily: string;
  OrganizationID: number;
  UserID: number;
  Visibility: number;
}

export class Icon {
  ID: number;
  Code: string;
  Status: number;
  CreatedByName: string;
  FeatureID: number;
  FontName: string;
  IconFontFamily: IconFontFamily = new IconFontFamily();
  IconFontFamilyID: number;
  IconSetID: number;
  IconType: number;
  Unicode: string;

  constructor(instanceObject?: any) {
    if (instanceObject) {
      this.ID = instanceObject.ID;
      this.Code = instanceObject.Code;
      this.Status = instanceObject.Status;
      this.FeatureID = instanceObject.FeatureID;
      this.FontName = instanceObject.FontName;
      this.IconFontFamily = instanceObject.IconFontFamily;
      this.IconFontFamilyID = instanceObject.IconFontFamilyID;
      this.IconSetID = instanceObject.IconSetID;
      this.IconType = instanceObject.IconType;
      this.Unicode = instanceObject.Unicode;
    }
  }

}
