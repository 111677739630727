export enum ResponseStatus {
  Exception = -9,
  Default = -1,
  Success = 0,
  NoUser = 1,
  LoginFail = 2,
  NotAllowed = 3,
  UsernameExists = 4,
  PasswordsDoNotMatch = 5,
  ValidationErrors = 6,
  EmailNeedsValidation = 7,
  InvalidUsername = 8,
  BadRequest = 9,
  AuthCodeExpired = 10
}

export namespace ResponseStatus {
  export const getName = (responseStatus: ResponseStatus): string => {
    return ResponseStatus[responseStatus];
  };
}


export enum UserLoginActionType {
  PasswordChange = 1,
  ConsentRequired = 2,
  UpdateBillingRequired = 3,
  Announcement = 4
}
