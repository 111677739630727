import { Component, Input, OnInit } from "@angular/core";

import { StringUtil } from "../../../../../shared/utils/string.utils";
import { BankCardPaymentNetworkIcon } from "../../../models/bank-card-payment-network.model";

@Component({
  selector: "bank-card-payment-network-icon",
  templateUrl: "./bank-card-payment-network-icon.component.html"
})
export class BankCardPaymentNetworkIconComponent implements OnInit {
  @Input() creditCardType: string; // INFO: No credit card type mentions for now

  fontAwesomeIcon: string = BankCardPaymentNetworkIcon.Default;

  constructor() { }

  ngOnInit() {
    this.fontAwesomeIcon = BankCardPaymentNetworkIcon.Default;
  }

}
