import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { CommonModulesService } from "./../../app-modules/service/common-module.service";
import { DEFAULT_PAGE_SIZE } from "../../../core/consts/request-defaults";
import { ResponseStatus } from "../../../core/enums/response-status.enum";
import { BaseDeleteParams, SORTING_MODIFIED_DESC, RequestFilter } from "../../../core/models/base-requests.model";
import { GenericItemsPositionsUpdateParams } from "../../../shared/model/generic-item-positions-params";
import { ForceAllType } from "../../../shared/model/system-lookup.model";
import { GenericItemPositionService } from "../../../shared/services/generic/generic-item-position.service";
import { ModuleTileData } from "../components/page-builder/modules-list/module-tile/module-tile.model";
import {
  AddAppModulesListParams, AppAddParams, AppConfigGetParams, AppConfigResponse, AppData, AppGetListParams, AppInfo, AppPublishParams, AppPublishResponse, AppEnforceUniqueParams, AppEnforceUniqueResponse,
  AppUpdateParams, CurrencyEntry, GetAppModuleListParams, StartMode, TimeZoneEntry, AppModuleRequestFilter, AppCloneParams, GetAppModuleDefaultParams
} from "../model/my-apps.model";
import { AbstractModuleData as AbstractModuleData, AppModuleData, ModuleStatus, MyAppModuleUpdate } from "../model/page-builder.model";
import { AppService as AppService } from "../services/app.service";
import { LoggerUtil } from "src/app/shared/utils/logger.util";
import { AlphaTechServerMessage } from "src/app/shared/model/alphatech-server-message.model";
import { DisplayType } from "../model/display-type.model";
import { SessionStorageService } from "src/app/core/services/session-storage.service";
import { AlphaTechSubscribeUtils } from "src/app/shared/utils/alpha-tech-subscribe.utils";
import { AlphaTechResponseData, AlphaTechResponseDataList } from "src/app/shared/model/alphatech-response-data.model";
import { ContentBanks } from "../../app-modules/models/common.model";

@Injectable({
  providedIn: "root"
})
export class AppPresenter {
  private appModulesCache: AppModuleData[] = [];

  constructor(
    private appService: AppService,
    private genericItemPositionService: GenericItemPositionService,
    private sessionStorageService: SessionStorageService
  ) { }

  // ==================================================================================================================
  // ---------------------------- APP ---------------------------------------------------------------------------------

  getAppInfo(id: number): Observable<AlphaTechResponseData<AppData>> {
    return AlphaTechSubscribeUtils.subscribeDataResponseHandling(
      this.appService.getApp(id)
    );
  }

  getAppPremiumList(
    pageNumber: number = 0,
    searchText?: string,
    displayType?: DisplayType,
    takeOne?: boolean,
    addedItemsCount: number = 0,
    deletedItemsCount: number = 0
  ): Observable<AlphaTechResponseDataList<AppData>> {
    const params: AppGetListParams = this.buildAppGetListParams(1, 0, pageNumber, searchText, displayType, takeOne, addedItemsCount, deletedItemsCount);

    return this.buildAppListObservable(params);
  }

  getAppAvatarList(
    pageNumber: number = 0,
    searchText?: string,
    displayType?: DisplayType,
    takeOne?: boolean,
    addedItemsCount: number = 0,
    deletedItemsCount: number = 0
  ): Observable<AlphaTechResponseDataList<AppData>> {
    const params: AppGetListParams = this.buildAppGetListParams(-1, 1, pageNumber, searchText, displayType, takeOne, addedItemsCount, deletedItemsCount, 0);

    return this.buildAppListObservable(params);
  }

  getAppFreeList(
    pageNumber: number = 0,
    searchText?: string,
    displayType?: DisplayType,
    takeOne?: boolean,
    addedItemsCount: number = 0,
    deletedItemsCount: number = 0
  ): Observable<AlphaTechResponseDataList<AppData>> {
    const params: AppGetListParams = this.buildAppGetListParams(0, 0, pageNumber, searchText, displayType, takeOne, addedItemsCount, deletedItemsCount);

    return this.buildAppListObservable(params);
  }

  getAppTemplateList(
    pageNumber: number = 0,
    searchText?: string,
    displayType?: DisplayType,
    takeOne?: boolean,
    addedItemsCount: number = 0,
    deletedItemsCount: number = 0
  ): Observable<AlphaTechResponseDataList<AppData>> {
    const params: AppGetListParams = this.buildAppGetListParams(-1, -1, pageNumber, searchText, displayType, takeOne, addedItemsCount, deletedItemsCount, 1);

    return this.buildAppListObservable(params);
  }

  // TODO: use AlphaTechSubscriptionUtils here
  getAppConfig(appId: number, version: string): Observable<string> {
    return new Observable(observer => {
      const params: AppConfigGetParams = new AppConfigGetParams();
      params.AppID = appId;
      params.Version = version;

      this.appService.getAppConfig(params).subscribe(
        (response: AppConfigResponse) => {
          if (response.Status == ResponseStatus.Success) {
            const formatterSpace: number = 2;
            observer.next(response.AppConfig ? JSON.stringify(response.AppConfig, null, formatterSpace) : "");
            observer.complete();
          } else {
            observer.error(response.UserMessage);
          }
        }, error => observer.error(error));
    });
  }

  addApp(newApp: AppInfo): Observable<AlphaTechResponseData<AppData>> {
    const appRequest: AppAddParams = new AppAddParams();
    appRequest.Data.Language.Name = newApp.appName;
    appRequest.Data.Language.Description = newApp.description;
    appRequest.Data.Language.LanguageID = newApp.languageId;
    appRequest.Data.AppIcon = newApp.appIcon;
    appRequest.Data.AppSplash = newApp.splashScreen;
    appRequest.Data.ThemeBundleID = newApp.themeBundleId;
    appRequest.Data.TimezoneID = newApp.timeZone.ID;
    appRequest.Data.FlagIsTemplate = newApp.isTemplate ? 1 : 0;
    appRequest.Data.TimezoneID = newApp.timeZone.ID;
    appRequest.CloneID = newApp.templateId;
    appRequest.Data.FlagIsTemplatePublic = newApp.isTemplatePublic ? 1 : 0;

    return AlphaTechSubscribeUtils.subscribeDataResponseHandling(
      this.appService.addApp(appRequest)
    );
  }

  cloneApp(id: number): Observable<AlphaTechResponseData<AppData>> {
    const params: AppAddParams = new AppAddParams();
    params.CloneID = id;

    return AlphaTechSubscribeUtils.subscribeDataResponseHandling(
      this.appService.cloneApp(params)
    );
  }

  cloneTemplateApp(id: number, isTemplate?: boolean): Observable<AlphaTechResponseData<AppData>> {
    const params: AppCloneParams = new AppCloneParams();
    params.CloneID = id;

    return AlphaTechSubscribeUtils.subscribeDataResponseHandling(
      this.appService.cloneTemplateApp(params)
    );
  }

  updateApp(appInfo: AppInfo): Observable<AlphaTechResponseData<AppData>> {
    const appRequest: AppUpdateParams = new AppUpdateParams();
    appRequest.Data = this.buildAppData(appInfo);

    return AlphaTechSubscribeUtils.subscribeDataResponseHandling(
      this.appService.updateApp(appRequest)
    );
  }

  deleteApp(id: number): Observable<AlphaTechServerMessage> {
    const params: BaseDeleteParams = new BaseDeleteParams();
    params.ID = id;

    return AlphaTechSubscribeUtils.subscribeResponseHandling(
      this.appService.deleteApp(params)
    );
  }

  publishApp(appId: number): Observable<AppPublishResponse> {
    const params: AppPublishParams = new AppPublishParams();
    params.AppID = appId;

    return this.appService.publishApp(params);
  }

  enforceUniqueApp(appId: number): Observable<AppEnforceUniqueResponse> {
    const params: AppEnforceUniqueParams = new AppEnforceUniqueParams();
    params.AppID = appId;

    return this.appService.enforceUniqueApp(params);
  }

  // ==================================================================================================================
  // ---------------------------- ABSTRACT MODULE - FEATURE -----------------------------------------------------------

  getAllAbstractModules(): Observable<AlphaTechResponseDataList<AbstractModuleData>> {
    const params = {
      ForceAll: ForceAllType.Activated
    };

    return AlphaTechSubscribeUtils.subscribeDataListResponseHandling(
      this.appService.getAllAbstractModules(params)
    );
  }



  // ==================================================================================================================
  // ---------------------------- MODULE INSTANCE BY ABSTRACT MODULE - APP ITEM ---------------------------------------

  getAppModulesList(appId: number, moduleType?: number): Observable<AlphaTechResponseDataList<AppModuleData>> {
    const params: GetAppModuleListParams = new GetAppModuleListParams();
    params.Filter = <AppModuleRequestFilter>new RequestFilter(appId, ModuleStatus.All);
    if (moduleType) {
      params.Filter.FeatureType = moduleType;
    }
    params.Take = 100;

    return AlphaTechSubscribeUtils.subscribeDataListResponseHandling(
      this.appService.getAppModulesList(params).pipe(map(result => {
        this.appModulesCache = result.Data;
        return result;
      }))
    );
  }

  getAppModulesDefault(appId: number, featureId: number): Observable<AlphaTechResponseData<AppModuleData>> {
    const params: GetAppModuleDefaultParams = new GetAppModuleDefaultParams();

    params.AppID = appId;
    params.FeatureID = featureId;

    return AlphaTechSubscribeUtils.subscribeDataResponseHandling(
      this.appService.getAppModuleDefault(params)
    );
  }

  addAppModules(appId: number, abstractModuleIds: number[]): Observable<AlphaTechResponseDataList<AppModuleData>> {
    const requestParams: AddAppModulesListParams = new AddAppModulesListParams();
    abstractModuleIds.forEach(abstractModuleId => {
      const appModuleData = new AppModuleData();
      appModuleData.AppID = appId;
      appModuleData.FeatureID = abstractModuleId;
      requestParams.Data.push(appModuleData);
    });

    return AlphaTechSubscribeUtils.subscribeDataListResponseHandling(
      this.appService.addAppModules(requestParams).pipe(map(result => {
        this.appModulesCache = this.appModulesCache.concat(result.Data);
        return result;
      }))
    );
  }

  updateAppData(
    commonModulesService: CommonModulesService,
    visibilityId: number,
    appModuleData: AppModuleData,
    contentBanks: ContentBanks[] = [],
    tagView?: number,
    module?: AppModuleData,
  ): Observable<AlphaTechServerMessage> {
    const moduleTileData: ModuleTileData = commonModulesService.getSelectedModuleTileData();
    const toUpdate = this.appModulesCache.find(item => item.ID === moduleTileData.id);
    const requestParams: MyAppModuleUpdate = new MyAppModuleUpdate();
    if (toUpdate) {
      requestParams.Data = toUpdate;
    } else {
      requestParams.Data = module;
    }
    requestParams.Data.IconID = moduleTileData.generalData.selectedIconMeta.id;
    requestParams.Data.Language.Name = moduleTileData.generalData.pageName;
    requestParams.Data.Language.Title = moduleTileData.generalData.pageTitle;
    requestParams.Data.AppItemContentBanks = contentBanks;
    requestParams.Data.Language.Description = moduleTileData.generalData.pageDescription;
    requestParams.Data.AppItemVisibilityTypeID = visibilityId;
    requestParams.Data.LayoutScreenID = appModuleData.LayoutScreenID;
    requestParams.Data.LayoutScreenPrefix = appModuleData.LayoutScreenPrefix;
    requestParams.Data.LayoutScreenFieldName = appModuleData.LayoutScreenFieldName;
    requestParams.Data.LayoutScreenFieldQualifier = appModuleData.LayoutScreenFieldQualifier;
    requestParams.Data.NotificationEmails = appModuleData.NotificationEmails;
    requestParams.Data.NotificationHeaderID = appModuleData.NotificationHeaderID;
    requestParams.Data.VoucherID = appModuleData.VoucherID;

    if (tagView) {
      requestParams.Data.TagViewType = tagView;
    }

    return AlphaTechSubscribeUtils.subscribeResponseHandling(this.appService.updateAppModule(requestParams)).pipe(
      map(response => {
        if (response.status == ResponseStatus.Success) {
          // The general data contains the new changes (it's bind to ui) and just a successful server side save have to update the selected module tile
          commonModulesService.getModuleTileDataList().find(item => item.id == appModuleData.ID).title = moduleTileData.generalData.pageName;
          commonModulesService.getModuleTileDataList().find(item => item.id == appModuleData.ID).iconMeta = moduleTileData.generalData.selectedIconMeta;
        }

        return response;
      })
    );
  }

  updateAppModuleStatus(id: number, active: boolean): Observable<AlphaTechServerMessage> {
    //LoggerUtil.log(this.constructor.name, "updateAppModuleStatus", this.appModulesCache);
    //LoggerUtil.log(this.constructor.name, "ID", id);

    const toUpdate = this.appModulesCache.find(item => item.ID === id);
    toUpdate.Status = active ? ModuleStatus.Active : ModuleStatus.Inactive;

    const requestParams: MyAppModuleUpdate = new MyAppModuleUpdate();
    requestParams.Data = toUpdate;

    return AlphaTechSubscribeUtils.subscribeResponseHandling(
      this.appService.updateAppModule(requestParams)
    );
  }

  updateAppModulesPosition(moduleTileDataList: ModuleTileData[]): Observable<AlphaTechServerMessage> {
    const genericItemsPositionsUpdateParams: GenericItemsPositionsUpdateParams = new GenericItemsPositionsUpdateParams();
    moduleTileDataList.forEach(moduleTileData => {
      genericItemsPositionsUpdateParams.Items.push(moduleTileData.id);
    });

    return AlphaTechSubscribeUtils.subscribeResponseHandling(
      this.genericItemPositionService.updateGenericItemPosition(genericItemsPositionsUpdateParams)
    );
  }

  deleteAppModule(id: number): Observable<AlphaTechServerMessage> {
    const params: BaseDeleteParams = new BaseDeleteParams();
    params.ID = id;

    return AlphaTechSubscribeUtils.subscribeResponseHandling(
      this.appService.deleteAppModule(params)
    );
  }

  // ==================================================================================================================
  // ---------------------------- TIME ZONE & CURRENCY ----------------------------------------------------------------

  getTimeZonesList(): Observable<AlphaTechResponseDataList<TimeZoneEntry>> {
    return AlphaTechSubscribeUtils.subscribeDataListResponseHandling(
      this.appService.getTimeZonesList()
    );
  }

  getCurrencyList(): Observable<AlphaTechResponseDataList<CurrencyEntry>> {
    return AlphaTechSubscribeUtils.subscribeDataListResponseHandling(
      this.appService.getCurrencyList()
    );
  }

  // ==================================================================================================================
  // ---------------------------- Helper functions --------------------------------------------------------------------

  buildAppInfo(item: AppData): AppInfo {
    const appInfo: AppInfo = new AppInfo();
    if (item) {
      appInfo.id = item.ID;
      appInfo.appName = item.Language ? item.Language.Name : "";
      appInfo.appCode = item.Code ? item.Code : "";
      appInfo.binaryFiles = item.BinaryFiles ? item.BinaryFiles : null;
      appInfo.description = item.Language ? item.Language.Description : "";
      appInfo.appPreviewLink = item.PreviewURL;
      appInfo.translationId = item.Language ? item.Language.ID : null;
      appInfo.languageId = item.Language ? item.Language.LanguageID : null;
      appInfo.timeZone.ID = item.TimezoneID;
      appInfo.currency.Code = item.Currency;
      appInfo.cultureInfo.ID = item.CultureInfoID;
      appInfo.cultureInfo.Language.Title = item.CultureInfoName;
      appInfo.distanceUnit.ID = item.DistanceUnitID;
      appInfo.distanceUnit.Language.Title = item.DistanceUnitName;
      appInfo.appIcon = item.AppIcon;
      appInfo.splashScreen = item.AppSplash;
      appInfo.themeBundleId = item.ThemeBundleID;
      appInfo.themeBundle = item.ThemeBundle;
      appInfo.version = item.Version;
      appInfo.createdBy = item.CreatedByName;
      appInfo.warningsCount = item.WarningsCount;
      appInfo.warnings = item.Warnings;
      appInfo.deltaFields = item.DeltaFields;
      appInfo.installsCount = item.InstallsCount;
      appInfo.layoutCount = item.LayoutCount;
      appInfo.themeBundleCount = item.ThemeBundleCount;
      appInfo.publishedDate = (item.PublishedDate) ? item.PublishedDate.Date : "";
      appInfo.repositoryDate = (item.RepositoryDate) ? item.RepositoryDate.Date : "";
      appInfo.createdDate = (item.CreatedDate) ? item.CreatedDate.Date : "";
      appInfo.updatedDate = (item.ModifiedDate) ? item.ModifiedDate.Date : "";
      appInfo.appItemCount = item.AppItemCount;
      appInfo.googlePlayLink = item.URLGoogleStore;
      appInfo.isRealTime = (item.FlagIsRealTime) ? true : false;
      appInfo.isRealTimeSystem = (item.FlagIsRealTimeSystem) ? true : false;
      appInfo.showProgressBar = item.FlagShowProgressBar ? true : false;
      appInfo.dataRefreshInSeconds = item.DataRefreshInSeconds;
      appInfo.googleServicesJson = item.GoogleServicesJson;
      appInfo.googleAccountsJson = item.GoogleAccountsJson;
      appInfo.appleStoreLink = item.URLAppleStore;
      appInfo.googleServicesPlist = item.GoogleServicesPlist;
      appInfo.appleCertificationContent = item.AppleCertificateContent;
      appInfo.appleCertificationName = item.AppleCertificate;
      appInfo.appleCertificationPassword = item.ApplePassword;
      appInfo.appleProvisioningProfileContent = item.AppleProvisioningProfileContent;
      appInfo.appleProvisioningProfileName = item.AppleProvisioningProfile;
      appInfo.sha1 = item.Sha1;
      appInfo.facebookAppId = item.FacebookIdentifier;
      appInfo.facebookClientToken = item.FacebookClientToken;
      appInfo.facebookProtocolScheme = item.FacebookProtocolScheme;
      appInfo.googleMapsId = item.GoogleMapsIdentifier;
      appInfo.adminName = item.SupportName ? item.SupportName : "";
      appInfo.adminEmail = item.SupportEmail ? item.SupportEmail : "";
      appInfo.adminPhone = item.SupportPhone ? item.SupportPhone : "";
      appInfo.adminURL = item.SupportURL ? item.SupportURL : "";
      appInfo.termsAndConditionsId = item.TermsAndConditionID;
      appInfo.isPublished = (item.FlagIsLive) ? true : false;
      appInfo.isDraft = (item.FlagIsLive) ? false : true;
      appInfo.isPremium = (item.FlagIsPremium) ? true : false;
      appInfo.isAvatar = (item.FlagIsAvatar) ? true : false;
      appInfo.editorContributors = item.EditorContributors;
      appInfo.editorOwners = item.EditorOwners;
      appInfo.editorSubscribers = item.EditorSubscribers;
      appInfo.isTemplate = (item.FlagIsTemplate === 1) ? true : false;
      appInfo.isTemplatePublic = (item.FlagIsTemplatePublic === 1) ? true : false;
      appInfo.isSocialDiscoverable = (item.FlagIsSocialDiscoverable === 1) ? true : false;
      appInfo.navigationIconType = item.NavigationIconType;
      appInfo.canUserDelete = (item.FlagUserCanDelete === 1) ? true : false;
      appInfo.canUserEdit = (item.FlagUserCanEdit === 1) ? true : false;
      appInfo.isWebshopEnabled = (item.FlagWebShopEnabled === 1) ? true : false;
      appInfo.webShopHomepageUrl = item.WebShopHomepageURL;
      appInfo.webShopUrl = item.WebShopURL;
      appInfo.isConfigProcessing = (item.FlagIsConfigProcessing === 1) ? true : false;
      appInfo.showDebug = (item.FlagShowDebug === 1) ? true : false;
      appInfo.ignoreRepository = (item.FlagIgnoreRepository === 1) ? true : false;
      appInfo.ignoreLocalScreens = (item.FlagIgnoreLocalScreens === 1) ? true : false;
      appInfo.commentCount = item.CommentCount;
      appInfo.ratingCount = item.RatingCount;
      appInfo.ratingValue = item.RatingValue;

      // custom data
      appInfo.startMode = StartMode.StartFresh;
      appInfo.acceptedTerms = true;
    }

    return appInfo;
  }

  buildAppData(appInfo: AppInfo): AppData {
    const appData: AppData = new AppData();

    if (appInfo) {
      appData.ID = appInfo.id;
      appData.Language.ID = appInfo.translationId;
      appData.Language.Name = appInfo.appName;
      appData.Language.Description = appInfo.description;
      appData.Language.LanguageID = appInfo.languageId;
      appData.Code = appInfo.appCode;
      appData.PreviewURL = appInfo.appPreviewLink;
      appData.AppIcon = appInfo.appIcon;
      appData.AppSplash = appInfo.splashScreen;
      appData.ThemeBundleID = appInfo.themeBundleId;
      appData.ThemeBundle = appInfo.themeBundle;
      appData.TimezoneID = appInfo.timeZone.ID;
      appData.Currency = appInfo.currency.Code;
      appData.CultureInfoID = appInfo.cultureInfo.ID;
      appData.DistanceUnitID = appInfo.distanceUnit.ID;
      appData.Version = appInfo.version;
      appData.CreatedByName = appInfo.createdBy;
      appData.URLGoogleStore = appInfo.googlePlayLink;
      appData.FlagIsRealTime = appInfo.isRealTime ? 1 : 0;
      appData.FlagIsRealTimeSystem = appInfo.isRealTimeSystem ? 1 : 0;
      appData.FlagShowDebug = appInfo.showDebug ? 1 : 0;
      appData.FlagIgnoreRepository = appInfo.ignoreRepository ? 1 : 0;
      appData.FlagIgnoreLocalScreens = appInfo.ignoreLocalScreens ? 1 : 0;
      appData.FlagIsTemplate = appInfo.isTemplate ? 1 : 0;
      appData.FlagIsTemplatePublic = appInfo.isTemplatePublic ? 1 : 0;
      appData.FlagShowProgressBar = appInfo.showProgressBar ? 1 : 0;
      appData.FlagIsSocialDiscoverable = appInfo.isSocialDiscoverable ? 1 : 0;
      appData.NavigationIconType = appInfo.navigationIconType;
      appData.DataRefreshInSeconds = appInfo.dataRefreshInSeconds;
      appData.GoogleServicesJson = appInfo.googleServicesJson;
      appData.GoogleAccountsJson = appInfo.googleAccountsJson;
      appData.URLAppleStore = appInfo.appleStoreLink;
      appData.GoogleServicesPlist = appInfo.googleServicesPlist;
      appData.AppleCertificate = appInfo.appleCertificationName;
      appData.AppleCertificateContent = appInfo.appleCertificationContent;
      appData.ApplePassword = appInfo.appleCertificationPassword;
      appData.AppleProvisioningProfile = appInfo.appleProvisioningProfileName;
      appData.AppleProvisioningProfileContent = appInfo.appleProvisioningProfileContent;
      appData.Sha1 = appInfo.sha1;
      appData.FacebookIdentifier = appInfo.facebookAppId;
      appData.FacebookClientToken = appInfo.facebookClientToken;
      appData.FacebookProtocolScheme = appInfo.facebookProtocolScheme;
      appData.GoogleMapsIdentifier = appInfo.googleMapsId;
      appData.SupportName = appInfo.adminName;
      appData.SupportEmail = appInfo.adminEmail;
      appData.SupportPhone = appInfo.adminPhone;
      appData.SupportURL = appInfo.adminURL;
      appData.WarningsCount = appInfo.warningsCount;
      appData.InstallsCount = appInfo.installsCount;
      appData.LayoutCount = appInfo.layoutCount;
      appData.ThemeBundleCount = appInfo.themeBundleCount;
      appData.PublishedDate.Date = appInfo.publishedDate;
      appData.RepositoryDate.Date = appInfo.repositoryDate;
      appData.CreatedDate.Date = appInfo.createdDate;
      appData.AppItemCount = appInfo.appItemCount;
      appData.TermsAndConditionID = appInfo.termsAndConditionsId;
      appData.FlagIsLive = appInfo.isPublished ? 1 : 0;
      appData.FlagIsLive = appInfo.isDraft ? 1 : 0;
      appData.FlagIsPremium = appInfo.isPremium ? 1 : 0;
      appData.FlagIsAvatar = appInfo.isAvatar ? 1 : 0;
      appData.EditorContributors = appInfo.editorContributors;
      appData.EditorOwners = appInfo.editorOwners;
      appData.EditorSubscribers = appInfo.editorSubscribers;
      appData.WebShopURL = appInfo.webShopUrl;
      appData.WebShopHomepageURL = appInfo.webShopHomepageUrl;
      appData.FlagWebShopEnabled = appInfo.isWebshopEnabled ? 1 : 0;
      appData.Languages = appInfo.languageDataList;
    }

    return appData;
  }

  private buildAppListObservable(params: AppGetListParams): Observable<AlphaTechResponseDataList<AppData>> {
    return AlphaTechSubscribeUtils.subscribeDataListResponseHandling(
      this.appService.getAppList(params)
    );
  }

  private buildAppGetListParams(isPremium: number, isAvatar: number,
    pageNumber: number = 0,
    searchText?: string,
    displayType?: DisplayType,
    takeOne?: boolean,
    addedItemsCount: number = 0,
    deletedItemsCount: number = 0,
    isTemplate?: number
  ): AppGetListParams {
    const params: AppGetListParams = new AppGetListParams();
    params.Sorting = SORTING_MODIFIED_DESC;
    params.Take = takeOne ? 1 : DEFAULT_PAGE_SIZE;
    params.Position = pageNumber * DEFAULT_PAGE_SIZE + addedItemsCount - deletedItemsCount;
    params.Filter.FlagIsPremium = isPremium;
    params.Filter.FlagIsAvatar = isAvatar;
    params.Filter.Keyword = searchText;
    params.Filter.FlagIsLive = displayType;
    params.Filter.FlagIsTemplate = isTemplate ? isTemplate : undefined;
    params.Filter.OrganizationID = this.sessionStorageService.getOrganizationId();

    return params;
  }

}
