import { BsModalService, BsModalRef } from "ngx-bootstrap";
import { TemplateRef } from "@angular/core";

export class ModalUtils {

  static showModalIgnoringBackdropClick(modalService: BsModalService, template: TemplateRef<any>): BsModalRef {
    return modalService.show(template, { ignoreBackdropClick: true });
  }

  static showModalInFullScreen(modalService: BsModalService, template: TemplateRef<any>) {
    return modalService.show(template, { class: "modal-fullscreen" });
  }

}
