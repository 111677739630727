import { Component, Output, EventEmitter, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";

import { routerPaths } from "../../../../core/consts/router-paths";
import { SessionStorageService } from "src/app/core/services/session-storage.service";
import { UserInfo } from "src/app/core/models/user-info.model";
import { SessionChangeService } from "src/app/shared/services/session-change.service";
import { Subscription } from "rxjs";
import { SubscriptionUtil } from "src/app/shared/utils/subscription.utils";

@Component({
  selector: "general-menu-block",
  templateUrl: "./general-menu-block.component.html"
})
export class GeneralMenuBlockComponent implements OnInit, OnDestroy {
  @Output() onLinkClick: EventEmitter<void> = new EventEmitter();

  readonly routerPaths = routerPaths;

  userInfo: UserInfo;
  subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private sessionChangeService: SessionChangeService,
  ) {
    this.userInfo = sessionStorageService.getUserInfo();
  }

  ngOnInit() {
    this.listenSessionUserInfoChange();
  }

  ngOnDestroy() {
    SubscriptionUtil.unsubscribe(this.subscriptions);
  }

  isMainPage() {
    return this.router.url === "/";
  }

  isDevelopPageSelected() {
    return this.router.url === this.routerPaths.Deltas || this.router.url === this.routerPaths.OutboundMessages || this.router.url === this.routerPaths.DevStudio;
  }

  clickOnLink() {
    this.onLinkClick.emit();
  }

  private listenSessionUserInfoChange() {
    this.subscriptions.push(
      this.sessionChangeService.getSessionUserInfoSubject().subscribe(
        sessionUserInfoChange => {
          if (sessionUserInfoChange.isRestored) {
            this.userInfo = sessionUserInfoChange.userInfo;
          }
        }
      )
    );
  }
}
